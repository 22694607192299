const { useState } = require("react")


function RightPanel({setLeft, left}) {
    const handleClick = (blob) => {
        setLeft(blob)
        window.scrollTo({
            top: 0,
            behavior: 'smooth' // for a smooth scrolling
        });
    };

    const data = [
        {
            label:'Listings',
            color: 'yellow'
        },   
        {
        label:'Places',
        color: 'purple'
    }, {
        label:'Organizers',
        color: 'blue'
    },   
    {
        label:'Regions',
        color: 'green'
},      {
    label:'Integrations',
    color: 'red'
},{
        label:'API',
        color: 'orange'
},                         
    ]
    return (
        <div className='grid grid-cols-6  text-2xl gap-4 pl-4 pr-4'>
            {data.map(blob=>(
        <div key={blob.label} className={`lg:col-span-3 sm:col-span-3 col-span-6  rounded-lg bg-${blob.color}-300 flex justify-center align-middle py-24 cursor-pointer hover:underline ${left.label==blob.label&&'underline'}`} onClick={()=>handleClick(blob)}>
        <div>
            {blob.label}
        </div>
        <span className="bg-orange-300"></span>
        <span className="bg-blue-300"></span>

        </div>
            ))}

     
        </div>
    )
}
export default RightPanel