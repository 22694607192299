import React, { useEffect, useState } from 'react';
function DeletePlaceListings({ place }) {
const [message,setMessage] = useState(false);
  const deleteLisitngs = async (placeId) => {
    if (window.confirm("Confirm Delete All Place Listing")!==true) {
        return
    }
    try {

         
       const response = await fetch('/api/place/'+placeId + '/listings', {
        method: 'DELETE',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      const data = await response.json();

      if (response.status === 201) {
        // User profile updated successfully
        // You can redirect to another page or display a success message here
       // console.log('User profile updated:', response);
      }
      else {
      }
      setMessage(data.message)

      // Call the addRegion function with regionData
    //  await addRegion(regionData);
      // Clear the form or perform other actions as needed
    } catch (error) {
      // Handle any errors that may occur during region creation
      console.error('Error adding region:', error);
      setMessage('Error');
    }
  };

  return (
<div className="m-2">
  {place&&<button onClick={()=>deleteLisitngs(place.id)} className='rounded bg-red-500 p-2 hover:bg-red-800 hover:text-white'>Delete Place Listings</button>}
  <br/>
  {message}
</div>

  );
}

export default DeletePlaceListings;
