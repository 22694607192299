import React, { useState } from 'react';
import { useForm } from "react-hook-form"

function AddOrganizer({onChange, placeId, region}) {
  const [errorMessage,setErrorMessage] = useState('');
  const {

    register,
    handleSubmit, reset  } = useForm();
    function SuccessMessage({ data }) {
      return (
        <div className="border text-xl border-green-400  bg-green-100 px-4 py-3 text-green-700">

          New organizer: <a className='underline' href={`/region/${region.urlName}/organizer/${data.organizer.urlName}`}>{data.organizer.name}</a> added successfully.
        </div>
      );
    }
    function FailMessage() {
      return (
        <div className="border text-xl border-red-400  bg-red-100 px-4 py-3 text-red-700">
        Error adding organizer
        </div>
      );
    }
    const onSubmit = async (data) => await update(data);
  const update = async (data) => {

    if (window.confirm("Confirm Create Organizer")!==true) {
      return;
  }
    try {
      if(placeId) {
        data.placeId = placeId;
      }

      // Send a POST request to your server to save the data
      const response = await fetch(`/api/region/${region.urlName}/organizer`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        // Reset the form after successful submission
        const data = await response.json();
        setErrorMessage(<SuccessMessage data={data} />);
        reset();
        onChange();
        // Handle success or navigate to a different page
      } else {
        setErrorMessage(<FailMessage  />);
        console.error('Error creating organizer:', response.statusText);
      }
    } catch (error) {
      setErrorMessage(<FailMessage  />);
      console.error('Error creating organizer:', error);
    }
  };

  return (
    <div className="m-4">
      <p className="text-lg pb-4">Create New Organizer:</p>
      <form onSubmit={(handleSubmit(onSubmit))} className="flex flex-col space-y-4">
        {/* Render input fields for each property in the schema */}
        <div className="mb-6">
     <label className="block mb-2 text-sm font-medium text-gray-900 "> Organizer Name: </label>
        <input className="bg-gray-50 border border-gray-300 text-gray-900 text-lg rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " {...register("name", { required: true })} />   
     </div>

     <div className="mb-6">
     <label className="block mb-2 text-sm font-medium text-gray-900 "> Organizer URL: </label>
        <input className="bg-gray-50 border border-gray-300 text-gray-900 text-lg rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " {...register("url", { required: true })} />   
     </div>
     <div className="mb-6">
     <label className="block mb-2 text-sm font-medium text-gray-900 ">Description: </label>
        <textarea className="bg-gray-50 border border-gray-300 text-gray-900 text-lg rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " {...register("description")} />   
     </div>
        <br />
        <button type="submit" className=" bg-gray-50 hover:bg-black text-black font-semibold hover:text-white py-2 px-4 border border-black hover:border-transparent rounded">Create Organizer</button>
      </form>
    {errorMessage}
    </div>
  );
}

export default AddOrganizer;
