import React, { useEffect, useState } from 'react';

import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
import SuggestionBlobs from '../../components/utilities/SuggestionBlobs';
import ScrapeBlobs from '../../components/utilities/ScrapeBlobs';


function UserDashboard({setIsAuthenticated,isAuthenticated}) {
//we want all scrapes from all managaged listings, ability to see the listings and dismiss scrapes
//we want all suggestions and adds

// i think one place at a time
//in a row with numbers x upcoming listings x new scrapes  x listing add requests x listings edit requests -> click to open all or any in particular
// list out managed places, orgs 
//add listing tab - click on 

  const navigate = useNavigate();
  const [places, setPlaces] = useState(null);
  const [count, setCount] = useState(null);
  const [regions, setRegions] = useState(null);

  const [organizers, setOrganizers] = useState(null);
  const [scrapes, setScrapes] = useState(null);

  const [open, setOpen] = useState(false);
  const [suggestions, setSuggestions] = useState(false);
  const [user, setUser] = useState(false);

  useEffect(() => {
    const dashboard = async () => {
        try {
          const response = await fetch('/api/dashboard', {
            headers: {
              'Content-Type': 'application/json', // Use a function to get the cookie value
                    },
                    credentials: 'include', // Include cookies
          });
          if (response.ok) {
            const data = await response.json();
            console.log(data);
            setUser(data.user);
            setRegions(data.regions)
          }
          
           else if(response.status===401) {
            setIsAuthenticated(false);
            localStorage.removeItem('isAuthenticated');
            localStorage.removeItem('role');
            navigate('/login/dashboard');
          }
          
      
        } catch (error) {
          //console.log('Error fetching listings', error);
    
        }
      };
    if(isAuthenticated) {
      dashboard();
    }
    else {
      navigate('/login/user');
    }
  }, [isAuthenticated,setIsAuthenticated,navigate]);
  useEffect(() => {
      document.title = `Dashboard - Zeitgeists`;
  }, []);


  return (
    <div className='mt-36'>
<div className='grid grid-cols-4 p-4'>
{user && user.userRegions &&user.userRegions.length > 0 && <div className='col-span-4 text-center border-black border border-b-0 p-2 '>
            <span className='font-bold text-lg'>Region{regions.length > 0 && 's'}: </span>
            {
            <>
            {user.userRegions.map(ur => (
                <span className='inline-block mt-2 mr-2' key={ur.region.id}>
                     <Link className='p-2  border-black border-2 hover:bg-black hover:text-white hover:scale-105 transition-transform' to={`/region/${ur.region.urlName}/`}>{ur.region.name}</Link>
                    <Link className='p-2  border-black border-l-0 border-2 bg-green-500 hover:bg-green-700 text-white hover:scale-105 transition-transform'to={`/region/${ur.region.urlName}/edit`}>Dashboard</Link>

                </span>
            ))}
       </> 
                      }
               
        </div>}

            {/* Repeat for places */}

        <div className='col-span-4 text-center border-black border border-b-0 pt-2 '>
            {
                
              user && user.userPlaces && user.userPlaces.length > 0 &&
            <>
                        <span className='font-bold text-lg'>Places: </span>
            {user.userPlaces.map(up => (
                <span className='inline-block m-3' key={up.place.id}>
                    <Link className='p-2 rounded-l-lg border-black border-2 hover:bg-black hover:text-white hover:scale-105 transition-transform' to={`/region/${up.place.region.urlName}/place/${up.place.urlName}/`}>{up.place.name}</Link>
                    <Link className='p-2 rounded-r-lg border-black border-l-0 border-2 bg-green-500 hover:bg-green-700 text-white hover:scale-105 transition-transform' to={`/region/${up.place.region.urlName}/place/${up.place.urlName}/edit`}>Dashboard</Link>

                </span>
            ))}
                    </>  }
               
            {/* Repeat for organizers */}
        </div>

        <div className='col-span-4 text-center border-black border border-t-0 pt-1 pb-2 '>
            {
              user && user.userOrganizers&& user.userOrganizers.length > 0 &&
            <>
                   <span className='font-bold text-lg'>Organizers: </span>

                       {user.userOrganizers.map(uo => (
                <span className='inline-block m-3' key={uo.organizer.id}>
          <Link className='p-2 rounded-l-full border-black border-2  hover:bg-black hover:text-white hover:scale-105 transition-transform' to={`/region/${uo.organizer.region.urlName}/organizer/${uo.organizer.urlName}/`}>{uo.organizer.name}</Link>
                    <Link className='p-2 rounded-r-full border-black border-l-0 border-2 bg-green-500 hover:bg-green-700 text-white hover:scale-105 transition-transform' to={`/region/${uo.organizer.region.urlName}/organizer/${uo.organizer.urlName}/edit`}>Dashboard</Link>
                </span>
            ))}
            </> }
            
        </div>

        {user&& user.listingEdits && 
        <div className="col-span-4 md:col-span-1 sm:col-span-2  m-3 bg-yellow-200 text-center p-6 rounded-lg flex flex-col justify-center items-center shadow-sm">
            <p className="text-5xl">{user.listingEdits.filter(suggestion => suggestion.listingId && suggestion.status==='unapproved').length}</p>
            <p className="text-xl">Listing Edit Suggestions</p>
            {open !== 'edits' && <button className="text-blue-600 hover:underline" onClick={()=>setOpen('edits')}>See listings suggestions</button>}
            {open === 'edits' && <button className="text-red-600 hover:underline" onClick={()=>setOpen(false)}>Close</button>}

        </div>
    }

    {user&& user.listingEdits &&
        <div className="col-span-4 sm:col-span-2 md:col-span-1 m-3 bg-purple-200 text-center p-6 rounded-lg flex flex-col justify-center items-center shadow-sm">
            <p className="text-5xl">{user.listingEdits.filter(suggestion => suggestion.suggestedAdd && suggestion.status==='unapproved').length}</p>
            <p className="text-xl">Listing Add Suggestions</p>
            {open !== 'adds' && <button className="text-blue-600 hover:underline" onClick={()=>setOpen('adds')}>See listings add suggestions</button>}
            {open === 'adds' && <button className="text-red-600 hover:underline" onClick={()=>setOpen(false)}>Close</button>}

        </div>
    }


    {scrapes&&
       <div className="col-span-4 sm:col-span-2  md:col-span-1 m-3 bg-blue-200 text-center p-6 rounded-lg flex flex-col justify-center items-center shadow-sm">
            <p className="text-5xl">{scrapes.filter(scrape=>scrape.new).length}</p>
            <p className="text-xl">New Scrapes</p>
            {open !== 'scrapes' && <button className="text-blue-600 hover:underline" onClick={()=>setOpen('scrapes')}>See scrapes</button>}
            {open === 'scrapes' && <button className="text-red-600 hover:underline" onClick={()=>setOpen(false)}>Close</button>}

        </div>
    }
            {
       false && <div className="col-span-4 sm:col-span-2  md:col-span-1 m-3 bg-orange-200 text-center p-6 rounded-lg flex flex-col justify-center items-center shadow-sm">
            <p className="text-5xl"></p>
            <p className="text-xl">Upcoming Listings</p>
            <Link to={`/region/listings`} className="text-blue-600 hover:underline">See listings</Link>
        </div>
    }
    {
        count  && <div className="col-span-4 sm:col-span-2  md:col-span-1 m-3 bg-green-200 text-center p-6 rounded-lg flex flex-col justify-center items-center shadow-sm">
            <p className="text-5xl">{count.live}</p>
            <p className="text-xl">Upcoming Live Status Listings</p>
            <Link to={'/listings'} className="text-blue-600 hover:underline">See listings</Link>
        </div>
    }
            {
        <div className="col-span-4 md:col-span-1 sm:col-span-2  m-3 bg-pink-200 text-center p-6 rounded-lg flex flex-col justify-center items-center shadow-sm">
            <Link to={'/listings'} className="text-blue-600 hover:underline">
            <p className="text-5xl">Add</p>
            <p className="text-xl">Listing</p>
           </Link>

        </div>
    }
            {
        <div className="col-span-4 md:col-span-1 sm:col-span-2  m-3 bg-teal-200 text-center p-6 rounded-lg flex flex-col justify-center items-center shadow-sm">
            <Link to={'/listings'} className="text-blue-600 hover:underline">
            <p className="text-5xl">Edit</p>
            <p className="text-xl">Listing</p>
           </Link>

        </div>
    }
       {open === 'adds' && suggestions && places && <SuggestionBlobs suggestions={suggestions.filter(suggestion=>suggestion.type==='add')} places={places} />
    }
   {open === 'edits' && suggestions && places && <SuggestionBlobs suggestions={suggestions.filter(suggestion=>suggestion.type==='edit')} places={places} />
    }
       {open === 'scrapes' && scrapes && places && <ScrapeBlobs places={places} scrapes={scrapes} />
    }
    

</div>




      
    </div>
           ) 
}

export default UserDashboard;



