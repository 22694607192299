import React from 'react';
import { Link } from "react-router-dom";

function DisplayIntegrations({ place,integrations,setSelectedIntegration }) {
  


  return (

    (integrations && place&&  <div className="p-4">

{place.placeIntegrations.length > 0 && (
  <div>
    <div className='text-lg font-semibold mb-4'>Current Integrations:</div>
    <div className="space-y-4">
      {place.placeIntegrations.map(pi => (
        <div key={pi.uuid} className="bg-white shadow overflow-hidden rounded-md px-6 py-4">
          <div className="flex flex-wrap items-baseline justify-between">
            <div className="flex flex-wrap sm:flex-wrap sm:space-x-4">
              <span className="font-bold">Type:</span>
              <span>{pi.integrationType.name}</span>
              <span className="font-bold">Name:</span>
              <span>{pi.name}</span>
              <span className="font-bold">Status:</span>
              <span>{pi.status.toUpperCase()}</span>
              <span className="font-bold">Permissions:</span>
              <span>{pi.action.toUpperCase()}</span>
              {pi.integrationType.name === "ICS" && (
                <>
                  <span className='font-bold'>ICS URL:</span>
                  <Link className='underline text-blue-600 hover:text-blue-800 visited:text-purple-600' to={pi.url}>{pi.url}</Link>
                </>
              )}
              {pi.integrationType.name === "Eventbrite" && (
                <>
                  <span className='font-bold'>Eventbrite API:</span>
                  <span>{pi.key}</span>
                </>
              )}
              {pi.integrationType.name === "TypeCatch" && (
                <>
                  <span className='font-bold'>Listing Type:</span>
                  <span>{pi.key}</span>
                </>
              )}
              {pi.integrationType.name === "OrganizerCatch" && (
                <>
                  <span className='font-bold'>Organizer:</span>
                  <Link className='underline text-blue-600 hover:text-blue-800 visited:text-purple-600' to={`/region/${place.region.urlName}/organizer/${pi.organizer.urlName}`}>{pi.organizer.name}</Link>
                </>
              )}
            </div>
            <div className="flex space-x-2 mt-4 sm:mt-0">
            <button className='text-blue-500 hover:text-blue-700 underline' onClick={()=>setSelectedIntegration(pi)}>Manage Integration</button>
              <button className='text-orange-500 hover:text-orange-700 underline'>See All Logs(4)</button>
              <button className='text-red-500 text-xs hover:text-red-700 underline'>Delete</button>
            </div>
          </div>
        </div>
      ))}
    </div>
  </div>
)}


      <div className='text-lg mt-8 mb-4'>Integration Types:</div>

        {integrations.map(integration=>
                  <div key={integration.uuid}>
   <h3 className='text-lg font-semibold'>{integration.name}</h3>
        <p>{integration.description}</p>        
</div>
            )}
     


    


    </div>)

  );
}

export default DisplayIntegrations;
