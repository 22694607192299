import React, {useEffect, useState} from 'react';
import TopHeadAnimation from '../components/utilities/TopHeadAnimation';
import SecondPanel from '../components/utilities/SecondPanel';
import { Link } from 'react-router-dom';
import RightPanel from '../components/utilities/RightPanel.js';
import HomeRegions from '../components/utilities/HomeRegions.js';
import LeftPanel from '../components/utilities/LeftPanel.js';
function NewHome({setIsAuthenticated,isAuthenticated}) {
  const [select,setSelect] = useState(false);
  const [qa,setQA] = useState(0);
  const [left,setLeft] = useState('qa');

const data = [
  {
    question: "What is this website?",
    answer: "Zeitgeists.org is an open platform designed as a curated repository of cultural events. It offers structured, free data for developers and tools for organizers to share and manage event information, emphasizing community collaboration in arts and culture.",
  },
  {
    question: "What cultural events are included?",
    answer: "The platform hosts a range of cultural events including film screenings, art exhibitions, literary events, and more, all selected by our community councils. Rather than being an event discovery interface, Zeitgeists.org serves as a collective effort to enrich the cultural scene by providing reliable, structured information.",
  },
  {
    question: "Why does this exist?",
    answer: "Zeitgeists.org was developed to provide a structured, accessible resource for cultural event data. It aims to facilitate a more intuitive and diverse cultural engagement by offering free data for developers and efficient tools for organizers, moving towards the ideals of the web. ",
  },
  {
    question: "How does it support developers?",
    answer: "Developers have access to free, structured event data, enabling them to build innovative tools and applications. Organizers and venues use the platform to manage and share event details, reaching a wider audience efficiently.",
  },
  {
    question: "Can anyone contribute?",
    answer: "Yes, contributions from the community are essential. Whether it's through edit an error in a listing, managing a place’s information, participating in local councils, or using our free API for your apps, there are many ways to be involved.",
  }
];
const home  = async () => {
  try {
    const response = await fetch('/api/home',{
      headers: {
        'Content-Type': 'application/json', // Use a function to get the cookie value
              }
              });
    if(response.ok) {
      const data = await response.json();
      console.log(data)
      if(data) {
        setSelect({
          listing: data.listing,
          regions: data.regions,
          organizer: data.organizer,
        })
      }
    }
    else {
      console.error('Failed to load data. Fallback to defaults.')
    }
  }
  catch (error) {
    console.error(error);
  }
}

useEffect(() => {
  document.title = `Home - Zeitgeists`;
  home();
}, []);

  return (
    <div className='grid grid-cols-2 h-screen pt-18 underline-offset-8'>
      <div className={`col-span-2 md:col-span-1 md:overflow-auto bg-${left!=='qa'? left.color+'-300': 'black'} h-full flex justify-start pt-20 flex-col  text-white  `}>
        {left !== 'qa' && <div className='w-full text-right text-3xl cursor-pointer pr-6 hover:text-gray-500' onClick={()=>setLeft('qa')}>✕</div>}
        {left !== 'qa' && select && <LeftPanel left={left} data={select} />}
     {left =='qa' && <div className='divide-y'>

        {data.map((q,i)=>(<div key={i} className='m-10 '>
      <button onClick={()=>setQA(i)} className={`text-3xl hover:text-gray-500 ${qa===i&&'font-bold shadow-md'}`}>
        {q.question} 
        </button>
        {qa==i &&<div className='text-2xl mt-4'>
        {q.answer}
        </div>}
        
        </div>
      ))}
      {select && <HomeRegions data={select} />}
      
      </div>}
      </div>

      <div className='col-span-2 md:col-span-1 md:overflow-auto h-full bg-white pt-5  md:pt-20 pb-10 text-black '>
        <RightPanel left={left} setLeft={setLeft} />
 

</div>
  
    </div>
  );
}

export default NewHome;



