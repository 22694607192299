import React, {useState,useEffect} from 'react';
import { useForm, Controller,useFieldArray } from "react-hook-form"
function AdminManageIntegration({  selectedIntegration,setSelectedIntegration, onChange }) {
    const [errorMessage, setErrorMessage] = useState(false);

    function SuccessMessage({ data }) {
        return (
          <div className="border text-xl border-green-400  bg-green-100 px-4 py-3 text-green-700">
              {data}
          </div>
        );
      }
      function FailMessage({data}) {
        return (
          <div className="border text-xl border-red-400  bg-red-100 px-4 py-3 text-red-700">
          {data ? data : 'Error Editing Integration'}
          </div>
        );
      }
  const {
    register,
    handleSubmit, formState: { errors }, setError, getValues, reset, setValue} = useForm({
        defaultValues: {
          directory: selectedIntegration.directory || null,
          status: selectedIntegration.status,
          frequency: selectedIntegration.frequency || null,
        }
      });
      useEffect(()=>{
        setValue('directory', selectedIntegration.directory || null);
        setValue('status', selectedIntegration.status);
        setValue('frequency', selectedIntegration.frequency);
      },[selectedIntegration])

  const onSubmit = async (data) => {
        // Check if type is OrganizerCatch and catches are empty
    
    try {
      // Send a POST request to your server to save the data
      const response = await fetch(`/api/admin/integration/${selectedIntegration.uuid}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        // Reset the form after successful submission
        const data = await response.json();
        console.log(data);
        setErrorMessage(<SuccessMessage data={data.message} />);
        onChange();
        // Handle success or navigate to a different page
      } else {
        setErrorMessage(<FailMessage  />);
        console.error('Error editing integration:', response.statusText);
      }
    } catch (error) {
      setErrorMessage(<FailMessage  />);
      console.error('Error editing integration:', error);
    }
  };


  

  return (

    <div className="m-4 shadow rounded-md px-6 py-4 border-4 border-yellow-200">
        <div className='text-right'>
        <button className='underline text-red-500 hover:font-semibold' onClick={()=>setSelectedIntegration(false)}>Close</button>

        </div>
            {selectedIntegration.catches.length > 0 && <div>
                {selectedIntegration.catches.map(sic=>(<div key={sic.uuid} className='space-x-2 md:space-x-4'>
                    <span className="font-bold">Type:</span>
                     <span>{sic.type}</span>
                     <span className="font-bold">Field:</span>
                     <span>{sic.field}</span>
                     <span className="font-bold">Catch:</span>
                     <span>{sic.catch}</span>

                </div>
                   
))}
                </div>}
<form onSubmit={(handleSubmit(onSubmit))} className="flex flex-col space-y-4">

<div  className="">
<label htmlFor="statusSelect" className="block mb-2 text-sm font-medium text-gray-900">Change Status:</label>
<select {...register("status", { required: 'Status is required' })} className="block appearance-none w-full bg-gray-50 border text-gray-900 border-gray-300 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline">
<option key="" value="">
Select Status
        </option>
       <option key="new" value="new">
      New - Inactive awaiting approval
               </option>
        <option key="active" value="active">
        Active - approved running integration
                </option>
                <option key="review" value="review">
        Review - Integration under review
                </option>      
                <option key="paused" value="paused">
        Paused - Integration temporarily paused
                </option>  
                <option key="rejected" value="rejected">
        Rejected - Integration denied or frequent errors
                </option>
    </select>
</div>
{errors.status && (
              <div className="mt-5">
                <div className="border border-red-400 bg-red-100 px-4 py-3 text-red-700">
                  <p>{errors.status.message}</p>
                </div>
              </div>
)}
<div  className="mb-6">
<label htmlFor="frequencySelect" className="block mb-2 text-sm font-medium text-gray-900">Change Frequency:</label>
<select {...register("frequency")} className="block appearance-none w-full bg-gray-50 border text-gray-900 border-gray-300 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline">
<option key="" value="">
Select Frequency
        </option>
       <option key="daily" value="daily">
      Daily - Runs every night
               </option>
        <option key="weekly" value="weekly">
        Weekly - Runs once every week
                </option>
              
    </select>
</div>
{errors.frequency && (
              <div className="mt-5">
                <div className="border border-red-400 bg-red-100 px-4 py-3 text-red-700">
                  <p>{errors.frequency.message}</p>
                </div>
              </div>
)}
<div>
<label className="block mb-2 text-sm font-medium text-gray-900 "> Integration Directory: what comes after ~/cronjobs/{selectedIntegration.place.region.urlName}/ </label>
        <input className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " {...register("directory")} />   
     </div>
     {errors.directory && (
              <div className="mt-5">
                <div className="border border-red-400 bg-red-100 px-4 py-3 text-red-700">
                  <p>{errors.directory.message}</p>
                </div>
              </div>
)}  

    {errorMessage}

    <button type="submit" className=" bg-gray-50 hover:bg-black text-black font-semibold hover:text-white py-2 px-4 border border-black hover:border-transparent rounded">Update</button>

    </form>
<div className='mt-6'>
    {selectedIntegration.runs.map(run=>(
        <div key={run.uuid}>
             <span className="font-bold">Run At:</span>
    <span>{new Date(run.createdAt).toLocaleString('en-US')}</span>
    <span className="font-bold">Status:</span>
    <span>{run.status}</span>
    <span className="font-bold">Updated:</span>
    <span>{run.updated}</span>
    <span className="font-bold">Added:</span>
    <span>{run.added}</span>
    <span className="font-bold">Errors:</span>
    <span>to add</span>
        </div>
    ))}
Show Logs here,
        and Possibly run by buttton here 
</div>


    </div>

  );
}

export default AdminManageIntegration;
