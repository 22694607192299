import '../main.css'
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
//import DisplayEditListing from '.../components/DisplayEditListing';
import EditListingNew from '../../components/listing/EditListingNew';
import ListingBox from '../../components/listing/ListingBox';
function EditListing({setIsAuthenticated, isAuthenticated, role }) {
    const navigate = useNavigate();

    const [listing, setListing] = useState(false)
    const [editor, setEditor] = useState(false)
    const [suggestions, setSuggestions] = useState(false);
    const [region, setRegion] = useState(false);

    //get place by string

    useEffect(() => {
      // Fetch region details when the component mounts
      if(!isAuthenticated) {
        navigate(`/login/listing/${listingId}/edit`) 
      }
      fetchListing();
    }, []);
  
    useEffect(() => {
      document.title = `Edit Listing - Zeitgeists`;

  }, []);
    
      const { listingId } = useParams(); // Access the "urlName" route parameter

      const fetchListing = async () => {
        try {
            const response = await fetch(`/api/listing/${listingId}`, {
                headers: {
                  'Content-Type': 'application/json', // Use a function to get the cookie value
                        },
                        credentials: 'include', // Include cookies
              });
               if (response.ok) {
                const data = await response.json();
                setListing(data.listing);
                setEditor(data.editor);
                setSuggestions(data.suggestions);
                setRegion(data.region);
                //not sure if boolean
              } 
              if(response.status===401) {
                setIsAuthenticated(false);
                localStorage.removeItem('isAuthenticated');
                localStorage.removeItem('role');
                navigate(`/login/listing/${listingId}/edit`)
          
              }
        } catch (error) {

        }
      }
//create edit listing blurb add requests blurb
   return (
    <div className="m-4 mt-20 md:mt-14"> 
        {(listing ) && <ListingBox  listing={listing} suggestions={suggestions}  editor={editor} editing={true}  />}

    {(listing ) && <div> 
    <EditListingNew onChange={fetchListing} listing={listing} editor={editor}/>
    </div> 
      
}

    </div>


    
  );
}

export default EditListing;
