import React, { useState } from 'react';
import { MapContainer, TileLayer, Marker, Popup } from 'react-leaflet';
import L from 'leaflet';

const PlacesMap = ({ region, small, places,setActivePlace,activePlace }) => {

  const dotIcon = new L.divIcon({
    className: 'custom-dot-marker bg-black w-14 h-14 rounded-full hover:bg-gray-700',
    html: '<div></div>',
    iconSize: [24, 24],
    iconAnchor: [12, 12]
  });
  
  const selectedDotIcon = new L.divIcon({
    className: 'custom-dot-marker bg-black w-14 h-14 rounded-full hover:bg-gray-700',
    html: '<div></div>',
    iconSize: [32, 32],
    iconAnchor: [16, 16]
  });
//center on region center
const zoomNumber = region ? region.zoom : 11
  return (
    <MapContainer center={region && !isNaN(region.latitude) && !isNaN(region.longitude) ?   [region.latitude,region.longitude] : [34.08,-118.4]} zoom={small ? zoomNumber-1 : zoomNumber}
    className={small ? "w-full h-48   flex z-0" : "full-screen-map"}
       zoomControl={false} // Disables the zoom control buttons
      scrollWheelZoom={false} // Prevents zooming with the scroll wheel
      //touchZoom={false} // Prevents zooming with touch gestures
    //  doubleClickZoom={false} // Prevents zooming with double-click
  //dragging={false} // Prevents moving the map
    >
<TileLayer
        url="https://{s}.basemaps.cartocdn.com/light_all/{z}/{x}/{y}.png"
        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
      />
      {places.map(place => {
        if(place.latitude&&place.longitude&&!isNaN(place.latitude)&&!isNaN(place.longitude)) {
            return (
                <Marker
                  key={place.id}
                  position={[place.latitude, place.longitude]}
                  icon={activePlace === place.id ? selectedDotIcon : dotIcon}
                  eventHandlers={{
                    click: () => {
                      setActivePlace(place.id);
                    },
                  }}
                >
       
                </Marker>
              )
        }
 })}
    </MapContainer>
  );
};

export default PlacesMap;
