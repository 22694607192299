import '../main.css'
import React, { useEffect, useState } from 'react';
import { useNavigate, useLocation, Link, useParams } from 'react-router-dom';
import SuggestedListing from '../../components/listing/SuggestedListing';

import ListingBox from '../../components/listing/ListingBox';
import PlaceInfo from '../../components/place/PlaceInfo';
import OrganizerInfo from '../../components/organizer/OrganizerInfo';
import ManageSuggestion from '../../components/listing/ManageSuggestion';
const moment = require('moment-timezone');


function Listing({setIsAuthenticated, isAuthenticated, role }) {

function formatISOInLocal(isoString) {
  // Format: 'MMM DD, h:mm A' e.g., 'Nov 17, 6:00 AM'
  return moment(isoString).format('MMM DD, h:mm A');
}
  
  const location = useLocation();

  const [openSuggestion, setOpenSuggestion] = useState(false);

    const navigate = useNavigate();
    const [listing, setListing] = useState(false);
    const [suggestions, setSuggestions] = useState(false);
    const [editor, setEditor] = useState(false);

    useEffect(() => {
      const searchParams = new URLSearchParams(location.search);
      const suggestionParam = searchParams.get('suggestion');
      setOpenSuggestion(suggestionParam);
    }, [location]);
    //get place by string

    useEffect(() => {
      // Fetch region details when the component mounts
      fetchListing();
    }, []);
  
    useEffect(() => {
      document.title = `Listing - Zeitgeists`;

  }, []);

      const { listingId } = useParams(); // Access the "urlName" route parameter
      const handleCloseSuggestion = () => {
        setOpenSuggestion('')
        const searchParams = new URLSearchParams(location.search);
        searchParams.delete('suggestion');
      
        // Navigate to the same path with the new searchParams string
        navigate({
          pathname: location.pathname,
          search: searchParams.toString()
        });
      
      }
      const fetchListing = async () => {
        try {
            const response = await fetch(`/api/listing/${listingId}`, {
                headers: {
                  'Content-Type': 'application/json', // Use a function to get the cookie value
                        },
                        credentials: 'include', // Include cookies
              });
               if (response.ok) {
                const data = await response.json();
                console.log(data)
                setListing(data.listing);
                setSuggestions(data.listing.edits.filter(edit=>edit.type=='suggest'));
                
                setEditor(data.editor);
                //region
              } 
              if(response.status===401) {
                setIsAuthenticated(false);
                localStorage.removeItem('isAuthenticated');
                localStorage.removeItem('role');
                navigate(`/login/listing/${listingId}`)
              }
        } catch (error) {

        }
      }
      function renderSuggestions() {
        // If there are no suggestions or openSuggestion is not set, render nothing.
        if (!suggestions || !openSuggestion) return null;
      
        // If openSuggestion is 'all', render all suggestions. Otherwise, filter and render the specific suggestion.
        const filteredSuggestions = openSuggestion === 'all'
          ? suggestions.filter(suggestion => suggestion.status !== 'approved')
          : suggestions.filter(suggestion => suggestion.uuid === openSuggestion);
      
        // Map over the filtered suggestions to render the JSX elements.
        return filteredSuggestions.map(suggestion => (
          <div key={suggestion.id}>
            <button onClick={handleCloseSuggestion} className="text-blue-600 hover:underline">Close Suggestion</button> <br/>
            {suggestion.createdAt && (
        <div>
          Submited At: {formatISOInLocal(suggestion.createdAt)} by{' '}{
suggestion.user ?  <Link to={`/user/${suggestion.user.username}`} className="text-blue-600">
@{suggestion.user.username}
</Link> : 'Integration'
          }
         
        </div>
      )}

      {suggestion.comment && <p>Comment: <span className="bg-green-200">{suggestion.comment}</span></p>}


           {listing &&  suggestion && suggestion.status !== 'approved' && suggestion.suggestedListing && <SuggestedListing suggestion={suggestion} currentListing={listing} region={listing.region} /> }
            {editor && (suggestion.status !== 'approved' ?
            <ManageSuggestion suggestion={suggestion} onChange={fetchListing} /> : <p>Status: <span className='text-green-500'>Approved</span></p>)
          
          }

          </div>
        ));
      }
   return (
    <div className="m-4 mt-20 md:mt-14"> 
    {(listing ) && <ListingBox  listing={listing}  suggestions={suggestions} editor={editor}  />}
{renderSuggestions()}
      <div className="">
        <div className='flex justify-center flex-wrap'>
          {
            listing.organizers && listing.organizers.map((lo) => <div className="w-full sm:w-1/2 md:w-1/3 " key={lo.organizer.id}><OrganizerInfo organizer={lo.organizer} places={[listing.place]} region={listing.region} listing={true} /></div>)
          }
          </div>
                <PlaceInfo place={listing.place} organizers={listing.organizers} region={listing.region} listing={true} />

        {listing.region &&
        <div className='text-right'>
          In <Link className='text-blue-500 hover:underline' to={`/region/${listing.region.urlName}`}>{listing.region.name}</Link>.
          </div>}
      </div>
    </div>


    
  );
}

export default Listing;
