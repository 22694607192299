import React, { useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import SetPlaceOrgsNew from '../place/SetPlaceOrgsNew';

import ScreeningForm from './ScreeningForm';
import { useForm, Controller } from "react-hook-form"
function EditListingNew({ listing, editor, onChange }) {
  const validTypes = ['Talk','Show','Screening','Fair','Exhibition','Family','Workshop','Tour','Party','Exclusive']

  function SuccessMessage({data}) {
    let message = 'Success';
    if(data.listing) {
      message = data.message
    } else if (data.suggestion) {
      message = data.message;
    }
    
    return (
      <div className="border text-xl border-green-400 rounded  bg-green-100 px-4 py-3 text-green-700">
        {message}
  </div>

    );
  }
  function FailMessage({data}) {
    return (
      <div className="border text-xl border-red-400 rounded bg-red-100 px-4 py-3 text-red-700">
      Error editing listing.
      </div>
    );
  }
    const startDate = Date.parse(listing.startDate);
const endDate = Date.parse(listing.endDate);
const doorTime = Date.parse(listing.doorTime);
  const {
    control,
    register,
    handleSubmit, setValue, getValues,watch } = useForm({
        defaultValues: {
          startDate: !isNaN(startDate) ? new Date(startDate) : listing.startDate,
          endDate: !isNaN(endDate) ? new Date(endDate) : listing.endDate,
          name: listing.name || null,
          note: listing.note || null,
          about: listing.about|| null,
          status: listing.status || null,
          url: listing.url || null,
          alternateName: listing.alternateName || null,
          room: listing.room || null,
          inLanguage: listing.inLanguage || null,
          isAccessibleForFree: listing.isAccessibleForFree || null,
          sponsor: listing.sponsor || null,
          image: listing.image || null,
          alt: listing.alt || null,
          keywords: listing.keywords || null,
          doorTime: !isNaN(doorTime) ? new Date(doorTime) : listing.doorTime,
          organizers: listing.organizers || [],
          type: listing.type || ''
        }
      });
      const type = watch('type')

  const onSubmit = (data) => submitAndConnect(data);
  const [updateMessage, setUpdateMessage] = useState(''); // State for update message
const [additionalData, setAdditonalData] = useState({});

const submitAndConnect = async (data) => {
    // Submit form data to your API or server
    data.additionalData = additionalData;
    if(data.isAccessibleForFree==="") {
      data.isAccessibleForFree = null;
    }
    setAdditonalData({})
    setValue('comment', '')
    // Call the reset function to clear the form
   await submit(data);
    
  }




  function removeHtmlTags(input) {
    return input.replace(/<[^>]*>/g, '');
  }
  const [showMore,setShowMore] = useState(false);

  const handleShowMore = () => {
    if(showMore) {
      setShowMore(false);
    } else {
      setShowMore(true);
    }
  };



  const submit = async (data) => {

    console.log(data);

    // Create a new listing object based on the form field values
    try {
      const response = await fetch(`/api/listing/${listing.uuid}/edit`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
        credentials: 'include', // Include cookies
      });
      if (response.ok) {
        const data = await response.json()
        setUpdateMessage(<SuccessMessage data={data} />)
        // Success: Listing updated successfully
        // You can display a success message here if needed
        setTimeout(() => setUpdateMessage(''), 10000); // Clear message after 5 seconds

        onChange();
        // You can close the form here by calling a callback function if needed
    } else {
      setUpdateMessage(<FailMessage data={data} />);

        // Error: An error occurred while updating the listing
        const errorData = await response.json();
        console.error(errorData.message); // Log the error message for debugging
        // Update the UI to display the error message to the user
        // For example, you can set it in a state variable to render in your component
    }

    } catch (error) {
   
        setUpdateMessage(<FailMessage />);
    
      console.error(error);
    }
  };


  return (
    <div className="m-4">
       {!editor && <p className="text-center text-xl underline">
            Suggest an Edit
        </p>}

      <form onSubmit={handleSubmit(onSubmit)} >
<div>

<div className="mb-6">
    <label htmlFor="large-input" className="block mb-2 text-sm font-medium text-gray-900 ">Event Title</label>
    <input {...register("name", { required: true })} type="text" id="large-input" className="block w-full p-4 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-md focus:ring-blue-500 focus:border-blue-500 " />
</div>
<div className="mb-6">
            <SetPlaceOrgsNew
          control={control} // Pass control to SetPlaceOrgs
          setValue={setValue} // Pass setValue to SetPlaceOrgs for updating values
          getValues={getValues}
          thePlace={listing.placeId}
          preOrgs={listing.organizers}
          region={listing.regionId}
          suggest={!editor}
        />  


             
</div>

<div className="mb-6">
<label htmlFor="typeSelect" className="block mb-2 text-sm font-medium text-gray-900">Select Listing Type:</label>
<select {...register("type", { required: true })} className="block appearance-none w-full bg-gray-50 border text-gray-900 border-gray-300 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline">
<option key="" value="">
            Select a listing type
        </option>
        {validTypes.map(type=>(
       <option key={type} value={type}>
       {type}
   </option>
        ))}   

     
    </select>
</div>
     <div className="w-full mb-6">
     <label className="block mb-2 text-sm font-medium text-gray-900 ">Start Date and Time:</label>
     <Controller
  name="startDate"
  control={control}
  rules={{ required: "Start Date and Time is required" }} // Adding the rules property
  className="block w-full"
  render={({ field, fieldState: { error } }) => (
    <>
      <DatePicker
        selected={field.value}
        onChange={(date) => field.onChange(date)}
        timeInputLabel="Time:"
        dateFormat="MM/dd/yyyy h:mm aa"
        showTimeInput
        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
      />
      {error && <p>{error.message}</p>} 
    </>
  )}
/>
     </div>

     <div className="w-full mb-6">
     <label className="block mb-2 text-sm font-medium text-gray-900 ">End Date and Time:</label>
     <Controller
  name="endDate"
  control={control}
  className="block w-full"
  render={({ field, fieldState: { error } }) => (
    <>
      <DatePicker
        selected={field.value}
        onChange={(date) => field.onChange(date)}
        timeInputLabel="Time:"
        dateFormat="MM/dd/yyyy h:mm aa"
        showTimeInput
        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
      />
    </>
  )}
/>
     </div>

     <div className="mb-6">
     <label className="block mb-2 text-sm font-medium text-gray-900 "> External URL: </label>
        <input className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " {...register("url", { required: true })} />   
     </div>
            
     <div className="mb-6">
     <label className="block mb-2 text-sm font-medium text-gray-900 ">  Event Subtitle / Note: </label>
        <textarea className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " {...register('note')}  rows="2"  />   
     </div>
     <div className="mb-6">
     <label className="block mb-2 text-sm font-medium text-gray-900 ">  About: </label>
        <textarea className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " {...register('about')}  rows="4"  />   
     </div>
       
          {!showMore && <button className=" bg-gray-50 hover:bg-black text-black font-semibold hover:text-white py-2 px-4 border border-black hover:border-transparent rounded" onClick={handleShowMore}>Show Additional Fields</button>}
          {showMore && (
  <>
  <div className="block mb-5 mt-10 font-medium text-gray-900 ">
    Additional Fields - <button onClick={handleShowMore}>Close</button>
  </div>
    <div className="mb-6">
      <label className="block mb-2 text-sm font-medium text-gray-900 ">
        Alternate Name:
      </label>
      <input {...register('alternateName')} className="input-style" />
    </div>

    <div className="mb-6">
      <label className="block mb-2 text-sm font-medium text-gray-900 ">
        Building Room:
      </label>
      <input {...register('room')} className="input-style" />
    </div>

    <div className="mb-6">
      <label className="block mb-2 text-sm font-medium text-gray-900 ">
        Language:
      </label>
      <input {...register('inLanguage')} className="input-style" />
    </div>

    <div className="mb-6">
      <label className="block mb-2 text-sm font-medium text-gray-900 ">
        Is Accessible for Free:
      </label>
      <Controller
        name="isAccessibleForFree"
        control={control}
        render={({ field }) => (
          <>
            <input type="radio" value={true} {...field} className="mr-2" />
            Yes
            <input type="radio" value={false} {...field} className="ml-4 mr-2" />
            No
          </>
        )}
      />
    </div>

    <div className="mb-6">
      <label className="block mb-2 text-sm font-medium text-gray-900 ">
        Sponsor Note:
      </label>
      <input {...register('sponsor')} className="input-style" />
    </div>

    <div className="mb-6">
      <label className="block mb-2 text-sm font-medium text-gray-900 ">
        External Image URL:
      </label>
      <input {...register('image')} className="input-style" />
    </div>

    <div className="mb-6">
      <label className="block mb-2 text-sm font-medium text-gray-900 ">
        External Image Alternative Text:
      </label>
      <input {...register('alt')} className="input-style" />
    </div>

    <div className="mb-6">
      <label className="block mb-2 text-sm font-medium text-gray-900 ">
        Keywords:
      </label>
      <input {...register('keywords')} className="input-style" />
    </div>

    <div className="mb-6">
      <label className="block mb-2 text-sm font-medium text-gray-900 ">
        Doortime:
      </label>
      <Controller
        name="doorTime"
        control={control}
        render={({ field }) => (
          <DatePicker
            {...field}
            selected={field.value}
            onChange={(date) => field.onChange(date)}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            timeCaption="Time"
            dateFormat="h:mm aa"
            className="input-style"
          />
        )}
      />
    </div>

    <div className="mb-6">
      <label className="block mb-2 text-sm font-medium text-gray-900 ">
        Status:
      </label>
      <Controller
  name="status"
  control={control}
  render={({ field: { onChange, value } }) => (
    <>
      <input
        type="radio"
        value="live"
        onChange={onChange}
        checked={value === 'live'}
        className="mr-2"
      />
      Live
      <input
        type="radio"
        value="editing"
        onChange={onChange}
        checked={value === 'editing'}
        className="ml-4 mr-2"
      />
      Editing
      <input
        type="radio"
        value="hiding"
        onChange={onChange}
        checked={value === 'hiding'}
        className="ml-4 mr-2"
      />
      Hiding
    </>
  )}
/>

    </div>

    <button className="button-style" onClick={handleShowMore}>
      Close Additional Fields
    </button>
  </>
)}

          </div> 
        
            <br/>

         {type==='Screening' && <ScreeningForm setMovieData={setAdditonalData} movieData={additionalData} listing={listing} />}
{listing.workingText && <p>
Scraped Working Text: {removeHtmlTags(listing.workingText)}
</p>
}
{!editor && <div className="mb-6">
        <label className="block mb-2 text-sm font-medium text-gray-900 ">
            Suggested edit comments:
            </label>

              <textarea   {...register('comment')} className="input-style" />
 
    </div>}
    {updateMessage}

         <button type="submit" className=" mt-4 bg-gray-50 hover:bg-black text-black font-semibold hover:text-white py-2 px-4 border border-black hover:border-transparent rounded" onClick={handleShowMore}>{!editor ? 'Suggest Listing Edit' : 'Update Listing'}</button>  
<hr />
       
      </form>

    </div>
  );
}

export default EditListingNew;
