import React, {useEffect, useState} from 'react';
import TopHeadAnimation from '../components/utilities/TopHeadAnimation';
import SecondPanel from '../components/utilities/SecondPanel';
import { Link } from 'react-router-dom';
function Homepage({setIsAuthenticated,isAuthenticated}) {
  const [select,setSelect] = useState(false);


const home  = async () => {
  try {
    const response = await fetch('/api/home',{
      headers: {
        'Content-Type': 'application/json', // Use a function to get the cookie value
              }
              });
    if(response.ok) {
      const data = await response.json();
      if(data) {
        setSelect({
          region: data.region,
          place: data.place,
          organizer: data.organizer,
          listing: data.listing,
        })
      }
    }
    else {
      console.error('Failed to load data. Fallback to defaults.')
    }
  }
  catch (error) {
    console.error(error);
  }
}
useEffect(() => {
  document.title = `Home - Zeitgeists`;
  home();
}, []);

  return (
    <div className='mt-36 underline-offset-8'>
        <div className='flex flex-wrap items-center justify-center align-middle'>
   
            <div className='md:w-1/2  w-full md:p-20 p-10 text-center'>
            <p className='text-4xl mb-4'>Catalyzing the Scene with Open Data</p>
            <p className='text-xl mb-8'>The Situationist's dream evolves—every coder a cartographer, every venue a vista, collaboratively charting the constellations of culture.</p>
            <div className='flex justify-center'>
            <p className='mt-4 mr-5'><Link className='rounded bg-black text-white  hover:underline p-4 text-xl' to={'/signup'}>Signup</Link></p>
            <p className='mt-4'><Link className='rounded bg-black text-white  hover:underline p-4 text-xl' to={'/login'}>Login</Link></p>
            </div>
            </div>
            <div className='md:w-1/2  w-full md:p-20  p-10 text-center'>
<TopHeadAnimation />
            </div>
            <div className='w-full text-center pt-10 pb-10 bg-black text-white'>
            <p className='text-4xl mb-4'>Open Cultural Events API</p>
               <p className='text-xl mb-8'>Use our free api to craft intriguing tools and websites that transform the way we engage with the arts.</p>
               <p className='mt-4'>
                
                <Link className='rounded bg-white text-black  hover:underline p-4 text-xl' to={'/listings'}>See the Listings</Link> 
                <Link className='ml-4 rounded bg-white text-black  hover:underline p-4 text-xl' to={'/api'}>Explore the API</Link>
               </p>
            </div>
            <div className='w-full'>
            <SecondPanel selectedListing={select.listing} selectedOrganizer={select.organizer} selectedPlace={select.place} selectedRegion={select.region}/> 
            </div>
<div className='w-full text-center px-4 bg-black text-white'>
    <div className='flex flex-col md:flex-row justify-center items-center space-y-4 md:space-y-0 md:space-x-4 mb-4'>
      {/* First Image and Description */}
      <div className="flex-1 flex flex-col items-center">
        <div className="w-56 h-56 overflow-hidden mb-2">
          <img
            src={'/people.png'}
            alt={''}
            className="w-full h-full object-cover invert"
          />
        </div>
        <p className="px-2 text-center text-xl">
        <span className='font-semibold'>Community-Powered Listings:</span> <br/>
         User Contributions Shape Our Calendar
                </p>
      </div>


      <div className="flex-1 flex flex-col items-center">
        <div className="w-56 h-56 overflow-hidden mb-2">
          <img
            src={'/scrape.png'}
            alt={''}
            className="w-full h-full object-cover invert"
          />
        </div>
        <p className="px-2 text-center text-xl">
        <span className='font-semibold'>Automated by Community:</span> <br/>
        Open-Source Place Calendar Integrations and Scraping
                        </p>
      </div>
      {/* Second Image and Description */}
      <div className="flex-1 flex flex-col items-center">
        <div className="w-56 h-56 overflow-hidden mb-2">
          <img
            src={'/inst.png'}
            alt={''}
            className="w-full h-full object-cover invert"
          />
        </div>
        <p className="px-2 text-center text-xl">
        <span className='font-semibold'>Dynamic Listings, Venue-Control:</span> <br/>
        Real-Time Updates from Cultural Institutions
                        </p>

      </div>

    </div>
</div>
<div className='w-full text-center '>
  <p className='text-xl font-light p-10'>zeitgeists.org emerges from the playful yet earnest foundations laid by <a rel="noreferrer" target="_blank" href="https://good1s.org" className='text-blue-700 hover:underline'>Good1s.org</a> and <a rel="noreferrer" target='_blank' href="https://teleology.foundation" className='text-blue-700 hover:underline'>the Teleological Foundation Los Angeles (TFLA)</a>. This project began as a personal tool to navigate LA's revival film scene with a convivial interface. It was crafted out of a need for a more intuitive event discovery experience and stands as a personal rebellion against one-size-fits-all solutions. Embracing this spirit, zeitgeists.org extends an invitation to the community, evolving into an open-source free platform for collaborative event data sharing, creating a collaborative platform for cultural interplay and discovery. It aspires to be a catalyst, empowering others to craft intriguing tools and websites that transform the way we engage with the arts.
</p>
</div>
</div>
    </div>
  );
}

export default Homepage;



