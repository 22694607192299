import React from "react";
import OrganizerInfo from "./OrganizerInfo";
import '../../custom.css'

function DisplayOrganizers({organizers, places, edit, gatekeeper, onChange,region}) {
    const removeOrg = async (id) => {
    
        if (window.confirm("Confirm remove organizer")!==true) {
          return;
      }
        try {
          const response = await fetch(`/api/organizer/${id}`, {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json', // Use a function to get the cookie value
                    },
            credentials: 'include', // Include cookies
               })
              if(response.ok) {
                onChange()
              }
          }
       catch(error) {
          console.error(error)
        }
      }
    return (
        organizers ? <div className=" masonry-grid">

            {organizers.map((organizer)=>
            <div className="masonry-grid-item p-1" key={organizer.id}>
                <OrganizerInfo region={region} organizer={organizer} places={places} light={true} deleteOrganizer={gatekeeper&&true} handleDelete={gatekeeper&&removeOrg}  />
            </div>
        )}
        </div> : 
        <div>No Organizers</div>

    )

    }
export default DisplayOrganizers