import React, { useEffect,useState } from 'react';
import AddListingNew from '../../components/listing/AddListingNew';
import GetListings from '../../components/listing/GetListings';
import ListingsIntro from '../../components/listing/ListingsIntro';
import { useParams } from 'react-router-dom';
function Listings({ setIsAuthenticated, isAuthenticated }) {
  const [regions,setRegions] = useState(false);

  const { regionName } = useParams();

  const onChange = () => {

  }


useEffect(() => {
    document.title = `Listings - Zeitgeists`;
    fetchListings();
}, []);
const fetchListings = async () => {
  try {
    const response = await fetch(regionName ? `/api/region/${regionName}/listings/` : `/api/listings/regions`);
    if (response.ok) {
      const data = await response.json();
      setRegions(data.regions);
    }
  } catch (error) {
    console.error(error)
  }
 }
  return (
    <div className="m-5 grid gap-3 grid-cols-5 pt-16">
      <div className="md:col-span-2 col-span-5">
        {isAuthenticated && regions ? (
          <div>
          <AddListingNew regions={regions} onChange={onChange}  />
          </div>
        ) : (<ListingsIntro />
        )}
      </div>
      <div className="md:col-span-3 col-span-5">
       <GetListings isAuthenticated={isAuthenticated} regions={regions&&regions.map(region=>(region.id))} filters={true} onChange={onChange}  />
      </div>


    </div>
  );
}

export default Listings;
