import React, { useState } from 'react';
import ProfileImage from './ProfileImage';
function EditUserProfile({onChange,profile}) {
  const [selectedFile, setSelectedFile] = useState(null);
  const [formData, setFormData] = useState({
    username: profile.username,
    email: profile.email,
    name: profile.name,
    description: profile.description,
    image: profile.image,
    alt: profile.alt,
  });

  const [errorMessage, setErrorMessage] = useState('');

  const handleFileChange = (e) => {
    const file = e.target.files[0];
    if (file) {
      if (file.size <= 5 * 1024 * 1024) {
        // File size is within the limit
        setSelectedFile(file);
      } else {
        // File size exceeds the limit
        alert('File size exceeds the 5MB limit');
      }
    }
  };
  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setFormData({ ...formData, [name]: value });
    setErrorMessage('');
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {

      const { username, name, description, alt } = formData;
       // Create a FormData object to handle file uploads
       const formDataToSend = new FormData();
       formDataToSend.append('username', username);
       formDataToSend.append('name', name);
       formDataToSend.append('description', description);
       formDataToSend.append('alt', alt);
 
       // Check if a new image was selected
       if (selectedFile) {
         formDataToSend.append('image', selectedFile);
       }
      // Make an API request to update user profile using the fetch API
      const response = await fetch('/api/user/', {
        method: 'POST',
 
        credentials: 'include', // Include cookies
        body: formDataToSend,
      });
      const data = await response.json();
      if (response.status === 201) {
        // User profile updated successfully
        // You can redirect to another page or display a success message here
       // console.log('User profile updated:', response);
       setErrorMessage('Profiled Updated!')
      }  else if (response.status === 400 && data.errors) {
        console.log(data.errors)
        setErrorMessage(<span className='text-red-500'>{data.errors.map(error=>(<span className='ml-1 mr-1'>{error.msg}</span>))}</span>);

      }
      else {
        setErrorMessage(<span className='text-red-500'>{data.message}</span>);
      }
    } catch (error) {
      // Handle API request errors and validation errors

    }
  };

  return (
    <div className='text-lg'>
      <p className='text-xl font-bold'>Edit Profile</p>
      <form onSubmit={handleSubmit}>
        <div className="mb-6">
          <label className="block mb-2 text-sm font-medium text-gray-900 " htmlFor="username">username:</label>
          <input
          className="bg-gray-50 border border-black text-gray-900 text-lg rounded-lg focus:ring-blue-500 focus:border-blue-500   p-2.5 "
            type="text"
            id="username"
            name="username"
            value={formData.username}
            onChange={handleInputChange}
          />
        </div>

        <div className="mb-6">
          <label className="block mb-2 text-sm font-medium text-gray-900 " htmlFor="name">name:</label>
          <input
          className="bg-gray-50 border border-black text-gray-900 text-lg rounded-lg focus:ring-blue-500 focus:border-blue-500   p-2.5 "
          type="text"
            id="name"
            name="name"
            value={formData.name}
            onChange={handleInputChange}
          />
        </div>
        <div className="mb-6">
          <label className="block mb-2 text-sm font-medium text-gray-900 "  htmlFor="description">Description:</label>
          <textarea className="bg-gray-50 border border-gray-300 text-gray-900 text-lg rounded-lg focus:ring-blue-500 focus:border-blue-500 p-2.5 " rows="4" value={formData.description} id="description" name="description" onChange={handleInputChange} />
        </div>
        <div className="mb-6">
        <label htmlFor="image">Select {profile.image && ' a new '}profile image (5 mb max):</label><br/>
      <input type="file" id='image' accept="image/*" onChange={handleFileChange} />

          {profile.image && <div className='text-center'>Current Profile Image:<div className='flex items-center text-center justify-center'><ProfileImage url={profile.image} alt={profile.alt} /></div></div>}
          {selectedFile && (
            <div>
              <p>Selected File: {selectedFile.name}</p>
            </div>
          )}
        </div>
        <div className="mb-6"> 
          <label className="block mb-2 text-sm font-medium text-gray-900 " htmlFor="alt">Alt text for image:</label>
          <input
                    className="bg-gray-50 border border-black text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500   p-2.5 "

            type="text"
            id="alt"
            name="alt"
            value={formData.alt}
            onChange={handleInputChange}
          />
        </div>

        {/* add social links sameAs here */}
        <div>
        <button type="submit" className='bg-black text-white rounded-full p-3 mt-5 hover:bg-white hover:text-black border-2 border-black'>Update Profile</button>

        </div>
      </form>
      {errorMessage && <p className="p-2 ">{errorMessage}</p>}
      <button onClick={() => onChange()} className='bg-black text-white rounded-full p-3 mt-5 hover:bg-white hover:text-black border-2 border-black'>Done Editing</button> 
    </div>
  );
}
//before clicking done I can have it be automatically updating or confirm before exitng
export default EditUserProfile;
