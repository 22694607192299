import React, { useEffect, useState } from 'react';
import { useParams, useNavigate, Link} from 'react-router-dom';
import OrganizerInfo from '../../components/organizer/OrganizerInfo';
import '../../custom.css'

function Organizers({setIsAuthenticated,isAuthenticated,role}) {
  const navigate = useNavigate();
  const [organizers,setOrganizers] = useState(false);
  const [places,setPlaces] = useState(false);

  const [region,setRegion] = useState(false);

  const { regionName } = useParams();

  //show user places if logged in and notifications about places
  //if not show blurb about places then a list of places
//get all places and display them with links to the places and buttons to edit if they are logged in
//nearby places using coords


useEffect(() => {
  document.title = 'Organizers - Zeitgeists';
  organizersFetch();

}, [isAuthenticated,setIsAuthenticated]);

  const organizersFetch = async () => {
    try {
      const response = await fetch(`/api/region/${regionName}/organizers`, {
        headers: {
          'Content-Type': 'application/json', // Use a function to get the cookie value
                },
                credentials: 'include', // Include cookies
      });
       if (response.ok) {
        const data = await response.json();
          setOrganizers(data.organizers);
          setRegion(data.region);
          setPlaces(data.places);
         // setRole(data.role);
        //  setShowInformation(false);

      }      else if (response.status===401) {
        setIsAuthenticated(false);
        localStorage.removeItem('isAuthenticated');
        localStorage.removeItem('role');
        navigate('/')
      } else {
     //     setIsAuthenticated(false);
      }
    } catch (error) {
      //console.log('Error fetching places', error);
//      setIsAuthenticated(false);

    }
  };
//see if user can delete with /organixers
  

  const removeOrg = async (id) => {
    
    if (window.confirm("Confirm remove organizer")!==true) {
      return;
  }
    try {
      const response = await fetch(`/api/organizer/${id}`, {
        method: 'DELETE',
        headers: {
          'Content-Type': 'application/json', // Use a function to get the cookie value
                },
        credentials: 'include', // Include cookies
           })
          if(response.ok) {
            organizersFetch()
          }
      }
   catch(error) {
      console.error(error)
    }
  }

  //your places
  //admin
  //
  return (

            
              <div className="text-center grid grid-cols-4 gap-4 items-center pt-20">
<div className="col-span-4">
              <p className="text-2xl font-bold">
    Organizers in {region&&<Link className='hover:underline' to={`/region/${region.urlName}`}>{region.name}</Link>}
</p>
</div>
<div  className="col-span-4 masonry-grid">
{organizers && (
          organizers.map((organizer) => (
           <div className="masonry-grid-item bg-white"key={organizer.id}>
                  {(role === 'superadmin' || role === 'gatekeeper') ? <OrganizerInfo region={region} organizer={organizer} deleteOrganizer={true} handleDelete={removeOrg} border={true} places={places} /> : <OrganizerInfo region={region} organizer={organizer} list={true} border={true} places={places} />}
           </div>
          ))
          ) }
</div>


              </div>
   

  );
}

export default Organizers;
/* 
               {
                  (owns || manages) && <div>
                    {owns && owns.length > 0 && <h3>My Owned Organizers</h3>}
                    <ul>{owns ? owns.map(org => 
                <li key={org._id}>
                  {org.name}  - <Link to={`/organizer/${org.urlName}/edit`}>Edit Organizer page</Link>
                
                </li>) : null}</ul>                       
                    {manages && manages.length > 0 && <h3>My Managed Organizers</h3>}
                    <ul>{manages ? manages.map(org => 
                <li key={org._id}>
                 {org.name}  - <Link to={`/organizer/${org.urlName}/edit`}>Edit Organizer page</Link>
                
                </li>) : null}</ul>   

                  </div>
                }
                */