import React, { useState, useEffect } from 'react';
import GetListings from '../listing/GetListings';

function DisplayLogs({ place, edit, logs, isAuthenticated }) {
  const [PI, setPI] = useState(false);
  const [filtered, setFiltered] = useState(logs || []);
  const [displayError, setDisplayError] = useState(null);
  const [seens, setSeens] = useState(logs.filter(log=>!log.isNew).map(log=>(log.uuid))||[])
  const handleFilter = (pi) => {
    setPI(pi);
    if (!pi) {
      setFiltered(logs);
    } else {
      setFiltered(logs.filter(log => log.placeIntegrationId === pi));
    }
  };

  const options = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  };
const markSeen = async (log) => {
  if(!isAuthenticated||!log.isNew) {
    return
  }
  try {
    setSeens([...seens,log.uuid])

    const response = await fetch(`/api/region/${place.region.urlName}/place/${place.urlName}/edit/seen/${log.uuid}`,
    {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        credentials: 'include', // Include cookies
      },
    });
    if(!response.ok) {
    setSeens(seens.filter(uuid=>uuid!==log.uuid));
    }
  }
  catch {

  }
}


  return (
    <div className="p-4">
      <div className="flex flex-wrap justify-center gap-4 m-2 mb-4">
        Filter Place Integrations: {place.placeIntegrations.map(pi => (
          <button onClick={() => handleFilter(pi.id)} className='underline'>
            {pi.name}
          </button>
        ))}
        {PI && <button onClick={() => handleFilter(null)} className='underline'>Remove Filters</button>}
      </div>
<span className='bg-green-600' />
<span className='bg-red-600' />
<span className='bg-red-100' />
<span className='bg-green-100' />

      <div className='flex flex-wrap justify-center gap-4'>
        {filtered.map(log => (
          <div key={log.uuid} className={`rounded bg-${log.errorLogs.length > 0 ? 'red' : 'green'}-${!seens.includes(log.uuid) ? '600' : '100'} py-4 px-2 w-full sm:w-1/2 md:w-1/3 lg:w-1/4 text-center cursor-pointer transition-all duration-300 ease-in-out transform hover:scale-105`} onClick={()=>markSeen(log)}>
            {!seens.includes(log.uuid) ? '' : <span>{log.errorLogs.length > 0 && '⚠️'}</span>} Log at {new Date(log.createdAt).toLocaleString('en-US', options)}
            <p className='text-lg underline'>{log.placeIntegration.name}</p>
            <p><strong>Added:</strong> {log.added} <strong>Updated:</strong> {log.updated}</p>
            {log.errorLogs && log.errorLogs.length > 0 && (
              <div>
                <p>⛔ Fails:</p>
                {log.errorLogs.map((error, i) => (
                  <div key={error.uuid} className="my-1">
                    <button className='underline hover:text-black' onClick={() => setDisplayError(displayError === error.uuid ? null : error.uuid)}>
                      Display Error Log
                    </button>
                    {displayError === error.uuid && (
                      <div className="text-white bg-gray-800 p-2 rounded mt-2 overflow-scroll">
                        {log.errorLogs > 1 && i + '.'} {error.message}
                      </div>
                    )}
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
}

export default DisplayLogs;
