import React, { useEffect } from 'react';
import ZeitgeistAPIDocumentation from '../components/ZeitgeistAPIDocumentation';
function API({setIsAuthenticated,isAuthenticated}) {
  //show user places if logged in and notifications about places
  //if not show blurb about places then a list of places
//get all places and display them with links to the places and buttons to edit if they are logged in
//nearby places using coords

 
useEffect(() => {
  document.title = 'API - Zeitgeists';

}, []);

  



  return (
    <div className='mt-16 text-center'>
            
<ZeitgeistAPIDocumentation />



    </div>


  );
}

export default API;



