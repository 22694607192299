import React, { useState, useEffect } from 'react';
//add confirm and fix external links
import moment from 'moment-timezone';
import { useForm, Controller } from 'react-hook-form';

import { useNavigate } from 'react-router-dom';
function EditRegion({onChange, region, isAuthenticated,setIsAuthenticated}) {
  const { handleSubmit, watch, control, register, setError, reset, formState: { errors }} = useForm({
    defaultValues: {
        name: region.name,
        timezone: region.timezone,
        description: region.description,
        latitude: region.latitude,
        longitude: region.longitude,
        zoom: region.zoom,
  }});

    const [errorMessage,setErrorMessage] = useState('');
    const navigate = useNavigate();
    function SuccessMessage() {
      return (
        <div className="border text-xl border-green-400  bg-green-100 px-4 py-3 text-green-700">
          Region update successful.
        </div>
      );
    }
    function FailMessage() {
      return (
        <div className="border text-xl border-red-400  bg-red-100 px-4 py-3 text-red-700">
        Error editing region.
        </div>
      );
    }
    useEffect(() => {
      if(isAuthenticated) {
        setErrorMessage('');
      } 
      }, [isAuthenticated]);
    

      const [timezoneOptions, setTimezoneOptions] = useState([]);
    

      useEffect(() => {
        const allTimezones = moment.tz.names();
        setTimezoneOptions(allTimezones);
      }, []);
    
      const onSubmit = async (data) => {
        if (window.confirm("Confirm Edit Region")!==true) {
            return
        }
        try {
          const zoom = parseInt(data.zoom);
          const latitude = parseFloat(data.latitude);
          const longitude = parseFloat(data.longitude);
      
          console.log(data);
              const { name, description, timezone} = data;

          // Make an API request to update user profile using the fetch API
           const response = await fetch('/api/region/'+region.id, {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
            },
            body: JSON.stringify({
              name,
              description,
              timezone,
              ...(Number.isFinite(zoom) && { zoom }),
              ...(Number.isFinite(latitude) && { latitude }),
              ...(Number.isFinite(longitude) && { longitude }),
            }),
          });
    
          if (response.status === 201) {
            // User profile updated successfully
            // You can redirect to another page or display a success message here
           // console.log('User profile updated:', response);
           setErrorMessage(<SuccessMessage />)
           onChange();
          } else if (response.status===401) {
            setIsAuthenticated(false);
            localStorage.removeItem('isAuthenticated');
            localStorage.removeItem('role');
            navigate(`/login/region/${region.urlName}/edit`)
          }
          else {

          } 
          // Call the addRegion function with regionData
        //  await addRegion(regionData);
          // Clear the form or perform other actions as needed
        } catch (error) {
          // Handle any errors that may occur during region creation
          setErrorMessage(<FailMessage />)
          console.error('Error adding region:', error);
        }
      };

  return (
    <div className="m-4">
            <p className='text-lg pb-4 font-semibold'>Edit Region Details:</p>

    <form onSubmit={handleSubmit(onSubmit)} className='flex flex-col space-y-4'>
      <div className=''>
        <label className='block mb-2 text-sm font-medium text-gray-900 '>Name:</label>
        <input
        className='bg-gray-50 border border-gray-300 text-gray-900 text-lg rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 '
          type="text"
          name="name"
          {...register('name')}

        />
      </div>
      <div>
          <label className='block mb-2 text-sm font-medium text-gray-900 ' htmlFor="timezoneSelect">Select a Timezone:</label>
          <Controller
            name="timezone"
            control={control}
            render={({ field }) => (
              <select
                {...field}
                className='bg-gray-50 border border-gray-300 text-gray-900 text-lg rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 '
              >
                <option value="">Select a Timezone</option>
                {timezoneOptions.map(tz => (
                  <option key={tz} value={tz}>
                    {tz}
                  </option>
                ))}
              </select>
            )}
          />
        </div>


      {/* Add input fields for other region properties */}
      <div>
        <label className='block mb-2 text-sm font-medium text-gray-900 '>Description:</label>
        <textarea
                className='bg-gray-50 border border-gray-300 text-gray-900 text-lg rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 '
          {...register('description')}
        />
      </div>

      <div className=''>
        <label className='block mb-2 text-sm font-medium text-gray-900 '>Center Point Latitude:</label>
        <input className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " {...register("latitude")} />   
      </div>
      <div className=''>
        <label className='block mb-2 text-sm font-medium text-gray-900 '>Center Point Longitude:</label>
        <input className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " {...register("longitude")} />   
      </div>
      <div className=''>
        <label className='block mb-2 text-sm font-medium text-gray-900 '>Map Zoom:</label>
        <input className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " {...register("zoom")} />   
      </div>
      <div>
        <button type="submit" className='bg-gray-50 hover:bg-black text-black font-semibold hover:text-white py-2 px-4 border border-black hover:border-transparent rounded'>Save Edit</button>
      </div>
      {errorMessage}
          </form>
          </div>
  );
}

export default EditRegion;

