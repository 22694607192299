import React, { useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';

function LoginForm({ setRole, setIsAuthenticated,nextPage }) {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [status, setStatus] = useState(false);
  const navigate = useNavigate();
  function FailMessage ({message})  {
    return (
      <div className="mt-5">
      <div className="border border-red-400 bg-red-100 px-4 py-3 text-red-700">
        <p>{message}</p>
      </div>
    </div>
    )
  }
  function SuccessMessage ({message})  {
    return (
      <div className="mt-5">
      <div className="border border-green-400 bg-green-100 px-4 py-3 text-green-700">
        <p>{message}</p>
      </div>
    </div>
    )
  }

  function SuccessMessage ({message})  {
    return (
      <div className="mt-5">
      <div className="border border-green-400 bg-green-100 px-4 py-3 text-green-700">
        <p>{message}</p>
      </div>
    </div>
    )
  }

const newEmail = async () => {
  if(email) {
    try {
      const response = await fetch(`/api/new-confirm-token/${email}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.ok) {
        const data = await response.json();
        setStatus(<SuccessMessage message={`Confirmation email from noreply@zeitgeists.org sent to ${email}.`} />);

      } else {
         // Handle login error
         console.error('New confirm failed');
         setStatus(<FailMessage message={'New confirm failed. Try again.'} />);
      }
    }
    catch (error) {
      console.error(error)
    }
  }
}

  const handleSubmit = async (e) => {
    e.preventDefault();

    try {
      // Send a request to your server to authenticate the user
      const response = await fetch('/api/login', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, password }),
      });

      if (response.ok) {
        const data = await response.json();
        const { role } = data;

        // Set the user's role and authentication status
        setRole(role);
        setIsAuthenticated(true); // Set authentication status to true
        localStorage.setItem('isAuthenticated', 'true');
        localStorage.setItem('role', role);


        // Redirect to another page or perform further actions
        setStatus('Login success!');
        if(nextPage) {
          navigate(`/${nextPage}`);
        } else {
          navigate('/dashboard')
        }
      } else if(response.status === 403) {
        // Handle login error
        console.error('Email not verified');
        setStatus(<div> <FailMessage message={'Email not verified.'} /> <p className='mt-4'><button className='underline' onClick={newEmail}>Click here to resend confirmation email</button></p></div>);

       
      } else {
        setStatus(<FailMessage message={'Login failed. Try again.'} />);
        setPassword('');
        setEmail('');
      }
    } catch (error) {
      console.error('Error:', error);
    }
  };

  return (


<div className="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8">
  <div className="sm:mx-auto sm:w-full sm:max-w-sm">
    <h2 className="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-black">Sign in to your account</h2>
  </div>

  <div className="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
    <form className="space-y-6" onSubmit={handleSubmit}>
      <div>
        <label htmlFor="email" className="block text-sm font-medium leading-6 text-black">Email address</label>
        <div className="mt-2">
          <input id="email" name="email" type="email" htmlFor="email" value={email} onChange={(e) => setEmail(e.target.value)} required className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-xl rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " />
        </div>
      </div>

      <div>
        <div className="flex items-center justify-between">
          <label htmlFor="password" className="block text-sm font-medium leading-6 text-black">Password</label>
          <div className="text-sm">
            <Link to="/forgotpassword/" className="font-semibold text-gray-600 hover:text-gray-500">Forgot password?</Link>
          </div>
        </div>
        <div className="mt-2">
          <input id="password" name="password" type="password" value={password}  onChange={(e) => setPassword(e.target.value)} autoComplete="current-password" required className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-xl rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " />
        </div>
      </div>       
      <div>
      <button
  type="submit"
  className="w-full px-4 py-2 text-lg font-semibold text-black bg-white border border-black rounded-md shadow-sm hover:bg-black hover:text-white focus:outline-none focus:ring focus:ring-gray-600"
>
  Sign in
</button>

      </div>
    </form>
    { status && <div className="mt-5">

  {status}
</div>}
    <p className="mt-5 text-center text-lg text-gray-500">
      Don't have an account? {' '}
      {nextPage ? <Link to={`/signup/${nextPage}`} className="font-semibold leading-6 text-black hover:text-gray-500">Sign up now</Link> : <Link to={'/signup'} className="font-semibold leading-6 text-black hover:text-gray-500">Sign up now</Link>}
    </p>
   
   


  </div>
</div>
  );
}

export default LoginForm;



