import React from 'react';
import { Link } from 'react-router-dom';

const PlacesCTA = ({ urlName, place }) => {
  return (
    <div className="p-6 bg-white shadow-md rounded border-black border mt-2">
      <h2 className="text-2xl font-semibold mb-4">Join Our Open Cultural Database</h2>
      <p className="mb-4">
        As an open platform, Zeitgeists.org offers venues a free space to claim, manage, and showcase their events. Collaborate in our shared mission to make cultural data accessible to all.
      </p>
      <p className="mb-4">
        Help us grow our repository. If you're familiar with a venue's schedule, contribute by editing listings to ensure accuracy. Or, use your coding skills to create a scraper that keeps all venue events up-to-date. Collaborate to maintain a vibrant, accurate directory of places.
      </p>
      <div className="flex flex-row sm:flex-wrap justify-center gap-4 ">
       {place?.owns?.length < 1 ? 
        <a href={`mailto:claim@zeitgeists.org?subject=Claim: ${place.name}`} className="px-4 py-2 bg-green-200 text-black text-sm font-medium rounded hover:bg-green-500 inline-block mt-2">Claim Your Place</a> : 
        <span className="px-4 py-2 bg-green-200 text-black text-sm font-medium rounded inline-block mt-2">Place Claimed</span>}
        <Link to={`/place/${urlName}/edit`} className="px-4 py-2 bg-orange-200 text-black text-sm font-medium rounded hover:bg-orange-500 inline-block mt-2">Contribute Data</Link>
        {place.scraperUrl ? <Link to={place.scraperUrl} className="px-4 py-2 bg-blue-200 text-black text-sm font-medium rounded hover:bg-blue-500 inline-block mt-2">Modify the Scraper on Github</Link> : <Link to={'https://github.com/zeitgeistsorg'} className="px-4 py-2 bg-blue-200 text-black text-sm font-medium rounded hover:bg-blue-500 inline-block mt-2">Create a Scraper</Link>}
      </div>
    </div>
  );
};

export default PlacesCTA;
