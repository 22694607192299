import React, {useState} from "react";
import { Link } from "react-router-dom";
import PlaceInfo from "../place/PlaceInfo"
import OrganizerInfo from "../organizer/OrganizerInfo"
import RegionInfo from "../region/RegionInfo"
import DisplayListing from "../listing/DisplayListing"
function LeftPanel({data,left}) {

    const [toggle,setToggle] = useState(true)
    const prettyJson = (jsonObject) => {
        return <div className="container mx-auto p-2">

        <pre className="bg-gray-100 p-2 rounded-lg overflow-hidden">
          {JSON.stringify(jsonObject, null, 2)}

        </pre>
      </div>
      }
    const selectedCode = () => {
        switch(left.label) {
            case 'Listings': return prettyJson(data.listing);
            case 'Places': return prettyJson(data.listing.place);
            case 'Organizers': return prettyJson(data.organizer);
            case 'Regions': return prettyJson(data.listing.region);
            default: return '';
        }
    }
    const selectedView = () => {
        switch(left.label) {
            case 'Listings': return <DisplayListing region={data.listing.region}  listing={data.listing} home={true} />;
            case 'Places': return <PlaceInfo place={data.listing.place} region={data.listing.region} home={true}/>;
            case 'Organizers': return <OrganizerInfo organizer={data.organizer}  home={true} />;
            case 'Regions': return <RegionInfo region={data.listing.region} home={true} />;
            default: return '';
        }
    }
    const selectedText = () => {
        switch (left.label) {
            case 'Listings': 
            return (
                <div>
                    Listings are the individual events hosted by Places, like exhibitions or concerts. They provide details about what's happening, where, and when. If an event is on a Place's calendar, it automatically appears in our Listings, offering an up-to-date guide to local cultural events.
                </div>
            );
        
                case 'Places': 
                return (
                    <div>
                        Places are specific venues like museums, theaters, or book stores. They host the events that make up our Listings. Each Place contributes its unique events to the broader cultural landscape, enriching the community's array of activities.
                    </div>
                );
                case 'Organizers': 
                return (
                    <div>
                        Organizers, often doubling as Places themselves, play a key role in the cultural landscape. They are involved in setting up events, whether it's independently or in collaboration with other venues. Their involvement varies, but they contribute significantly to the richness and diversity of the events available in our listings.
                    </div>
                );
            
                case 'Regions': 
                return (
                    <div>
                        Regions are geographical areas, like cities or metro areas, that manage the Places and Organizers within them. They provide context for where Places and their events are located, helping to organize and cultivate the cultural landscape by location.
                    </div>
                );
                case 'API': 
                return (
                    <div>
Our REST API is a dynamic digital conduit, offering free and comprehensive access to a wealth of cultural data. Our API seamlessly connects you to an array of event listings, venue details, and organizer profiles. It's designed to provide real-time updates and customizable data interactions, making it an invaluable tool for developers and cultural enthusiasts alike, who wish to explore, integrate, and innovate within the vibrant landscape of events and venues we offer.                    </div>
                );
                case 'Integrations': 
                return (
                    <div>
Within our platform, we employ a variety of tools to streamline event data management effectively. Community-developed scrapers are key in collecting event listings, while our bots routinely check and refine this data for precision. We also integrate with standard calendar systems such as Eventbrite and iCal, ensuring seamless event data synchronization. This direct and efficient method, bolstered by our API for bespoke integrations, equips place managers and community members with everything needed to maintain accurate and current event information with ease. Furthermore, we're continually expanding our capabilities, actively working to support more calendar systems, enhancing our service and offering even more options for integration.</div>
                );
            default: 
                return '';
        }
    }
    return (
        <div className='ml-10 mr-10 mb-4 text-black'>
           <div className="text-4xl underline ">{left.label}</div>
           <div className="text-2xl mt-4 mr-4 mb-4">{selectedText()}</div>
           {left.label !=='API' &&  left.label !=='Integrations' && <div className=" h-96 rounded overflow-hidden relative border border-black bg-white">
                <button className="right-0 absolute p-2 rounded-tr bg-orange-200 hover:bg-orange-400 font-semibold" onClick={()=>setToggle(!toggle)}>Toggle</button>
            {toggle ? <div className="bg-gray-100 h-full">
            {selectedCode()}
            <Link to={'/api'} >
            <span className="absolute bottom-0 w-full p-2 rounded-b bg-green-200 text-center hover:bg-green-300">
   Explore the API Docs
  </span></Link>
            </div> : <div className="bg-white h-full ">
                {selectedView()}
            <Link  >
            <span className="absolute bottom-0 w-full p-2 rounded-b bg-gray-200 text-center ">
            {left.label} View
  </span></Link>
                </div>}
            
            </div>}
        </div>
    )
}
export default LeftPanel