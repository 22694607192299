import React, {useEffect} from 'react';
import { Link } from 'react-router-dom';
import DisplaySuggestedAdds from './DisplaySuggestedAdds';
const moment = require('moment-timezone');

function DisplayListing({ listing, organizers, place, region, suggestions, suggestion,home}) {
  useEffect(() => {
  }, [suggestions]);
  
  function formatISOInLocal(isoString) {
    // Format: 'MMM DD, h:mm A' e.g., 'Nov 17, 6:00 AM'
    return moment(isoString).format('MMM DD, h:mm A');
}

function formatISOInTimezone(isoString, timezone) {
    // Format: 'MMM DD, h:mm A' e.g., 'Nov 17, 6:00 AM'
    return moment(isoString).tz(timezone).format('MMM DD, h:mm A');
}


const formatDateRegionString = (listing) => {
return <div className="text-xl">
{listing.startDate && (
 <span className=''>{formatISOInTimezone(listing.startDate,region.timezone)}</span>
) }
      {listing.endDate && (
 <span>     {' → '}  
 {formatISOInTimezone(listing.endDate,region.timezone)}</span>
) }


</div>
}
  let linkPath;
  let linkText;
if (!suggestion) {
  linkText = listing.name ? listing.name : 'No Main Listing Title';
  linkPath = `/listing/${listing.uuid}`;
} else if (suggestion && suggestion.suggestedAdd  && !suggestion.listingId) {
  linkText = listing.name ? listing.name : 'No Main Listing Title';
  linkPath = `/region/${region.urlName}/place/${listing.place.urlName}/edit/suggestions?id=${suggestion.uuid}`;
} else if (suggestion && suggestion.suggestedAdd && suggestion.listingId && suggestion.listing) {
  linkText = listing.name ? listing.name : 'No Main Listing Title';
  linkPath = `/listing/${suggestion.listing.uuid}`;
}
let editLinkPath;
let background = 'border border-black';
if (!suggestion) {
  editLinkPath = `/listing/${listing.uuid}/edit`;
} else if (suggestion && suggestion.type !== 'edit') {
  editLinkPath = `/region/${region.urlName}/place/${listing.place.urlName}/edit/suggestions?id=${suggestion.uuid}`;
  background = suggestion.status === 'rejected' ? 'bg-red-300' : suggestion.status === 'expired' ? 'bg-yellow-300' : 'bg-purple-300';
} else if (suggestion) {
  background = suggestion.status === 'approved' && 'bg-green-300';
  editLinkPath = `/listing/${suggestion.listingId}/edit`;
}
if(home) {
  background = '';
}
  return (
    <div key={listing.uuid} className={`p-4 rounded shadow mb-4 ${background}`}>
      {listing.status && (
                <a href={editLinkPath} >

{suggestion ? <div className={`rounded flex flex-wrap  justify-center gap-2 p-1 text-lg  border border-black border-1`}>
  <div>
  Status: <span className="capitalize">{suggestion.status}</span>

  </div>
  <div> 

{suggestion.createdAt && (
            <div>
              Submitted At: {formatISOInLocal(suggestion.createdAt)} by{' '}
              {suggestion.user ? <Link to={`/user/${suggestion.user.username}`} className="text-blue-600">
                @{suggestion.user.username}
              </Link> : 'Integration'}
              
            </div>
          )}
                    </div> 

         <div>
         {suggestion.comment && <p>Comment: <span className="bg-green-200">{suggestion.comment}</span></p>}
          </div> 

</div>  : <div className={`rounded flex items-center  border border-black border-1 ${listing.status == 'live' ? 'bg-green-400' : listing.status == 'hiding' ? 'bg-red-400' : listing.status == 'editing' ? 'bg-yellow-400' : ''}`}>
    <div className=' ml-1 flex-1 min-w-0'>
      {listing.lastEdit ? (listing.lastEdit.lastDate && (
        <div className='font-medium truncate'>
                    Last Updated: {formatISOInLocal(listing.lastEdit.lastDate)} {listing.lastEdit.lastUsername ? <>
                      by{' '}  <Link to={`/user/${listing.lastEdit.lastUsername}`} className="text-blue-600">
            @{listing.lastEdit.lastUsername}
          </Link> </> : <>Integration</>}
        </div>
      )) : listing.edit}  
        </div>

      <div className='flex-shrink-0 flex items-center mr-1'> 
      <span className={`hidden sm:inline-block m-1 p-2 font-semibold bg-white ${listing.status == 'live' ? 'text-green-500' : listing.status == 'hiding' ? 'text-red-500' : listing.status == 'editing' ? 'text-yellow-500' : ''}`}>
          {listing.status.toUpperCase()}
        </span> 
        <span className=" sm:bg-yellow-500 sm:hover:bg-yellow-900 sm:hover:text-white sm:border rounded-full border-black p-1  inline-block">
    Edit Listing
        </span>

      </div>
    </div> }
    </a> 

)}
<div className="relative grid p-1 ">
  <Link to={linkPath} className="text-2xl font-bold hover:underline z-10 mr-20">
    {linkText}
  </Link>

</div>

       <p > 
       { listing.place &&
              <span className='inline-block mt-2' >
       <Link to={`/region/${region.urlName}/place/${listing.place.urlName}`} className="rounded-lg mr-1 border border-1 border-black p-1 hover:text-white hover:bg-black ">
       {listing.place.name}
     </Link>
     </span>
       }
 
      {listing.organizers && listing.organizers.length > 0 && (
        <>
          {listing.organizers.map((lo) => (

<span className='inline-block mt-2' key={lo.organizer.id}>

              <Link to={`/region/${region.urlName}/organizer/${lo.organizer.urlName}`} className="mr-1 rounded-full border border-1 border-black p-1 hover:text-white hover:bg-black ">
                {lo.organizer.name}
              </Link>
              </span>
          ))}
        </>
      )}
            {listing.type &&         <span className='inline-block mt-2' >
<span className="mr-1 border border-1 border-black p-1 hover:text-white hover:bg-black ">{listing.type}</span></span>}

            </p>
      {listing&&region&&formatDateRegionString(listing)}

      
      {listing.url && (
        <p >
         <strong>URL:</strong>  {' '}
          <a
            href={listing.url}
            target="_blank"
            rel="noopener noreferrer"
            className="underline break-all"
          >
          {listing.url}
          </a>
        </p>
      )}

      {listing.QA  && (
        <p>Q&A: {listing.QA ? 'Yes' : 'No'}</p>
      )}
                 {listing.note && (
        <p>
          Note / Subtitle: {listing.note}
        </p>
      )}

      {listing&&listing.type==='Screening' && listing.worksPresented && listing.worksPresented.length > 0 && (
  <div>

    <div>
      { listing.worksPresented.length > 0  && (
        <strong>Work Presented ({listing.worksPresented.length}): </strong>
      )}
    </div>
    <div className='grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4'>
  {listing.worksPresented.length < 6 && listing.worksPresented.map((work) => (
    <div key={work.uuid} className="bg-white overflow-hidden mt-2 pt-2 border border-black">
      <div className="p-4">
        <h3 className="text-lg font-semibold mb-2">Title: {work.name}</h3>
        {work.year && (
          <p className="text-sm mb-1"><strong>Release Year:</strong> {work.year}</p>
        )}
        {work.duration && (
          <p className="text-sm mb-1"><strong>Duration:</strong> {work.duration.length > 400 ? 'Problem with duration' : work.duration} Mins</p>
        )}
        {work.director && (
          <p className="text-sm mb-1"><strong>Director:</strong> {work.director.length > 500 ? 'Problem with director' : work.director}</p>
        )}
        {work.videoFormat && (
          <p className="text-sm mb-1"><strong>Video Format:</strong> {work.videoFormat}</p>
        )}
        {work.TMDB && (
          <p className="text-sm mb-1"><a href={`https://letterboxd.com/tmdb/${work.TMDB}`} target='_blank' className='text-blue-500 underline'>Letterboxd Link</a></p>
        )}
      </div>
    </div>
  ))}
</div>



                      
  </div>
)}

{suggestions && listing && (
        <div className="border-t mt-2 pt-2">
          <DisplaySuggestedAdds listing={listing} data={suggestions} closeSuggestions={true} />
        </div>
      )}



           
<div className='text-right'>
In {region && <Link className='underline' to={`/region/${region.urlName}`}>{region.name}</Link>} 
</div>

    </div>
  );
}

export default DisplayListing;
