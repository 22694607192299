import React, { useEffect, useState } from 'react';
import { useNavigate,Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';

import DisplayScrapes from '../../components/place/DisplayScrapes';
import EditBar from '../../components/utilities/EditPlaceBar';

function EditPlaceScrapes({setIsAuthenticated, isAuthenticated, role }) {
  const navigate = useNavigate();
    const [place,setPlace] = useState(false);

   

    //get place by string
    const [editPlace,setEditPlace] = useState(false);
    const [scrapes,setScrapes] = useState(false);
    const [filtered, setFiltered] = useState(false);

    useEffect(() => {
      // Fetch region details when the component mounts
      fetchPlaceDetails();
      //fetch user list in place detauks
    }, []);
    useEffect(() => {
      // Fetch region details when the component mounts
    }, [filtered]);
    useEffect(() => {
      if(place) {
        document.title = `Edit ${place.name} Scrapes - Zeitgeists`;
      } else {
        document.title = `Place - Zeitgeists`;
      }
    }, [place]);
    
      const { urlName } = useParams(); // Access the "urlName" route parameter


      const fetchPlaceDetails = async () => {
        try {
            const response = await fetch(`/api/place/${urlName}/edit`, {
                headers: {
                  'Content-Type': 'application/json', // Use a function to get the cookie value
                        },
                        credentials: 'include', // Include cookies
              });
               if (response.ok) {
                const data = await response.json();
                  setPlace(data.place);
                  if(response.status ===201) {
                    setEditPlace(data.edit);
                    setScrapes(data.scrapes);
                  }
              }
              if(response.status===401) {
                navigate(`/login/place/${urlName}/edit`)
              }
        } catch (error) {

        }
      }
//display place people

   return (

    
    <div className="p-4 mt-36">
      {place && <p className="center font-bold text-2xl m-4 underline"><Link to={`/place/${place.urlName}/`}> {place.name}</Link></p>}
      { place && <EditBar type="place" id={place._id} active={'Scrape Logs'}/>}   


<div>
{scrapes && place &&  <DisplayScrapes edit={editPlace} place={place} scrapes={scrapes} setFiltered={setFiltered}/>}
</div>

    
  
 
    

       </div>


    
  );
}

export default EditPlaceScrapes;
