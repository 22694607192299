import React, {useState,useEffect} from 'react';
import GetListings from '../listing/GetListings';

function DisplayScrapes({ place, scrapes, setFiltered, notSetFiltered, edit }) {
  console.log(edit)
  const options = {
    year: 'numeric',
    month: 'numeric',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
    hour12: true,
  };
  const [seenScrapes, setSeenScrapes] = useState(() =>
  scrapes.filter(scrape => !scrape.new).map(scrape => scrape._id));
  
  const initialDisplayCount = 10;
  const [displayCount, setDisplayCount] = useState(initialDisplayCount);
  const [filteredScrapes, setFilteredScrapes] = useState([]);

const [showListings,setShowListings] = useState(false);
const [showListingsId,setShowListingsId] = useState(false);

  useEffect(() => {
    setFilteredScrapes(scrapes.slice(0, displayCount));
  }, [scrapes, displayCount]);
 
  const handleLoadMore = () => {
    setDisplayCount(prevCount => prevCount + 10); // Load 10 more scrapes
  };
  const handleScrapeClick = async (scrapeId) => {
    // Add the scrapeId to the seenScrapes Set
    if(!edit) {
      return
    }
    setSeenScrapes([...seenScrapes,scrapeId]);
    // Make a POST request to mark the scrape as seen
    try {
      const response = await fetch(`/api/seenscrape/${scrapeId}/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
      });
      if(response.ok) {

      }
      if (!response.ok) {
        //remove on bad
        setSeenScrapes(seenScrapes.filter(scrape=>scrape!==scrapeId));
      }
      

      // Handle success if needed
    } catch (error) {
      console.error(error);
      // Optionally set error state here
    }
  };
  const [activeFilter, setActiveFilter] = useState('');
  const handleFilter = (type) => {
    setActiveFilter(type);
        switch (type) {
      case 'unseen':
        setFilteredScrapes(scrapes.filter(scrape => !seenScrapes.includes(scrape._id)).slice(0, 10));
        break;
      case 'fails':
        setFilteredScrapes(scrapes.filter(scrape => scrape.fail.length > 0).slice(0, 10));
        break;
      case 'errors':
        setFilteredScrapes(scrapes.filter(scrape => scrape.error.length > 0).slice(0, 10));
        break;
      case 'newListings':
        setFilteredScrapes(scrapes.filter(scrape => scrape.success.length > 0).slice(0, 10));
        break;
      default:
        setFilteredScrapes(scrapes.slice(0, 10));
    }
  };

  return (

    <div className="p-4">
       {place && place.scraperUrl &&<p className="mb-6">Make a contribution to the scraper <a target="_blank" rel="noreferrer" className="text-blue-600 underline" href={place.scraperUrl}>{place.scraperUrl}</a> </p>}

       <div className="flex flex-wrap justify-center gap-2 m-2 mb-4">
  {scrapes.filter(scrape => scrape.new).length > 0 && (
    <button
      onClick={() => handleFilter('unseen')}
      className={`flex items-center gap-2 px-4 py-2  rounded hover:bg-blue-700 focus:outline-none ${activeFilter === 'unseen' ? 'bg-blue-500' : 'bg-blue-200'}`}
    >
      <span className="px-3 py-1 bg-blue-500 rounded-full">
        {scrapes.filter(scrape => scrape.new).length}
      </span>
      Unseen
    </button>
  )}
  {scrapes.filter(scrape => scrape.error.length > 0).length > 0 && (
    <button
      onClick={() => handleFilter('errors')}
      className={`flex items-center gap-2 px-4 py-2 rounded hover:bg-yellow-700 focus:outline-none ${activeFilter === 'errors' ? 'bg-yellow-500' : 'bg-yellow-200'}`}
    >
      <span className="px-3 py-1 bg-yellow-500 rounded-full">
        {scrapes.filter(scrape => scrape.error.length > 0).length}
      </span>
      Errors
    </button>
  )}
  {scrapes.filter(scrape => scrape.fail.length > 0).length > 0 && (
    <button
      onClick={() => handleFilter('fails')}
      className={`flex items-center gap-2 px-4 py-2 rounded hover:bg-red-700 focus:outline-none ${activeFilter === 'fails' ? 'bg-red-500' : 'bg-red-200'}`}
    >
      <span className="px-3 py-1 bg-red-500 rounded-full">
        {scrapes.filter(scrape => scrape.fail.length > 0).length}
      </span>
      Fails
    </button>
  )}
  {scrapes.filter(scrape => scrape.success.length > 0).length > 0 && (
    <button
      onClick={() => handleFilter('newListings')}
      className={`flex items-center gap-2 px-4 py-2  rounded hover:bg-green-700 focus:outline-none ${activeFilter === 'newListings' ? 'bg-green-500' : 'bg-green-200'}`}
    >
      <span className="px-3 py-1 bg-green-500 rounded-full">
        {scrapes.filter(scrape => scrape.success.length > 0).length}
      </span>
      Added Listings
    </button>
  )}
  {scrapes.length > 0 && (
    <button
      onClick={() => handleFilter('all')}
      className={`flex items-center gap-2 px-4 py-2 rounded hover:bg-gray-700 focus:outline-none ${activeFilter === 'all' ? 'bg-gray-500' : 'bg-gray-200'}`}
    >
      <span className="px-3 py-1 bg-gray-500 rounded-full">
        {scrapes.length}
      </span>
      No Filter
    </button>
  )}
</div>


      {seenScrapes && filteredScrapes.map((scrape) => (
        <div key={scrape._id} className="mb-6 p-4 border rounded-lg shadow space-y-2">
          <div
            onClick={() => handleScrapeClick(scrape._id)}
            className={`cursor-pointer ${!seenScrapes.includes(scrape._id) ? 'text-gray-900' : 'text-gray-500'}`}
          >
            <div>
              {seenScrapes.includes(scrape._id) ? '' : <span>🆕{scrape.error.length>0&&'⚠️'}{scrape.fail.length>0&&'⛔'}</span> }  Scrape at {new Date(scrape.scrapedAt).toLocaleString('en-US', options)}
            </div>
            <p>Inserted: {scrape.inserted}, Updated: {scrape.updated}</p>
          {scrape.success.length > 0 && (
            <div>
              <p>{scrape.success.length} New successful scrapes.</p>
              {showListings !== scrape.success && <div>
            <button onClick={()=>{setShowListingsId(scrape._id); setShowListings(scrape.success);}}>Open Success Listings</button>
            </div>
          }
            </div>
          )}
          {scrape.error.length > 0 && (
            <div>
              <p>{scrape.error.length} ⚠️ Errors</p>
              {showListings !== scrape.error && <div>
            <button onClick={()=>{setShowListingsId(scrape._id); setShowListings(scrape.error);}}>Open Error Listings</button>
            </div>
          }
            </div>
          )}
          {scrape.fail.length > 0 && (
            <div>
              {!notSetFiltered ? (
                <div>⛔ Fails: {scrape.fail.map((x) => (
                  <span key={x} className="inline-block mr-2">
                    <span to={x} className="text-red-500  underline">
                      {x}
                    </span>
                  </span>
                ))}</div>
              ) : (
                <p>{scrape.fail.length} new fail scrapes.</p>
              )}        
                </div>
          )}
          </div>

          {showListingsId === scrape._id && showListings && showListings.length > 0 &&   <div>
            <button onClick={()=>{setShowListingsId(); setShowListings();}}>Close Listings</button>
          <GetListings listings={showListings} hidefilters={true} />
          </div>
          }

      </div>
      ))}
       {displayCount < scrapes.length && (
        <button onClick={handleLoadMore} className="mt-4 text-blue-500 underline">
          Load More
        </button>
      )}
    </div>

  );
}

export default DisplayScrapes;
