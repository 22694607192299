import React, {useEffect} from 'react';
function About() {
  useEffect(() => {
    document.title = `About - Zeitgeists`;
  }, []);
  return (
    <div>
  </div>     

  );
}

export default About;



