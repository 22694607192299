import { Link } from "react-router-dom";
function HomeRegions({data}) {
return(
    <div className='flex flex-col   pb-10'>
                 <div className="p-4 text-2xl grid grid-cols-4 divide-y divide-white md:divide-black">

         <div className="text-2xl pb-6  col-span-4  text-center">See our regions:</div>

        {data.regions && data.regions.map(region=>
            <div className="lg:col-span-1  md:col-span-2 col-span-4 text-center pb-10"  key={region.uuid}>
            <Link to={`/region/${region.urlName}`}  >
                       <span className='m-2'>{region.name}</span>
                   </Link> 
            </div>

                       
            )}
        </div>

        </div>
)
}
export default HomeRegions;