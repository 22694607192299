import React, { useEffect, useState } from 'react';
import { useNavigate,Link,useParams } from 'react-router-dom';

import EditBar from '../../components/utilities/EditPlaceBar';
import PlaceDashboard from '../../components/place/PlaceDashboard';
//need to write a function to add regionUrlName when urername created, and routes for info by name
/*
if signed as a gate keeper in show my regions
links to edit -> go to region page and add new places or remove them
i think just have notification about
proably need to do some consensus for gatekeepers to add new places or remove places
not for now though

    {place&&editPlace&&owns&&userList &&isAuthenticated ? <EditPlacePeople place={place} userList={userList} /> : null}

*/
function EditPlace({setIsAuthenticated, isAuthenticated, role }) {
  const navigate = useNavigate();
    const [place,setPlace] = useState(false);

    const [suggestions,setSuggestions] = useState(false);


    //get place by string
    const [count,setCount] = useState(false);
    const [scrapes,setScrapes] = useState(false);

    useEffect(() => {
      // Fetch region details when the component mounts
      fetchPlaceDetails();
      //fetch user list in place detauks
    }, []);

    useEffect(() => {
      if(place) {
        document.title = `Edit ${place.name} - Zeitgeists`;
      } else {
        document.title = `Place - Zeitgeists`;
      }
    }, [place]);
    
      const { regionName,placeName } = useParams(); // Access the "urlName" route parameter


      const fetchPlaceDetails = async () => {
        try {
            const response = await fetch(`/api/region/${regionName}/place/${placeName}/edit`, {
                headers: {
                  'Content-Type': 'application/json', // Use a function to get the cookie value
                        },
                        credentials: 'include', // Include cookies
              });
               if (response.ok) {
                const data = await response.json();
                  setPlace(data.place);
                  if(data.count) {
                    setCount(data.count);
                  }
                  if(response.status === 201) {
                   setScrapes(data.scrapes);
                    setSuggestions(data.suggestions)
                  }
              }
             else if(response.status===401) {
                navigate(`/login/region/${regionName}/place/${placeName}/edit`)
              }
        } catch (error) {
          console.error('Network or other error:', error);
        }
      }
//display place people

   return (

    <div className='p-4' >      
      { place && <EditBar type="place" place={place} active={'Dashboard'} region={place.region} />}

      {place&& scrapes && <div><PlaceDashboard scrapes={scrapes} place={place}   count={count} suggestions={suggestions}/></div>}


  
 
    

    </div>


    
  );
}

export default EditPlace;
