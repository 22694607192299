import React from 'react';
import { Link } from 'react-router-dom';
import DisplaySuggestedAdds from './DisplaySuggestedAdds';
import HideRemoveListing from './HideRemoveListing';
import ReactMarkdown from 'react-markdown';
const moment = require('moment-timezone');



function ListingBox({ listing, suggestions,editing, editor,closeSuggestions,}) {
  function formatISOInTimezone(isoString, timezone) {
    // Format: 'MMM DD, h:mm A' e.g., 'Nov 17, 6:00 AM'
    return moment(isoString).tz(timezone).format('MMM DD, h:mm A');
  }
  function formatISOInLocal(isoString) {
    // Format: 'MMM DD, h:mm A' e.g., 'Nov 17, 6:00 AM'
    return moment(isoString).format('MMM DD, h:mm A');
  }
  
  return (
    <div key={listing.uuid} className="p-4 border border-black rounded shadow mb-4 text-center">
      <div className='flex justify-end'>
      {editing ? <Link to={`/listing/${listing.uuid}`} className=' bg-blue-500 hover:bg-blue-900 hover:text-white border rounded-full border-black p-1 z-20'>Close Editor</Link> : <Link to={`/listing/${listing.uuid}/edit`} className='justify-right bg-yellow-500 hover:bg-yellow-900 hover:text-white border rounded-full border-black p-1 z-20'>Edit Listing</Link>}

      </div>

<div className='mt-4'>
<Link to={`/listing/${listing.uuid}`} className="text-3xl font-bold hover:underline">

{listing.name ? listing.name : 'No Main Listing Title'}
</Link>
</div>

      <p> 
      <span className='inline-block mt-2' >
        <Link to={`/region/${listing.region.urlName}/place/${listing.place.urlName}`} className="mr-1 rounded-lg border border-1 border-black p-1 hover:text-white hover:bg-black ">
          {listing.place.name}
        </Link>
        </span>
      {listing.organizers && listing.organizers.length > 0 && (
        <>
          {listing.organizers.map((op) => (

<span className='inline-block mt-2' key={op.organizer.id}>
              <Link to={`/region/${listing.region.urlName}/organizer/${op.organizer.urlName}`} className="mr-1 rounded-full border border-1 border-black p-1 hover:text-white hover:bg-black ">
                {op.organizer.name}
              </Link>
              </span>
          ))}
        </>
      )}
            {listing.type &&       <span className='inline-block mt-2' >
<span className="mr-1 border border-1 border-black p-1 hover:text-white hover:bg-black ">{listing.type}</span></span>}

            </p>
            <p className="text-xl">
      {listing.startDate && listing.region && (
       <span>{formatISOInTimezone(listing.startDate,listing.region.timezone)}</span>
      ) }
                  {listing.endDate && listing.region && (
       <span>       {' → '}
       {formatISOInTimezone(listing.endDate,listing.region.timezone)}</span>
      ) }
</p>
      {listing.url && (
        <p>
          <a
            href={listing.url}
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-600 break-all"
          >
            {listing.url}
          </a>
        </p>
      )}
      {listing.room && (
        <p>
          <strong>Room:</strong> {listing.room}
        </p>
      )}
            {listing.note && (
        <p>
          Note / Subtitle: {listing.note}
        </p>
      )}
      {listing.QA  && (
        <p>Q&A: {listing.QA ? 'Yes' : 'No'}</p>
      )}
{listing.about && 
  <div>
      <ReactMarkdown>{listing.about}</ReactMarkdown>
      </div>
  }
      {listing.worksPresented && listing.worksPresented.length > 0 && (
  <div>
    
    {listing&&listing.type==='Screening' && listing.worksPresented.length < 6 && 
    <div  > 
        <div>

    </div>
    <div>
      { listing.worksPresented.length > 0  && (
        <strong>Work Presented ({listing.worksPresented.length}): </strong>
      )}
    </div>
    <div className='flex flex-wrap justify-center  gap-4 mx-auto'>
  {listing.worksPresented.length < 6 && listing.worksPresented.map((work) => (
    <div key={work.uuid} className="bg-white overflow-hidden mt-2 pt-2 border border-black w-full sm:w-auto">
      <div className="p-4">
        <h3 className="text-lg font-semibold mb-2">Title: {work.name}</h3>
        {work.year && (
          <p className="text-sm mb-1"><strong>Release Year:</strong> {work.year}</p>
        )}
        {work.duration && (
          <p className="text-sm mb-1"><strong>Duration:</strong> {work.duration.length > 400 ? 'Problem with duration' : work.duration} Mins</p>
        )}
        {work.director && (
          <p className="text-sm mb-1"><strong>Director:</strong> {work.director.length > 500 ? 'Problem with director' : work.director}</p>
        )}
        {work.videoFormat && (
          <p className="text-sm mb-1"><strong>Video Format:</strong> {work.videoFormat}</p>
        )}
        {work.TMDB && (
          <p className="text-sm mb-1"><a href={`https://letterboxd.com/tmdb/${work.TMDB}`} target='_blank' className='text-blue-500 underline'>Letterboxd Link</a></p>
        )}
      </div>
    </div>
  ))}
</div>
    
    
    </div>}
   
      


  </div>
)}
                      {listing && listing.edits && (
        <div className=" mt-2 pt-2">
          <DisplaySuggestedAdds listing={listing} data={listing.edits} closeSuggestions={editing || closeSuggestions} />
        </div>
      )}
              {listing && listing.lastEdit.lastDate && (
        <p className=" mt-2 pt-2">
          Last Updated: {formatISOInLocal(listing.lastEdit.lastDate)} by{' '}
          {  listing.lastEdit.lastUsername ?        <Link to={`/user/${listing.lastEdit.lastUsername}`} className="text-blue-600">
            @{listing.lastEdit.lastUsername}
          </Link> : listing.lastEdit.lastIntegrationName &&listing.lastEdit.lastIntegrationName}

        </p>
      )}
 {editor && <HideRemoveListing listing={listing} />}
    
      {/* Add more fields as needed */}
    </div>
  );
}

export default ListingBox;
