import React, {  useState } from 'react';

function ManageManagers({edit, type, userList, space}) {

  const [selectedIds, setSelectedIds] = useState(space.manages);
  const [inputValue, setInputValue] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  // Convert userList to an array if it's not already, and handle null/undefined cases
  const userListArray = Array.isArray(userList) ? userList : [];

  const handleRemove = (index) => {
    const updatedIds = selectedIds.filter((_, i) => i !== index);
    setSelectedIds(updatedIds);
    setErrorMessage('Press save to update managers')

  };

  const onChange = (event) => {
    setErrorMessage('');
    setInputValue(event.target.value);
  };

  const handleAdd = () => {
    const selectedUser = userListArray.find((user) => user.username === inputValue);
    setErrorMessage('Press save to update managers')

    if (selectedUser && !selectedIds.includes(selectedUser.value)) {
      setSelectedIds([...selectedIds, selectedUser.value]);
      setInputValue('');
    }



  };

  const filteredUserList = userListArray.filter((user) => !selectedIds.includes(user.value));

  const handleSubmit = async (e) => {
    if (window.confirm(`Confirm ${type} Manager List`)!==true) {
        return
    }
    try {

          const  userIds  = selectedIds;

      // Make an API request to update user profile using the fetch API
       const response = await fetch(`/api/${type}/${space.id}/managers`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            userIds,
        }),
      });

      if (response.status === 201) {
        // User profile updated successfully
        // You can redirect to another page or display a success message here
       // console.log('User profile updated:', response);
       setErrorMessage(type+ ' managers updated!')
      } else if (response.status===401) {
      }
      else {
        
      }

    } catch (error) {
      // Handle any errors that may occur during region creation
      console.error('Error adding region:', error);
    }
  };

  return (
    <div className="m-5">
      <h3 className="text-lg font-medium text-gray-900 ">Current Selected Managers:</h3>
      <div className="mb-4">
        {selectedIds.map((selectedId, index) => {
          const user = userListArray.find((user) => user.value === selectedId);
          return (
            user && (
              <div key={user.value} className="flex items-center mb-2">
                <span className=" font-medium text-blue-700  hover:underline pr-3"><a rel="noreferrer"  target="_blank" href={`/user/${user.username}`}>@{user.username}</a></span>
                <button
                  onClick={() => handleRemove(index)}
                  className=" hover:shadow font-xl text-black bg-red-300  rounded-full p-2 text-xs hover:bg-red-500"
                >
                  REMOVE
                </button>
              </div>
            )
          );
        })}
      </div>
      <div className="mb-4">
        <p className="font-medium text-gray-900 ">Add New:</p>
        <input
          type="search"
          list="list"
          autoComplete="on"
          value={inputValue}
          onChange={onChange}
          className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  mb-2"
        />
        <datalist id="list">
          {filteredUserList.map((user) => (
            <option key={user.value} value={user.username}>
              {user.username}
            </option>
          ))}
        </datalist>
        <button
          onClick={handleAdd}
          className="bg-blue-200 hover:bg-blue-500  font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        >
          Add
        </button>
      </div>
      <div className="mb-4">
        {errorMessage && <b className="text-red-600">{errorMessage}</b>}
        <button
          onClick={handleSubmit}
          className="bg-green-200 hover:bg-green-500  font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
        >
          Save Managers
        </button>
      </div>
    </div>
    
  );
}

export default ManageManagers;
