import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
function AddGatekeepers({ region,setIsAuthenticated,role }) {
  const [userListArray, setUserList] = useState(false)
  useEffect(() => {
    // Fetch region details when the component mounts
    users()
  }, []);
  function SuccessMessage({ message }) {
    return (
      <div className="border text-xl border-green-400  bg-green-100 px-4 py-3 text-green-700">
        {message}
      </div>
    );
  }
  function FailMessage({message}) {
    return (
      <div className="border text-xl border-red-400  bg-red-100 px-4 py-3 text-red-700">
        {message}
      </div>
    );
  }
  function PendingMessage({message}) {
    return (
      <div className="border text-xl border-orange-400  bg-orange-100 px-4 py-3 text-orange-700">
        {message}
      </div>
    );
  }
  const users = async () => {
    try {
  const response = await fetch('/api/users', {
    headers: {
      'Content-Type': 'application/json', // Use a function to get the cookie value
            },
            credentials: 'include', // Include cookies
  });
  if (response.ok) {
    const data = await response.json();
    setUserList(data);
  }
    }
    catch (error) {

    }
} 

  const [selectedIds, setSelectedIds] = useState(region.council || []);
  const [inputValue, setInputValue] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  // Convert userList to an array if it's not already, and handle null/undefined cases

  const handleRemove = (index) => {
    if(role!=='super'&&role!=='superadmin'){
      return
    }
    const updatedIds = selectedIds.filter((_, i) => i !== index);
    setSelectedIds(updatedIds);
    setErrorMessage(<PendingMessage message='Add, then press save to update Council' />)

  };

  const onChange = (event) => {
    setErrorMessage('');
    setInputValue(event.target.value);
  };

  const handleAdd = () => {
    const selectedUser = userListArray.find((user) => user.username === inputValue);
    setErrorMessage(<PendingMessage message='Add, then press save to update Council' />)

    if (selectedUser && !selectedIds.includes(selectedUser.value)) {
      setSelectedIds([...selectedIds, selectedUser.value]);
      setInputValue('');
    }
  };
  const filteredUserList = userListArray && userListArray.filter((user) => !selectedIds.includes(user.value));

  const handleSubmit = async (e) => {
    if (window.confirm("Confirm Council List")!==true) {
        return
    }
    try {

          const  userIds  = selectedIds;
       const response = await fetch(`/api/region/${region.id}/council/`, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            userIds,
        }),
      });

      if (response.status === 200) {
        // User profile updated successfully
        // You can redirect to another page or display a success message here
       // console.log('User profile updated:', response);
       setErrorMessage(<SuccessMessage message='Council updated!' />)

      } else if (response.status===401) {
        setIsAuthenticated(false);
        setErrorMessage(<FailMessage message='Error.' />)

      }
      else {
        setErrorMessage(<FailMessage message='Error.' />)

      }
    
    } catch (error) {
      console.error('Error adding region:', error);
      setErrorMessage(<FailMessage message='Error.' />)
    }
  };

  return (
   <div className='m-2'>{userListArray && filteredUserList && 
  
  <div key='1'>
      <h3 className="text-lg font-medium text-gray-900 ">Current Council:</h3>
    <div className='mb-4'>
      {selectedIds.map((selectedId, index) => {
      const user = userListArray.find((user) => user.value === selectedId);
      return (
        user && (
          <div key={user.value} className="flex items-center mb-2">
            <span className=" font-medium text-blue-700  hover:underline pr-3"><a rel="noreferrer" target="_blank" href={`/user/${user.username}`}>@{user.username}</a></span>
            {role !== 'gatekeeper' && <button
              onClick={() => handleRemove(index)}
              className=" hover:shadow font-xl text-white bg-red-600  rounded-full border-red-600 p-1 text-xs border-2"
            >
              REMOVE
            </button>}
          </div>
        )
      );
    })}
  </div>
  <div className='mb-4'>
    <p className="font-medium text-gray-900 ">Add New:</p>
    <input
      type="search"
      list="list"
      autoComplete="on"
      value={inputValue}
      onChange={onChange}
      className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  mb-2 "
    />
    <datalist id="list">
      {filteredUserList.map((user) => (
        <option key={user.value} value={user.username}>
          {user.username}
        </option>
      ))}
    </datalist>
    <button
      onClick={handleAdd}
      className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
    >
      Add
    </button>
    </div>
    <div className="mb-4">

      
      <button
        onClick={handleSubmit}
        className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
      >
        Save Council
      </button>
      <div className='pt-2'>
      {errorMessage}

        </div>

    </div>

    </div>
 } </div>
  );
}

export default AddGatekeepers;
