import React, { useState,useEffect } from 'react';
//add confirm and fix external links
function AddRegion({onChange, isAuthenticated,setIsAuthenticated}) {
    const [errorMessage,setErrorMessage] = useState('');
    useEffect(() => {
      if(isAuthenticated) {
        setErrorMessage('');
      } 
      }, [isAuthenticated]);

    const [regionData, setRegionData] = useState({
        name: '',
        description: '',
      });
    
      const handleChange = (e) => {
        const { name, value } = e.target;
        setRegionData({
          ...regionData,
          [name]: value,
        });
        setErrorMessage('')
      };
      const handleSubmit = async (e) => {
        e.preventDefault();
        if (window.confirm("Confirm Add Region")!==true) {
            return
        }
        try {

              const { name, city, state, country, description,  } = regionData;

          // Make an API request to update user profile using the fetch API
           const response = await fetch('/api/region/', {
            method: 'POST',
            headers: {
              'Content-Type': 'application/json',
              credentials: 'include', // Include cookies

            },
            body: JSON.stringify({
              name,
              description,
            }),
          });
    
          if (response.status === 201) {
            // User profile updated successfully
            // You can redirect to another page or display a success message here
           // console.log('User profile updated:', response);
           setErrorMessage('Region added!')
           onChange();
          } else if (response.status===401) {
            setIsAuthenticated(false);
            localStorage.removeItem('isAuthenticated');
            localStorage.removeItem('role');
          }
          else {

          } 
          // Call the addRegion function with regionData
        //  await addRegion(regionData);
          // Clear the form or perform other actions as needed
          setRegionData({
            name: '',
            alternateName: '',
            description: '',
          });
        } catch (error) {
          // Handle any errors that may occur during region creation
          setErrorMessage(error);
          console.error('Error adding region:', error);
        }
      };

  return (
    <div className="m-4">
      <h3 className="text-lg pb-4">Add New Region:</h3>
    <form onSubmit={handleSubmit}  className="flex flex-col space-y-4">
      <div >
        <label className="block mb-2 text-sm font-medium text-gray-900 ">Name:</label>
        <input
        className="bg-gray-50 border border-gray-300 text-gray-900 text-lg rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
          type="text"
          name="name"
          value={regionData.name}
          onChange={handleChange}
        />
      </div>
      
      {/* Add input fields for other region properties */}
      <div className="mb-6">
        <label className="block mb-2 text-sm font-medium text-gray-900 ">Description:</label>
        <textarea
        className="bg-gray-50 border border-gray-300 text-gray-900 text-lg rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
          name="description"
          value={regionData.description}
          onChange={handleChange}
        />
      </div>
      <div>
        <button className='bg-gray-50 hover:bg-black text-black font-semibold hover:text-white py-2 px-4 border border-black hover:border-transparent rounded' type="submit">Add Region</button>
      </div>
      {errorMessage}
    </form>
    </div>
  );
}

export default AddRegion;
