import React from 'react';
import { Link } from 'react-router-dom';

const OrganizersCTA = ({urlName, organizer}) => {
  return (
    <div className="p-6 bg-white shadow-md rounded border-black border mt-2">
      <h2 className="text-2xl font-semibold mb-4">Join Our Open Cultural Database</h2>
      <p className="mb-4">
        As an open platform, Zeitgeists.org offers organizers a free space to claim, manage, and showcase their events. Collaborate in our shared mission to make cultural data accessible to all.
      </p>
      <div className="flex flex-col sm:flex-row justify-center gap-4">
       {organizer.owns.length < 1 ? <a href={`mailto:claim@zeitgeists.org?subject=Claim: ${organizer.name}`} className="px-4 py-2 bg-green-200 text-black text-sm font-medium rounded hover:bg-green-500">Claim Your Page</a> : <span className="px-4 py-2 bg-green-200 text-black text-sm font-medium rounded ">Organizer Claimed</span>}
        <Link to={`/organizer/${urlName}/edit`} className="px-4 py-2 bg-orange-200 text-black text-sm font-medium rounded hover:bg-orange-500">Contribute Data</Link>
      </div>
    </div>
  );
};

export default OrganizersCTA