import  React, { useEffect, useState } from 'react';
import LoginForm from '../../components/user/LoginForm';
import { useParams } from 'react-router-dom';
function LoginPage({ setRole, setIsAuthenticated }) {
  const { '*': wildcardParam } = useParams();
  const [nextPage, setNextPage] = useState(false);

  useEffect(() => {
    if (wildcardParam) {
      setNextPage(wildcardParam);
    }
  }, [wildcardParam]);
  useEffect(() => {
    document.title = `Login - Zeitgeists`;

}, []);

  return (

    <div >

          {nextPage ? <LoginForm setRole={setRole} setIsAuthenticated={setIsAuthenticated} nextPage={nextPage} /> : <LoginForm setRole={setRole} setIsAuthenticated={setIsAuthenticated} />}

    </div>

  );
}

export default LoginPage;


