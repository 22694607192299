import React, { useEffect, useState} from 'react';
import { useNavigate,Link,useLocation,useParams } from 'react-router-dom';

import EditPlaceBar from '../../components/utilities/EditPlaceBar';
import DisplayListing from '../../components/listing/DisplayListing';
import ManageSuggestion from '../../components/listing/ManageSuggestion';
//need to write a function to add regionUrlName when urername created, and routes for info by name
/*
if signed as a gate keeper in show my regions
links to edit -> go to region page and add new places or remove them
i think just have notification about
proably need to do some consensus for gatekeepers to add new places or remove places
not for now though

    {place&&editPlace&&owns&&userList &&isAuthenticated ? <EditPlacePeople place={place} userList={userList} /> : null}

*/
function EditPlaceSuggestions({setIsAuthenticated, isAuthenticated, role }) {
  const formatDateTime = (dateTime) => {
    const options = {
      year: 'numeric',
      month: 'numeric',
      day: 'numeric',
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
    };
    return new Date(dateTime).toLocaleString('en-US', options);
  };
  const navigate = useNavigate();

   //filter field for refetching after the first
   const location = useLocation();
   const searchParams = new URLSearchParams(location.search);
   const selectedId = searchParams.get('id');
   const [preSelectedId,setPreSelectedId] = useState(selectedId || false);
   const [edit,setEdit] = useState(false);

    const [place,setPlace] = useState(false);
    const [organizers,setOrganizers] = useState(false);

    const [suggestions,setSuggestions] = useState(false);

    const { regionName,placeName } = useParams(); // Access the "urlName" route parameter

    //get place by string

    useEffect(() => {
      // Fetch region details when the component mounts
      if(!isAuthenticated) {
        navigate(`/login/region/${regionName}/place/${placeName}/edit/suggestions`)
      }
      fetchPlaceDetails();
      //fetch user list in place detauks
    }, []);

    useEffect(() => {
      if(place) {
        document.title = `Edit ${place.name} - Zeitgeists`;
      } else {
        document.title = `Place - Zeitgeists`;
      }
    }, [place]);
    

      const fetchPlaceDetails = async () => {
        try {
            const response = await fetch(`/api/region/${regionName}/place/${placeName}/edit/suggestions`, {
                headers: {
                  'Content-Type': 'application/json', // Use a function to get the cookie value
                        },
                        credentials: 'include', // Include cookies
              });
               if (response.ok) {
                const data = await response.json();
                console.log(data)
                  setPlace(data.place);
                  setOrganizers(data.organizers);
                  setEdit(data.edit);
                  if(response.status ===201) {
                    setSuggestions(data.suggestions)
                  }
              }
              if(response.status===401) {
                setIsAuthenticated(false);
                navigate(`/login/region/${regionName}/place/${placeName}/edit/suggestions`)
              }
        } catch (error) {

        }
      }
      const hasUnapproved = suggestions && suggestions.some(s => s.status === 'unapproved');
      const [showUnapproved, setShowUnapproved] = useState(true);
      const [showApproved, setShowApproved] = useState(!hasUnapproved);
      const [showRejected, setShowRejected] = useState(false);
      const [showExpired, setShowExpired] = useState(false);
      const [sortKey, setSortKey] = useState('createdAt'); // default sort by date added

const ListingSuggestions = ({ suggestions, preSelectedId }) => {
 useEffect(()=>{

 },[suggestions])

  const toggleFilter = (status) => {
    switch (status) {
      case 'unapproved':
        setShowUnapproved(!showUnapproved);
        break;
      case 'approved':
        setShowApproved(!showApproved);
        break;
      case 'rejected':
        setShowRejected(!showRejected);
        break;
      case 'expired':
        setShowExpired(!showExpired);
        break;
      default:
        break;
    }
  };

  // Filtering based on toggle states
  let filteredAndSortedSuggestions = suggestions
    .filter(suggestion => {
      switch (suggestion.status) {
        case 'unapproved':
          return showUnapproved;
        case 'approved':
          return showApproved;
        case 'rejected':
          return showRejected;
        case 'expired':
          return showExpired;
        default: // This will include 'unapproved' or any other status not explicitly handled
          return true;
      }
    });

  // Sorting the filtered suggestions
  filteredAndSortedSuggestions.sort((a, b) => {
    if (sortKey === 'startDate') {
      return new Date(a.suggestedListing.startDate) - new Date(b.suggestedListing.startDate);
    }
    return new Date(a[sortKey]) - new Date(b[sortKey]);
  });

  return (
    <div className=''>
      <div className='flex flex-wrap justify-center'>
        <button onClick={() => setSortKey('createdAt')} className='inline-flex items-center justify-center gap-2 m-3 bg-blue-100 rounded-lg shadow-md p-3'>Sort by Date Added</button>
        <button onClick={() => setSortKey('startDate')} className='inline-flex items-center justify-center gap-2 m-3 bg-blue-100 rounded-lg shadow-md p-3'>Sort by Listing Start Date</button>
      </div>
      <div className="flex flex-wrap justify-center gap-2 m-2">
  <button
    className={`inline-flex items-center justify-center gap-2 m-3 ${showUnapproved ? 'bg-purple-300' : 'bg-purple-100'} rounded-lg shadow-md p-3`}
    onClick={() => toggleFilter('unapproved')}
  >
    <span className="px-3 py-1 text-white bg-purple-500 rounded-full">
      {suggestions.filter(suggestion => suggestion.status === 'unapproved').length}
    </span>
    {showUnapproved ? 'Hide' : 'Show'} Unapproved
  </button>

  <button
    className={`inline-flex items-center justify-center gap-2 m-3 ${showApproved ? 'bg-green-300' : 'bg-green-100'} rounded-lg shadow-md p-3`}
    onClick={() => toggleFilter('approved')}
  >
    <span className="px-3 py-1 text-white bg-green-500 rounded-full">
      {suggestions.filter(suggestion => suggestion.status === 'approved').length}
    </span>
    {showApproved ? 'Hide' : 'Show'} Approved
  </button>

  <button
    className={`inline-flex items-center justify-center gap-2 m-3 ${showRejected ? 'bg-red-300' : 'bg-red-100'} rounded-lg shadow-md p-3`}
    onClick={() => toggleFilter('rejected')}
  >
    <span className="px-3 py-1 text-white bg-red-500 rounded-full">
      {suggestions.filter(suggestion => suggestion.status === 'rejected').length}
    </span>
    {showRejected ? 'Hide' : 'Show'} Rejected
  </button>

  <button
    className={`inline-flex items-center justify-center gap-2 m-3 ${showExpired ? 'bg-yellow-300' : 'bg-yellow-100'} rounded-lg shadow-md p-3`}
    onClick={() => toggleFilter('expired')}
  >
    <span className="px-3 py-1 text-white bg-yellow-500 rounded-full">
      {suggestions.filter(suggestion => suggestion.status === 'expired').length}
    </span>
    {showExpired ? 'Hide' : 'Show'} Expired
  </button>
</div>



      {filteredAndSortedSuggestions.map(suggestion => (
        <div key={suggestion.uuid} className={`col-span-4 sm:col-span-2  md:col-span-1 m-3 ${
          suggestion.status === 'approved' ? 'bg-green-300' :
          suggestion.status === 'rejected' ? 'bg-red-300' :
          suggestion.status === 'expired' ? 'bg-yellow-300' : 'bg-purple-300'
        } text-center p-6 rounded-lg flex flex-col justify-center items-center shadow-sm`}>
          <p>
            <button className="text-blue-500 hover:underline" onClick={() => setPreSelectedId(suggestion.uuid)}>
              Open Listing Add Suggestion
            </button>
          </p>
          Status: <span className="capitalize">{suggestion.status}</span>
          {suggestion.createdAt && (
            <div>
              Submitted At: {formatDateTime(suggestion.createdAt)} by{' '}
              <Link to={`/user/${suggestion.user.username}`} className="text-blue-600">
                @{suggestion.user.username}
              </Link>
            </div>
          )}
          {suggestion.comment && <p>Comment: <span className="bg-green-200">{suggestion.comment}</span></p>}
          {suggestion.suggestedListing.name && <p>Title: {suggestion.suggestedListing.name}</p>}
          {suggestion.suggestedListing.url && <p>External URL: <a className="underline " href={suggestion.suggestedListing.url}>{suggestion.suggestedListing.url}</a></p>}
        </div>
      ))}
    </div>
  );
};

   return (
    <div className="p-4 ">
      
 { place && <EditPlaceBar type="place" place={place} region={place.region} active="New Listing Suggestions"/>}
    <div className="content flex-grow">
        {suggestions&& suggestions.filter(suggestion=>suggestion.uuid===preSelectedId).map(suggestion=> {
          suggestion.suggestedListing.place = place;

          return (
          <div key={preSelectedId}>
            <DisplayListing listing={suggestion.suggestedListing} place={place} region={place.region} suggestion={suggestion}  />
            <div className='flex flex-wrap justify-end'>
           {edit && (suggestion.status !== 'approved' &&  <ManageSuggestion suggestion={suggestion} onChange={fetchPlaceDetails}/> )} 
           {suggestion.status === 'approved' && suggestion.type === 'add' && <p className='text-xl'>Status: <span className='text-green-500'>Approved</span>. See live listing <Link className="hover:underline text-blue-500" to={`/listing/${suggestion.listing.uuid}`}>Here</Link></p>}
           </div>
          </div>
        )})}
      {
    suggestions && <ListingSuggestions suggestions={suggestions} preSelectedId={preSelectedId} />
      }
    </div>
       </div>


    
  );
}

export default EditPlaceSuggestions;
