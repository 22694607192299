import React, { useState,useEffect } from 'react';
import { Link } from 'react-router-dom';

//add confirm and fix external links
function AdminUsers({users, regions}) { 
    const [showUsers, setShowUsers] = useState(false);
    const displayRegion = (user) => {
        const region = regions.filter(region=>region.id===user.region||(user.region.id&&user.region.id==region.id));
        if(!region.length>0) {
          return <span>region not found at {user.region}</span>
        }
        return <a className='underline' href={`/region/${region[0].urlName}`}>{region[0].name}</a>
      
       }
    const handleDeteteUser = async (user) => {
        if (window.confirm(`Confirm Delete User @${user.username} with ${user.manages && user.manages.length} managed and ${user.owns && user.owns.length} owned.`)!==true) {
          return
      } else {
        if (window.confirm(`Again Confirm Delete User @${user.username}`)!==true) {
          return
      } else {
        try  {
          const response = await fetch(`/api/user/${user.id}`, {
          method: 'DELETE',
          credentials: 'include', // Include cookies
          headers: {
            'Content-Type': 'application/json',
          }});
          const data =await response.json();
          if(response.ok) {
            alert('User Deleted, refresh page')
          } else {
            alert('Error')
            console.log(data);
          }
       
      } catch (error) {
      
        console.error(error);
        alert('Error')
      }
      }
      }
        console.log(user)
       }
       const handleResetPassword = async (user) => {
        if (window.confirm(`Confirm reset password of User @${user.username}.`)!==true) {
          return
      } else {
        try{
          const response = await fetch(`/api/newpassword/${user.id}`, {
            method: 'POST',
            credentials: 'include', // Include cookies
            headers: {
              'Content-Type': 'application/json',
            }});
            if(response.ok) {
              alert('Password Reset')
            } else {
              alert('Error')
      
            }
         
        } catch (error) {
          console.error(error);
          alert('Error')
        }
      }
       }
      const handleReconfirmEmail = async (user) => {
        if (user.isConfirmed) {
          return
      } else {
        try{
          const response = await fetch(`/api/reconfirm/${user.id}`, {
            method: 'POST',
            credentials: 'include', // Include cookies
            headers: {
              'Content-Type': 'application/json',
            }});
            if(response.ok) {
              alert('Email Sent')
            } else {
              alert('Error')
      
            }
         
        } catch (error) {
          console.error(error);
          alert('Error')
        }
        
      }
      }
      return (
<div>
<button onClick={()=>setShowUsers(!showUsers)} className='underline text-blue-600'> {showUsers ? 'Close Users' : 'Show Users'}</button>
{users && showUsers && <div >

<p className='mt-5'>Users:</p>                 
<div className='grid md:grid-cols-3'>
{users.map(user=> 
<div  key={user.id} className=' border-2 border-gray-400 p-2 m-2'>
    <p className='text-xl underline'><Link to={`/user/${user.username}`}>@{user.username}</Link></p>
    {user.name && <p>Name: {user.name}</p>}

    {user.region && <p>Gatekeeper of {displayRegion(user)}</p>}
    {!user.region && <p>Role: {user.role}</p>}
    {user.email && <p className={user.isConfirmed? 'text-green-500' : 'text-red-500'} >Email: {user.email}</p>}
    {user.owns && <p>Owns: {user.owns.length}</p>}
    {user.manages && <p>Manages: {user.manages.length}</p>}
    {user.numberOfEdits && <p>Edits: {user.numberOfEdits}</p>}
    {user.apiKey && <p>API Key: {user.apiKey ? 'Yes' : 'No'}</p>}


  <div className='flex text-center'>
  <button className=" bg-green-200 p-1 m-1 shadow-md rounded-full  hover:bg-green-600" onClick={()=> handleReconfirmEmail(user)}>Send Confirmation Email</button>

<button className=" p-1 m-1 shadow-md rounded-full bg-yellow-200 hover:bg-yellow-600" onClick={()=>  handleResetPassword(user)}>Reset Password</button>
 {user.role!=='superadmin' && <button className=" p-1 m-1 shadow-md rounded-full  bg-red-200 hover:bg-red-600" onClick={()=> handleDeteteUser(user)}>Delete User</button>}

    </div>
</div>)}
</div>
</div>}


</div>
  
)}


export default AdminUsers;
