import React from 'react';
import {  Link } from 'react-router-dom';
import ProfileImage from '../user/ProfileImage';
import DisplayUsers from '../utilities/DisplayUsers';
import OrganizersCTA from './OrganizersCTA';
function OrganizerInfo({ places, organizer, light, deleteOrganizer, handleDelete, listing,home,list,border,region}) {
  return (
      <div className={light||border ? 'm-1 text-center border-2 rounded-lg p-2 border-black' : 'm-3 text-center'}>
      {organizer && (
        <div className='m-1' >
           
         {!home&&<div className="text-right mb-2">
          {deleteOrganizer&&<button onClick={()=>handleDelete(organizer.id)} className="inline-block m-2 bg-red-500 hover:bg-red-900 hover:text-white  border rounded-full border-black p-1 ">
              Delete organizer
            </button>}
            <Link to={`/region/${region ? region.urlName : organizer.region.urlName}/organizer/${organizer.urlName}/edit`} className="inline-block mt-2 bg-yellow-500 hover:bg-yellow-900 hover:text-white  border rounded-full border-black p-1 ">
              Edit organizer
            </Link>
            
            </div>}
                <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}>
                  {organizer.image && <ProfileImage url={organizer.image} alt={organizer.alt} />}
                </div>   
          <h2 className="text-2xl font-semibold mt-4 "><Link to={`/region/${region ? region.urlName : organizer.region.urlName}/organizer/${organizer.urlName}`} className="hover:underline">{organizer.name}</Link></h2>
          {!listing && places && organizer.places && places.length > 0 && (
        <div className="">
          {organizer.places.map((placeId) => {
            const place = places.find(place=>place.id===placeId);
            if(place){
              return         <span className='inline-block mt-2 ' key={place.id}>
              <Link key={place.id} to={`/region/${region ? region.urlName : organizer.region.urlName}/place/${place.urlName}`} className="mr-1 rounded-lg border border-1 border-black p-1 hover:text-white hover:bg-black ">
              {place.name}
            </Link>
            </span>
            }
          }
          )}
        </div>
      )}

          {organizer.url && !light &&(
            <a
              href={organizer.url}
              target="_blank"
              rel="noopener noreferrer"
              className="text-blue-500 hover:underline truncate break-all"
            >
{organizer.url}            </a>
          )}
                    {!listing  && !light &&  
            <p className="mt-2">
              Region:{' '}
              <Link className='text-blue-500 hover:underline' to={`/region/${region ? region.urlName : organizer.region.urlName}`}>
                {region ? region.name : organizer.region.name}
              </Link>
            </p>
          }
                   
          {!listing && organizer.owns && !light && organizer.owns.length > 0 && (
            <p className="mt-2">
              Owners:
              <DisplayUsers ids={organizer.owns}/>
            </p>
          )}
          {!listing && organizer.manages && !light && organizer.manages.length > 0 && (
            <p className="mt-2">
              Managers:
              <DisplayUsers ids={organizer.manages}/>
            </p>
          )}
          {organizer.description && <p className="mt-2">{organizer.description.length>300&& border ? organizer.description.substring(0,300) + '...'   : organizer.description}</p>}

          {!listing && !border && !light && organizer.email && <p className="mt-2">Email: {organizer.email}</p>}


          {!listing && organizer.sameAs && !light && organizer.sameAs.length > 0 && organizer.sameAs[0].url && (
            <div className="mt-2">
              <p>External Links:</p>
              <ul>
                {organizer.sameAs.map((x, index) => (
                  <li key={index}>
                    <a href={x.url} target="_blank" rel="noopener noreferrer">
                      {x.label}
                    </a>
                  </li>
                ))}
              </ul>
            </div>
          )}
                  {!deleteOrganizer&& !listing && !light &&  !home && !list && organizer && <OrganizersCTA urlName={organizer.urlName} organizer={organizer} />}

        </div>
      )}
      </div>
  

  );
}

export default OrganizerInfo;
