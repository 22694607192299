import { useEffect,useState } from "react";
import { Link } from "react-router-dom";
const EditPlaceBar = ({ active, onMenuItemClick, type,place,region}) => {
const [info, setInfo] = useState(false)

    const sidebar = async () => {
        const response = await fetch(`/api/sidebar/type/${type}/id/${place.id}`);
        if(response.ok) {
            const data = await response.json();
            setInfo(data.toReturn);
        }
    }
    useEffect(()=> {
        sidebar();
    }, []);
    const style = (url) => {
        if(active!==url) {
          return 'p-2 rounded-lg border-black border-2 hover:bg-black hover:text-white hover:scale-105 transition-transform'
        } else {
          return 'p-2 rounded-lg border-black border-2 bg-black text-white hover:scale-105 transition-transform'
        }
    }
    return (
      <div className="text-center mt-16 ">
      {place && region && <p className="center font-bold text-2xl m-4 underline"><Link to={`/region/${region.urlName}/place/${place.urlName}/`}> {place.name}</Link></p>}
<div className="border-black border p-4">
        {info && info.map((x, index)=> (
            <span key={index} className="inline-block m-3">
            <Link className={style(x.name)} to={x.url} >{x.name}</Link>
            </span>
        ))}
</div>

      </div>
    );
  };
  
  export default EditPlaceBar;