import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';

import 'react-datepicker/dist/react-datepicker.css';

function HideRemoveListing({ listing }) {
  const navigate = useNavigate();

  const [status,setStatus] = useState(listing.status || '');
  const [updateMessage, setUpdateMessage] = useState(''); // State for update message
  const [deleted, setDeleted] = useState(false); // State for update message

 const deleteListing = async (e) => {
  if (window.confirm("Confirm Delete listing. Deleted Listings won't be re-fetched from the scraper.")!==true) {
    return;
}
  try {    
    const response = await fetch(`/api/listing/${listing.uuid}`, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include', // Include cookies
    });
    if (response.ok) {
      // Success: Listing updated successfully
      // You can display a success message here if needed
     // const data = await response.json()
      console.log("Listing deleted successfully");
      setDeleted(true);
      // You can close the form here by calling a callback function if needed
  } else {
      // Error: An error occurred while updating the listing
      const errorData = await response.json();
      console.error(errorData.message); // Log the error message for debugging
  }

  } catch (error) {
    // Handle fetch error
    setUpdateMessage('An error occurred while deleting the listing.');
    console.error(error);
  }
 }

 const changeStatus = async (e) => {
  setUpdateMessage('Loading.')
  try {
    console.log(e)
    const response = await fetch(`/api/listing/status/${listing.uuid}`, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      credentials: 'include', // Include cookies
      body: JSON.stringify({'status': e}),
    });
    if (response.ok) {
      // Success: Listing updated successfully
      // You can display a success message here if needed
    //  const data = await response.json()
      setUpdateMessage("Status Updated.");
      // You can close the form here by calling a callback function if needed
  } else {
      // Error: An error occurred while updating the listing
      const errorData = await response.json();
      console.error(errorData.message); // Log the error message for debugging
      setUpdateMessage("Error updating.");

  }

  } catch (error) {
    // Handle fetch error
    setUpdateMessage("Error updating.");
    console.error(error);
  }
  setTimeout(() => {
    setUpdateMessage('')
  }, 5000); 
 }
  


 const handleStatus = (e) => {
  const newStatus = e.target.value;
  setStatus(newStatus);
  setUpdateMessage('Loading.')
  setTimeout(() => {
    changeStatus(newStatus); 
  }, 1000); 
 }
  return (<> {deleted ? <div className='text-xl text-red-600'>Listing deleted.</div> :
    <div className="text-right">



<form onSubmit={changeStatus} >
  <div className='mt-4 text-center'>
    {[{name:'live',color: 'green'},{name:'editing',color: 'yellow'},{name:'hiding',color: 'red'}].map((item,i) => (
      <label key={item.name} className={`px-4 py-1  cursor-pointer border-t border-b ${i==0 ? 'border-l rounded-l' : ''}  ${i==2 ? 'border-r rounded-r' : ''} border-black ${status === item.name ? `bg-${item.color}-500` : `  text-${item.color}-500`} `}>
        <input
          type="radio"
          name="status"
          value={item.name}
          checked={status === item.name}
          onChange={handleStatus}
          className="hidden"
        />
        {item.name.charAt(0).toUpperCase() + item.name.slice(1)}
      </label>
    ))}
    <br/>
    <span>{updateMessage}</span>
  </div>
</form>


<p><button type="button" onClick={deleteListing}className="inline-block m-2 bg-red-500 hover:bg-red-900 hover:text-white  border rounded-full border-black p-1 ">
Delete Listing              </button></p>
    </div>
 } </>);
}

export default HideRemoveListing;
