import React, { useState,useEffect } from 'react';
function ScreeningForm({ setMovieData, movieData,listing}) {
  const [formData, setFormData] = useState({
    workPresented: [{ name: '', duration: '', director: '', year: '', videoFormat: '' , description: '', inLanguage: '', countryOfOrigin: '', productionCompany: '', TMDB: '' , about: '', abstract: '', genre: ''}],
})


    
//map in movie data here

const [currentMovieIndex, setCurrentMovieIndex] = useState(null);
const openMovieDetails = (index) => {
  setCurrentMovieIndex(index);
};

const closeMovieDetails = () => {
  setCurrentMovieIndex(null);
}; 
const handleChange = (e) => {
  const { name, value } = e.target;


    const workPresented = [...formData.workPresented];
    const [nul,movieIndex, fieldName] = name.split('.');   
      
    if (fieldName === 'videoFormat') {
      if (value === 'custom') {
        // Handle 'custom' format
        workPresented[movieIndex]['videoFormat'] = value;
        workPresented[movieIndex]['customFormat'] = '';
      } else {
        // Handle other formats
        workPresented[movieIndex][fieldName] = value;
        // You may want to reset 'customFormat' here if necessary.
      }
    } else {
      workPresented[movieIndex][fieldName] = value;
    }
    setFormData({ ...formData, workPresented });
  
};

const addMovieField = () => {
  const newMovie = {
    name: '',
    duration: '',
    director: '',
    year: '',
    videoFormat: '',
    customFormat: '',
    description: '',
    inLanguage: '',
    countryOfOrigin: '',
    productionCompany: '',
    TMDB: '',
    genre: '',
  };
  setFormData({
    ...formData,
    workPresented: [...formData.workPresented, newMovie] ,
  });
};

const removeMovie = (indexToRemove) => {
  if (indexToRemove >= 0 && indexToRemove < formData.workPresented.length) {
    const workPresented = [...formData.workPresented];
    workPresented.splice(indexToRemove, 1);
    setFormData({ ...formData, workPresented });
  }
};
useEffect(() => {
  if(movieData) {
    setFormData({
       workPresented: movieData.workPresented ? movieData.workPresented : [{ name: '', duration: '', director: '', year: '', videoFormat: '' , description: '', inLanguage: '', countryOfOrigin: '', productionCompany: '', TMDB: '' , about: '', abstract: '', genre: ''}],
   })}
}, []);

useEffect(() => {

  setMovieData(formData);
}, [formData]);

useEffect(()=>{
  if(listing) {
    setFormData({
       workPresented: listing.worksPresented ? listing.worksPresented : [{ name: '', duration: '', director: '', year: '', videoFormat: '' , description: '', inLanguage: '', countryOfOrigin: '', productionCompany: '', TMDB: '' , about: '', abstract: '', genre: ''}],
   })}

}, [listing])
useEffect(()=>{
  if(movieData){
    
  }
},[movieData])

  return (
    <div>


        
        <div>
          {<div>           
        {/* Fields for movies */}
        <h3>Movies:</h3>
        {formData.workPresented.map((movie, index) => (
          <div key={index}>
            <div className="mb-6">
      <label className="block mb-2 text-sm font-medium text-gray-900 ">
      Movie Title:
      </label>

      <input
      className="input-style"
                type="text"
                value={movie.name || ''}
                name={`workPresented.${index}.name`}
                onChange={handleChange}
              />
    </div>
    <div className="mb-6">
      <label className="block mb-2 text-sm font-medium text-gray-900 ">
      Movie Director(s):
      </label>

      <input
      className="input-style"
                type="text"
                value={movie.director || ''}
                name={`workPresented.${index}.director`}
                onChange={handleChange}
              />
    </div>
    <div className="mb-6 w-full grid grid-cols-2 gap-4">
      <div className="col-span-2 md:col-span-1">
      <label className="block mb-2 text-sm font-medium text-gray-900 ">
      Movie Duration in Minutes:
      </label>

      <input
            className="input-style"

                type="number"
                min="1"
                max="720"
                value={movie.duration || ''}
                name={`workPresented.${index}.duration`}
                onChange={handleChange}
              />

    </div>
    <div className="col-span-2 md:col-span-1">
      <label className="block mb-2 text-sm font-medium text-gray-900 ">
      Movie Release Year:
      </label>

      <input
            className="input-style"
            type="number"
            min="1878"
            max="2100"
            value={movie.year || ''}
            name={`workPresented.${index}.year`}
            onChange={handleChange}
          />

    </div>
    </div>

    
            
            
          <div className="mb-6">
          <label htmlFor="typeSelect" className="block mb-2 text-sm font-medium text-gray-900 ">
              Movie Format:
              </label>

              <select
                value={movie.videoFormat || ''}
                name={`workPresented.${index}.videoFormat`}
                onChange={handleChange}
                className="block appearance-none w-full bg-gray-50 border text-gray-900 border-gray-300 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
              >
                <option  disabled value=""> Select a format </option>
                  <option value="Digital">Digital</option>
                <option value="35mm">35mm</option>
                <option value="DCP">DCP</option>
                <option value="70MM">70MM</option>
                <option value="16MM">16MM</option>
                <option value="VCR">VCR</option>
                {/* Add other predefined options */}
                <option value="other">Other</option>
              </select>
            {/* Render custom input if the user selects "Other" */}
            {movie.videoFormat === 'custom' && (
              <div className="mb-6">
              <label className="block mb-2 text-sm font-medium text-gray-900 ">
                Format:
                </label>

                <input
                  type="text"
                  className="input-style"
                  value={movie.customFormat || ''}
                  name={`workPresented.${index}.customFormat`}
                  onChange={handleChange}
                />
                </div>
            )}
            </div>
    
{currentMovieIndex !== index && <div className="mb-6"><button className=" bg-gray-50 hover:bg-black text-black font-semibold hover:text-white py-2 px-4 border border-black hover:border-transparent rounded" type="button" onClick={() => openMovieDetails(index)}>
                Edit Movie Details
              </button></div>}
                          {currentMovieIndex === index && <div>
                
                <div className="mb-6">
                <label  className="block mb-2 text-sm font-medium text-gray-900 ">
              Description:
              </label>
              <textarea className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " name={`workPresented.${index}.description`}  value={movie.description|| ''} onChange={handleChange} />
                </div>
            <div className="mb-6">
            <label className="block mb-2 text-sm font-medium text-gray-900 ">
              Movie Language:
            </label>
            <input className="input-style" type="text" name={`workPresented.${index}.inLanguage`}  value={movie.inLanguage|| ''} onChange={handleChange} />
              </div>
              <div className="mb-6">
            <label className="block mb-2 text-sm font-medium text-gray-900 ">
            Movie Country:
            </label>
            <input className="input-style" type="text" name={`workPresented.${index}.countryOfOrigin`} value={movie.countryOfOrigin|| ''} onChange={handleChange} />
              </div>
            
              <div className="mb-6">
            <label className="block mb-2 text-sm font-medium text-gray-900 ">
            Production companies:
            </label>
            <input className="input-style" type="text" name={`workPresented.${index}.productionCompany`} value={movie.productionCompany|| ''} onChange={handleChange} />
              </div>
              <div className="mb-6">
            <label className="block mb-2 text-sm font-medium text-gray-900 ">
            TMDB:
            </label>
            <input className="input-style" type="text" name={`workPresented.${index}.TMDB`} value={movie.TMDB|| ''} onChange={handleChange} />
              </div>
              <div className="mb-6">
            <label className="block mb-2 text-sm font-medium text-gray-900 ">
            Genre:
            </label>
            <input className="input-style" type="text" name={`workPresented.${index}.genre`} value={movie.genre|| ''} onChange={handleChange} />
              </div>
           <div className="mb-6">
            <button type="button" onClick={closeMovieDetails} className="text-blue-500 hover:underline">
                    Close Movie Details
                  </button>
                  </div>
                                  </div>}
            {index > 0 && (
              <div className="mb-6">
              <button type="button" onClick={() => removeMovie(index)} 
              className=" hover:shadow  text-white bg-red-600  rounded border-red-600 p-2  border-2"
              >
                Remove Movie
              </button>
              </div>
            )}
  
            {/* Add fields for other movie properties */}
          </div>
        ))}
        <div className="mb-6">
        <button type="button" onClick={addMovieField}       className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
>
          Add Another Movie
        </button>
        </div>
      </div>}
          </div>  

    </div>
  );
}

export default ScreeningForm;
