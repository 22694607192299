import React, { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom';
const Navbar = ({ isAuthenticated, role }) => { // Define isAuthenticated and role as props\
const location = useLocation();
  const [isOpen, setIsOpen] = useState(false);
  
  const toggleMenu = () => {
    setIsOpen(!isOpen);
  };
useEffect(()=>{
  
},[isAuthenticated])
useEffect(()=>{
},[location])
return (
  <nav className="bg-black text-white fixed top-0 z-50 w-full p-3">
    <div className="flex justify-between items-center">
      <div>
        <Link to="/" className="">
          <p className="text-xl font-medium">zeitgeists.org</p>
        </Link>
      </div>
      <div className={`${!isOpen&&`md:hidden`}`}>
        <button onClick={toggleMenu} className="font-light text-2xl m-2">
          {isOpen ? '↓' : '↑'}
        </button>
      </div>
      <div
        className={`${
          isOpen ? 'flex absolute top-14 left-0 w-full bg-black flex-col items-start py-3 pl-2 border-white border-b-2 ' : 'hidden'
        } md:flex md:items-center md:justify-center md:space-x-4 md:relative text-xl`}
      >
        <Link
          to="/api"
          className={`hover:underline ${location.pathname === '/api' && 'underline'}`}
        >
          API
        </Link>
        <Link to="/listings" className={`hover:underline   ${location.pathname.includes('/listings')&& ' underline'}`}>Listings</Link>

<Link to="/regions" className={`hover:underline   ${location.pathname==='/regions'&& ' underline'}`}>Regions</Link>
{isAuthenticated ? (
  <>
    {(role === 'admin' || role === 'superadmin') && (
      <Link to="/admin" className={`hover:underline   ${location.pathname==='/admin'&& ' underline'}`}>Admin</Link>
    )}
    <Link to="/dashboard" className={`hover:underline   ${location.pathname==='/dashboard'&& 'underline'}`}>Dashboard</Link>
    <Link to="/user" className={`hover:underline   ${location.pathname==='/user'&& 'underline'}`}>Profile</Link>
    <Link to="/logout" className={`hover:underline   ${location.pathname==='/logout'&& 'underline'}`}>Sign Out</Link>
  </>
) : (
  <>
    <Link to="/login"className={`hover:underline   ${location.pathname==='/login'&& 'underline'}`}>Login</Link>
    <Link to="/signup" className={`hover:underline   ${location.pathname==='/signup'&& 'underline'}`}>Sign Up</Link>
  </>
)}      </div>
    </div>
  </nav>
);
};

export default Navbar;

/*
        <div className="md:hidden">
          <input
            type="checkbox"
            className="hidden"
            id="menu-toggle"
            checked={isOpen}
            onChange={toggleMenu}
          />
          <label
            htmlFor="menu-toggle"
            className="cursor-pointer select-none"
          >
            <span className="block w-6 h-1 bg-white mb-1"></span>
            <span className="block w-6 h-1 bg-white mb-1"></span>
            <span className="block w-6 h-1 bg-white"></span>
          </label>
        </div>

*/