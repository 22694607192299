import React from "react";
import { Link } from "react-router-dom";

import SuggestionBlobs from "../utilities/SuggestionBlobs";
function PlaceDashboard({scrapes, place, suggestions, count, setMenuItem}) {

return (
    <div className="grid gap-2 grid-cols-4 p-2">
         {
        <div className="col-span-4 md:col-span-1 sm:col-span-2  m-3 bg-yellow-200 text-center p-6 rounded-lg flex flex-col justify-center items-center shadow-sm">
            <p className="text-5xl">{suggestions && suggestions.filter(suggestion => suggestion.listingId && suggestion.status==='unapproved').length}</p>
            <p className="text-xl">Listing Edit Suggestions</p>
            <Link to={`/place/${place.urlName}?suggestions=edit`} className="text-blue-600 hover:underline">See listings suggestions</Link>
        </div>
    }

    {
        <div className="col-span-4 sm:col-span-2 md:col-span-1 m-3 bg-purple-200 text-center p-6 rounded-lg flex flex-col justify-center items-center shadow-sm">
            <p className="text-5xl">{suggestions && suggestions.filter(suggestion => !suggestion.listingId && suggestion.status==='unapproved').length}</p>
            <p className="text-xl">New Listing Suggestion{suggestions.filter(suggestion => !suggestion.listingId && suggestion.status==='unapproved').length >1 && 's'}</p>
            <Link to={`/place/${place.urlName}/edit/suggestions`} className="text-blue-600 hover:underline">See listings add suggestions</Link>
        </div>
    }

    {
        scrapes.length > 0 && <div className="col-span-4 sm:col-span-2  md:col-span-1 m-3 bg-blue-200 text-center p-6 rounded-lg flex flex-col justify-center items-center shadow-sm">
            <p className="text-5xl">{scrapes.filter(scrape=>scrape.new).length}</p>
            <p className="text-xl">New Scrapes</p>
            <Link to={`/place/${place.urlName}/edit/scrapes`} className="text-blue-600 hover:underline">See all scrapes</Link>
        </div>
    }

    {
        scrapes.length > 0 && <div className="col-span-4 sm:col-span-2  md:col-span-1 m-3 bg-green-200 text-center p-6 rounded-lg flex flex-col justify-center items-center shadow-sm">
            <p className="text-5xl">{count}</p>
            <p className="text-xl">Upcoming Listings</p>
            <Link to={`/place/${place.urlName}/`} className="text-blue-600 hover:underline">See listings</Link>
        </div>
    }

    {suggestions && <SuggestionBlobs suggestions={suggestions} urlName={place.urlName} /> 
    }

</div>



)

}
export default PlaceDashboard;