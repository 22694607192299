import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
function AddOrganizerToPlace({  place, id }) {
  const [organizers, setOrganizers] = useState(false);
  useEffect(() => {
    // Fetch region details when the component mounts
    fetchOrganizers();
  }, []);

  const fetchOrganizers = async () => {
    try {
      const response = await fetch(`/api/region/${place.region.urlName}/organizers/`, {
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });
      if (response.ok) {
        const data = await response.json();
        setOrganizers(data.organizers);
        console.log(data.organizers)
      }
    } catch (error) {
      // Handle error
    }
  }

  const [selectedOrgIds, setselectedOrgIds] = useState(place.organizers|| []);
  const [inputOrgValue, setinputOrgValue] = useState('');
  const [errorMessage, setErrorMessage] = useState('');

  // Convert userList to an array if it's not already, and handle null/undefined cases
  const organizerListArray = Array.isArray(organizers) ? organizers : [];

  const handleRemove = (index) => {
    const updatedOrgIds = selectedOrgIds.filter((_, i) => i !== index);
    setselectedOrgIds(updatedOrgIds);
    setErrorMessage('Add, then press save to update organizers')
  };

  const onChange = (event) => {
    setErrorMessage('');
    setinputOrgValue(event.target.value);
  };

  const handleAdd = () => {
    const selectedOrganizer = organizerListArray.find((org) => org.name === inputOrgValue);
    setErrorMessage('Add, then press save to update organizers')

    if (selectedOrganizer && !selectedOrgIds.includes(selectedOrganizer.id)) {
      setselectedOrgIds([...selectedOrgIds, selectedOrganizer.id]);
      setinputOrgValue('');
    }
  };

  const filteredOrganizerList = organizerListArray.filter((org) => !selectedOrgIds.includes(org.id));

  // need to write addGatekeepers route plural
  const handleSubmit = async (e) => {
    if (window.confirm("Organizer List") !== true) {
      return;
    }
    try {
      const orgIds = selectedOrgIds;
      console.log(orgIds);
      // Make an API request to update user profile using the fetch API
      const response = await fetch(`/api/place/${place.id}/organizers`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
            orgIds: orgIds,
        }),
      });

      if (response.status === 201) {
        setErrorMessage('Organizers updated!')
      } else if (response.status === 401) {
        // Handle authentication error
      } else {
        // Handle other errors
      }
    } catch (error) {
      // Handle any errors that may occur
      console.error('Error adding org:', error);
    }
  };

  return (
    <div className='m-4'>
            <p className="text-lg pb-4">Current Selected Organizers:</p>

      {filteredOrganizerList && selectedOrgIds &&
        selectedOrgIds.map((selectedOrgId, index) => {
          const org = organizerListArray.find((org) => org.id === selectedOrgId);

          if (org) {
            return (
              <p key={org.id}>
                
                <Link  className="underline text-blue-600 hover:text-blue-800 text-lg pr-5" to={`/region/${place.region.urlName}/organizer/${org.urlName}`} >{org.name}</Link>
                <button className=" hover:shadow  text-white bg-red-600  rounded-full border-red-600 p-1 text-xs border-2" onClick={() => handleRemove(index)}>REMOVE</button>
              </p>
            );
          }
        })}
      <b>{errorMessage}</b>
      <div className="mb-6">
      <label className="block mb-2 text-sm font-medium text-gray-900 "> Search for Organizer Name: </label>

  <input
    type="search"
    list={id} 
    autoComplete="on"
    value={inputOrgValue}
    onChange={onChange}
    className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5  mb-4"
  />
  <datalist id={id}>
    {filteredOrganizerList && filteredOrganizerList.map((i) => (
      <option key={i.id} value={i.name}>{i.name}</option>
    ))}
  </datalist>
  <button
    onClick={handleAdd}
    className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
  >
    Add
  </button>
</div>
<div className="mb-6">
  <button
    onClick={handleSubmit}
    className="bg-green-500 hover:bg-green-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline"
  >
    Save Organizers
  </button>
</div>

    </div>
  );
}

export default AddOrganizerToPlace;
