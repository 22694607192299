import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import OrganizerInfo from '../../components/organizer/OrganizerInfo';
import GetListings from '../../components/listing/GetListings';
function OrganizerDetail({ setIsAuthenticated, isAuthenticated, role }) {
  const [organizer, setOrganizer] = useState(null);
  const [places, setPlaces] = useState([]);
  const [region, setRegion] = useState(false);

  useEffect(() => {
    // Fetch region details when the component mounts
    fetchOrganizer();
  }, []);
  useEffect(() => {
    if(organizer) {
      document.title = `${organizer.name} - Zeitgeists`;
    } else {
      document.title = `Organizer - Zeitgeists`;
    }
  }, [organizer]);
  const { regionName,organizerName } = useParams();

  const fetchOrganizer = async () => {
    try {
      const response = await fetch(`/api/region/${regionName}/organizer/${organizerName}`, {
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });
      if (response.ok) {
        const data = await response.json();
        setOrganizer(data.organizer);
        setPlaces(data.places);
        setRegion(data.region);
      }
    } catch (error) {
      // Handle error
    }
  };

  return (
    <div className="grid grid-cols-3 gap-4 mt-16">
      <div className="col-span-3 md:col-span-1">
      {organizer && <OrganizerInfo places={places} organizer={organizer}/>}
      </div>
      
 


<div className="col-span-3 md:col-span-2">

      {organizer &&   (
        <div className="m-4">
        <GetListings organizers={[organizer.id]} />
        </div>
      )}
</div>
    </div>
  );
}

export default OrganizerDetail;
