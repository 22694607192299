  import React, {useState, useEffect} from "react";
  const cities = [
    { name: 'Los Angeles', url: 'los-angeles' },
    { name: 'Chicago', url: 'chicago' },
    { name: 'Mexico City', url: 'mexico-city' },
    { name: 'New York', url: 'new-york' },
    { name: 'Toronto', url: 'toronto' },
    { name: 'San Francisco', url: 'san-francisco' },
    { name: 'New Orleans', url: 'new-orleans' },
    { name: 'Montreal', url: 'montreal' },
    { name: 'Vancouver', url: 'vancouver' },
    { name: 'Austin', url: 'austin' },
    { name: 'Miami', url: 'miami' },
    { name: 'Seattle', url: 'seattle' },
    { name: 'Washington D.C.', url: 'washington-dc' },
    { name: 'Atlanta', url: 'atlanta' },
    { name: 'Boston', url: 'boston' },
    { name: 'Nashville', url: 'nashville' },
  ];
  const types = ['Screening','Exhibition','Reading','Talk','Concert','Book Fair','Art Fair','Screening','Exhibition','Reading','Talk','Concert'];
  const getRandomIndex = (arrayLength) => Math.floor(Math.random() * arrayLength);

const TopHeadAnimation = () => {
  const [currentCity, setCurrentCity] = useState(cities[getRandomIndex(cities.length)]);
  const [currentType, setCurrentType] = useState(types[getRandomIndex(types.length)]);
  const [fade, setFade] = useState(true); // New state for controlling fade effect

  useEffect(() => {
    const intervalId = setInterval(() => {
      setFade(false); // Trigger exit transition

      setTimeout(() => {
        setCurrentCity(cities[getRandomIndex(cities.length)]);
        setCurrentType(types[getRandomIndex(types.length)]);
        setFade(true); // Trigger enter transition
      }, 500); // This timeout should match the duration of your exit transition
    }, 6000);

    return () => clearInterval(intervalId);
  }, []);

  return (
    <div className='text-center'>
      <p className={`text-xl transition-opacity duration-800 ${fade ? 'opacity-100' : 'opacity-0'}`}>
        Upcoming <span className='text-green-400'>{currentType}</span> 
        <span className='text-black'> listings </span> 
        in <span className='text-blue-400'>{currentCity.name}</span>
      </p>
      <div className="flex justify-center p-4">

  <div className={`bg-gray-800 text-white text-sm font-mono p-4 rounded-md shadow-md mx-auto  transition-opacity duration-800 ${fade ? 'opacity-100' : 'opacity-0'} `}
  
  style={{ wordWrap: 'break-word', wordBreak: 'break-all' }}
  >
    {`zeitgeists.org/data/region/${currentCity.url}/listings/type/${currentType.replace(' ', '-').toLowerCase()}`}
  </div>
      </div>
    </div>
  );
};
export default TopHeadAnimation;
