import React, { useEffect, useState } from 'react';
import { useNavigate,Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import EditPlace from '../../components/place/EditPlace';
import DeletePlaceListings from '../../components/place/DeletePlaceListings';
import EditPlaceBar from '../../components/utilities/EditPlaceBar';

function EditPlaceSettings({setIsAuthenticated, isAuthenticated, role }) {
  const navigate = useNavigate();
    const [place,setPlace] = useState(false);
    const [region,setRegion] = useState(false);


    useEffect(() => {
      // Fetch region details when the component mounts
      fetchPlaceDetails();
      //fetch user list in place detauks
    }, []);

    useEffect(() => {
      if(place) {
        document.title = `Edit ${place.name} Settings - Zeitgeists`;
      } else {
        document.title = `Place - Zeitgeists`;
      }
    }, [place]);
    
    const { regionName,placeName } = useParams(); // Access the "urlName" route parameter

    const fetchPlaceDetails = async () => {
      try {
        const response = await fetch(`/api/region/${regionName}/place/${placeName}/edit/settings`, {
          headers: {
            'Content-Type': 'application/json',
          },
          credentials: 'include',
        });
    
        if (response.ok) {
          const data = await response.json();
          setPlace(data.place);
          setRegion(data.region);
        } else if (response.status === 403) {
          navigate(`/region/${regionName}/place/${placeName}/edit/`);
        } else if (response.status === 401) {
          navigate(`/region/${regionName}/place/${placeName}/edit/`);
        } else {
          // Handle other non-ok responses
          console.error('HTTP Error:', response.status);
        }
      } catch (error) {
        console.error('Network or other error:', error);
      }
    };
//display place people

   return (
    <div className="p-4">

 {place &&  <EditPlaceBar active="Settings" type="place" place={place} region={region} />}
    <div className="content flex-grow">
     {place&&region &&  <EditPlace onChange={fetchPlaceDetails} place={place} region={region} />}
</div>
{place &&<div>
  <p className='text-red-500 underline'>Danger</p>
  <DeletePlaceListings place={place} />
</div> }
    </div>


    
  );
}

export default EditPlaceSettings;
