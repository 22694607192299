import React, { useEffect, useState } from 'react';
import { useNavigate,Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import DisplayIntegrations from '../../components/place/DisplayIntegrations';
import AddIntegrations from '../../components/place/AddIntegrations';
import DisplayScrapes from '../../components/place/DisplayScrapes';
import EditBar from '../../components/utilities/EditPlaceBar';
import ManageIntegration from '../../components/place/ManageIntegration';
function EditPlaceIntegrations({setIsAuthenticated, isAuthenticated, role }) {
  const navigate = useNavigate();
  const { regionName,placeName } = useParams(); // Access the "urlName" route parameter

  const [place, setPlace] = useState(false);
  const [selectedIntegration, setSelectedIntegration] = useState(false);
  const [integrations, setIntegrations] = useState(false);
    useEffect(() => {
      if(!isAuthenticated) {
      // Fetch region details when the component mounts
      navigate(`/login/region/${regionName}/place/${placeName}/edit`)
      }
      fetchPlaceDetails();
      //fetch user list in place detauks
    }, []);

    useEffect(() => {
      if(place) {
        document.title = `Edit ${place.name} Integrations - Zeitgeists`;
      } else {
        document.title = `Place - Zeitgeists`;
      }
    }, [place]);
    


      const fetchPlaceDetails = async () => {
        try {
            const response = await fetch(`/api/region/${regionName}/place/${placeName}/edit/integrations`, {
                headers: {
                  'Content-Type': 'application/json', // Use a function to get the cookie value
                        },
                        credentials: 'include', // Include cookies
              });
               if (response.ok) {
                const data = await response.json();
                console.log(data)
                  if(response.status ===201) {
                    setPlace(data.place);
                    setIntegrations(data.integrationTypes)
                  }
              }
             else if(response.status===401||response.status===403) {
                navigate(`/region/${regionName}/place/${placeName}/edit/logs`)
              }
   
        
        } catch (error) {
          console.error(error);
        }
      }
// integration logs

   return (

    
    <div className="p-4">
      { place && <EditBar type="place" place={place} active={'Integrations'} region={place.region} />} 
    <div>
      <div className=' grid grid-cols-2'>
<div className='col-span-2 sm:col-span-1'>
{ place && integrations  && <DisplayIntegrations place={place} integrations={integrations} setSelectedIntegration={setSelectedIntegration} />}

</div>
<div className='col-span-2 sm:col-span-1'>

{ place && integrations  && selectedIntegration ? <ManageIntegration place={place} selectedIntegration={selectedIntegration} setSelectedIntegration={setSelectedIntegration} onChange={fetchPlaceDetails} />   : 
<AddIntegrations place={place} integrations={integrations} onChange={fetchPlaceDetails} />}

</div>
      </div>
    </div>


    
  
 
    

       </div>


    
  );
}

export default EditPlaceIntegrations;
