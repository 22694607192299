import React, { useEffect, useState, } from 'react';
import {Link, useParams} from 'react-router-dom';
import PlaceInfo from '../../components/place/PlaceInfo';
import PlacesMap from '../../components/utilities/PlacesMap';
import '../../custom.css'

function Places({setIsAuthenticated,isAuthenticated}) {
  const { regionName } = useParams();
  const [region,setRegion] = useState(false); 
  const [organizers,setOrganizers] = useState(false); 

  const [places,setPlaces] = useState(false); 
  const [user,setUser] = useState(false); 
  //show user places if logged in and notifications about places
  //if not show blurb about places then a list of places
//get all places and display them with links to the places and buttons to edit if they are logged in
//nearby places using coords
const [activePlace,setActivePlace] = useState('');

 
useEffect(() => {
  document.title = 'Places - Zeitgeists';
  fetchPlacesNew();
}, []);
const fetchPlacesNew = async () => {
  try {
    const response = await fetch(`/api/region/${regionName}/places`, {
      headers: {
        'Content-Type': 'application/json', // Use a function to get the cookie value
              },
              credentials: 'include', // Include cookies
    });
     if (response.ok) {
      const data = await response.json();
      setPlaces(data.places);
      setRegion(data.region);
      setOrganizers(data.organizers);
      if(response.status === 201) {
        setUser({userId:data.userId,region:data.region, role:data.role})
      }
//set deleteplaces update route;
      //  setShowInformation(false);
    } 
    else {

    }
  } catch (error) {
    console.log('Error fetching places', error);
//      setIsAuthenticated(false);

  }
};
const handleDeletePlace = async (placeId) => {
  if (!window.confirm('Confirm delete place')) {
    return;
  }

  try {
    const response = await fetch('/api/deletePlace/' + placeId, {
      method: 'DELETE',
      headers: {
        'Content-Type': 'application/json',
        credentials: 'include', // Include cookies
      },
    });

    if (response.status === 201) {
      //setErrorMessage('Place Deleted');
      fetchPlacesNew();
    } else if (response.status === 401) {
      // Handle unauthorized access
    } else {
      // Handle other errors
    }
  } catch (error) {
    //setErrorMessage('Error deleting place');
    console.error('Error deleting place:', error);
  }
};

const handleSelectPlace = (placeId) => {
  setActivePlace(placeId);

};

const regionInfo = () => {
  return <div className='md:bg-gray-100 shadow-md rounded-xl m-6 p-4'>
    <div className='text-4xl m-4'>
      Places {places&&<> in <Link className='hover:underline font-medium' to={`/region/${regionName}`} >{region.name}</Link></>}
    </div>
  </div>
}
  return (  
    <div  className='' style={{height: '80vh'}} >
      {/* Map Container */}
      <div className='hidden md:block'>
          {places && ( <PlacesMap region={region} places={places} setActivePlace={handleSelectPlace} activePlace={activePlace} />)}
      </div>
      {/* Overlay Container */}
      <div className="hidden md:block content-container pt-28">
          {region&& regionInfo()}
          {places && places.map((place) => (
            <div className={` bg-gray-100 shadow-md rounded-xl m-6 p-4 ${activePlace === place.id ? '' : ''}`} key={place.id}>
                {(user &&(user.role === 'superadmin' || user.role === 'gatekeeper')) ? <PlaceInfo organizers={organizers} place={place} deletePlace={true} handleDelete={handleDeletePlace} noMap={true} activePlace={activePlace===place.id}  /> : <PlaceInfo organizers={organizers} place={place} noMap={true} activePlace={activePlace===place.id} />}
            </div>
          ))}
      </div>
      {/* Overlay Container */}
      <div className="block md:hidden pt-36">
        {region&& regionInfo()}
        <div className='shadow-md rounded-xl m-6 p-4'>
          { places &&<PlacesMap region={region} small={true} places={places} setActivePlace={handleSelectPlace} activePlace={activePlace} />}
        </div>
        {places && places.map((place) => (
        <div className={` shadow-md rounded-xl m-6 p-4 ${activePlace === place.id ? '' : ''}`} key={place.id}>
            {(user &&(user.role === 'superadmin' || user.role === 'gatekeeper')) ? <PlaceInfo organizers={organizers} place={place} deletePlace={true} handleDelete={handleDeletePlace} noMap={true} activePlace={activePlace===place.id}  /> : <PlaceInfo organizers={organizers} place={place} noMap={true}  activePlace={activePlace===place.id} />}
        </div>
        ))}
      </div>
    </div>
);
}

export default Places;



