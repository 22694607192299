import React, { useState } from "react";
import PlaceInfo from "./PlaceInfo";
import '../../custom.css'

function DisplayPlaces({places, edit,organizers,onChange,gatekeeper,region}) {
    const handleDeletePlace = async (placeId) => {
        if (!window.confirm('Confirm delete place')) {
          return;
        }
      
        try {
          const response = await fetch('/api/placed/' + placeId, {
            method: 'DELETE',
            headers: {
              'Content-Type': 'application/json',
              credentials: 'include', // Include cookies
            },
          });
      
          if (response.status === 201) {
            //setErrorMessage('Place Deleted');
            onChange();
          } else if (response.status === 401) {
            // Handle unauthorized access
          } else {
            // Handle other errors
          }
        } catch (error) {
          //setErrorMessage('Error deleting place');
          console.error('Error deleting place:', error);
        }
      };

      
    return (
        places ? <div className="masonry-grid ">

            {places.map((place)=>

            <div className="masonry-grid-item" key={place.id}>
                <PlaceInfo region={region} place={place} organizers={organizers} light={true} noMap={true} deletePlace={gatekeeper&&true} handleDelete={gatekeeper&&handleDeletePlace}  />
            </div>
        )}
        </div> : 
        <div>No places</div>

    )

    }
export default DisplayPlaces