import '../main.css'
import React, { useEffect, useState } from 'react';
import { useNavigate,Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import EditOrganizer from '../../components/organizer/EditOrganizer';

import DisplayPlaces from '../../components/place/DisplayPlaces';
import ManageOrganizerUsers from '../../components/organizer/ManageOrganizerUsers';
//need to write a function to add regionUrlName when urername created, and routes for info by name
/*
if signed as a gate keeper in show my regions
links to edit -> go to region page and add new places or remove them
i think just have notification about
proably need to do some consensus for gatekeepers to add new places or remove places
not for now though

    {place&&editPlace&&owns&&userList &&isAuthenticated ? <EditPlacePeople place={place} userList={userList} /> : null}

*/
function EditOrganizerDetail({setIsAuthenticated, isAuthenticated, role }) {
  const navigate = useNavigate();
  const [organizer,setOrganizer] = useState(false);
  const [places,setPlaces] = useState(false);
  const [region, setRegion] = useState(false);


  const [edit, setEdit] = useState(false);
    useEffect(() => {
      if(!isAuthenticated) {
        navigate(`/login/region/${regionName}/organizer/${organizerName}/edit`)
      }
      // Fetch region details when the component mounts
      fetchOrganizer();
    }, []);
  
    useEffect(() => {
      if(organizer) {
        document.title = `Edit ${organizer.name} - Zeitgeists`;
      } else {
        document.title = `Organizer - Zeitgeists`;
      }
    }, [organizer]);
    
    const { regionName,organizerName } = useParams();

      const fetchOrganizer = async () => {
        try {
          const response = await fetch(`/api/region/${regionName}/organizer/${organizerName}`, {
            headers: {
                  'Content-Type': 'application/json', // Use a function to get the cookie value
                        },
                        credentials: 'include', // Include cookies
              });
               if (response.ok) {
                const data = await response.json()
                setOrganizer(data.organizer);
                setEdit(data.power);
                setPlaces(data.places);
                setRegion(data.region);
              }
              //restict this
              if(response.status===401) {
                navigate(`/login/region/${regionName}/organizer/${organizerName}/edit`)
              }
        } catch (error) {
          setOrganizer(false)
        }
      }


   return (
    <div className="m-4 mt-36">


{organizer && (
  <div className="grid grid-cols-3 gap-3">

  {<p className="col-span-3 text-center font-bold text-2xl m-4"><Link className="underline" to={`/region/${regionName}/organizer/${organizer.urlName}/`}> {organizer.name}</Link></p>}

    { <div className="col-span-3 "><EditOrganizer organizer={organizer} onChange={fetchOrganizer}/></div>}
    {<div className="col-span-3 "><ManageOrganizerUsers edit={5} space={organizer}/></div>}

  {places && <div className="col-span-3">
    <div className='text-center font-bold text-xl'>Places:</div>
    <DisplayPlaces places={places} region={region} />
    </div> }

  </div>

)} 
         

   

    </div>


    
  );
}

export default EditOrganizerDetail;
