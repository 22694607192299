import React, { useEffect, useState } from 'react';
import { useParams,Link } from 'react-router-dom';


import DisplayPlaces from '../../components/place/DisplayPlaces';
import DisplayOrganizers from '../../components/organizer/DisplayOrganizers';
import RegionInfo from '../../components/region/RegionInfo';
function RegionDetail({ setIsAuthenticated, isAuthenticated, role }) {
  const { urlName } = useParams();
  const [region, setRegion] = useState(null); // Initialize region as null
  const [places, setPlaces] = useState(false)
  const [organizers, setOrganizers] = useState(false)


  useEffect(() => {
    if(region) {
      document.title = `${region.name} - Zeitgeists`;
    } else {
      document.title = `Region - Zeitgeists`;
    }
  }, [region]);

useEffect(() => {

  const fetchRegionDetails = async () => {
    try {
      const response = await fetch(`/api/region/${urlName}`, {
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });

      if (response.ok) {
        const data = await response.json();
        setRegion(data.region);
        setOrganizers(data.organizers);
        setPlaces(data.places);
      } else {
        // Handle invalid region name or other error scenarios
        console.log(`${urlName} is an invalid region name.`);
      }
    } catch (error) {
      console.log(error);
    }
  };
  
  // Fetch region details when the component mounts
  fetchRegionDetails();
}, [urlName]);
  return (
    <div className='mt-16'>
      {region &&(
        <div className="m-4 text-center">
          <RegionInfo region={region} />
 <br/>
                      {places && <div>
                        <p className="text-2xl font-bold mb-2">
                          <Link to={`/region/${region.urlName}/places`} className='underline'>Places</Link>
                          </p>
                        <DisplayPlaces places={places} organizers={organizers} />
                      </div>}
                      <br/>
                        {organizers && <div>
                          <p className="text-2xl font-bold mb-2">
                          <Link to={`/region/${region.urlName}/organizers`} className='underline'>Organizers</Link>
                          </p>
                          <DisplayOrganizers region={region} places={places} organizers={organizers} />
                        </div>}

        </div>
      )
      
      
      
      
  }


    </div>
  );
}

export default RegionDetail;
