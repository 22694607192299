import React, { useEffect, useState } from 'react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';
import SetPlaceOrgsNew from '../place/SetPlaceOrgsNew';

import ScreeningForm from './ScreeningForm';
import { useForm, Controller } from "react-hook-form"
function AddListingNew({ onChange,regions }) {
  const validTypes = ['Talk','Show','Screening','Fair','Exhibition','Family','Workshop','Tour','Party','Exclusive']

  function SuccessMessage({data}) {

    let message = 'Success';
    if(data.listing) {
      message = data.message + ' Open it '+ <a className='underline' href={`/listing/${data.listing.uuid}`}>here</a>
    } else if (data.suggestion) {
      message = data.message;
    }
    
    return (
      <div className="border text-xl border-green-400 rounded  bg-green-100 px-4 py-3 text-green-700">
        {data.listing ? 
        <span>{data.message} Open it  <a className='underline' href={`/listing/${data.listing.uuid}`}>here</a></span>
      : data.suggestion&& data.message}
  </div>

    );
  }
  function FailMessage() {
    return (
      <div className="border text-xl border-red-400 rounded bg-red-100 px-4 py-3 text-red-700">
      Error adding listing.
      </div>
    );
  }
  const {
    watch,
    control,
    register,
    handleSubmit, setValue, getValues, reset } = useForm({
        defaultValues: {
          status: 'live'
        }
      });
      useEffect(()=>{
        if(regions.length==1)  setValue('regionId',regions[0].id)
    },[regions])
  const type = watch('type')
  const regionId = watch('regionId')

  const [suggest, setSuggest] = useState(false);
  const onSubmit = (data) => submitAndConnect(data);
  const [updateMessage, setUpdateMessage] = useState(''); // State for update message
const [additionalData, setAdditonalData] = useState({});
const submitAndConnect = async (data) => {
    // Submit form data to your API or server
    data.additionalData = additionalData;
    // Call the reset function to clear the form
   await  submitListing(data)
}





  const [showMore,setShowMore] = useState(false);

  const handleShowMore = () => {
    if(showMore) {
      setShowMore(false);
    } else {
      setShowMore(true);
    }
  };




  const submitListing = async (data) => {

  // Validate the form fields
  if (!window.confirm('Confirm add Listing')) {
    return; // Stop the submission if validation fails
  } 
  if (data.startDate) {
    data.startDate = data.startDate.toISOString();
  }
  if (data.endDate) {
    data.endDate = data.endDate.toISOString();
  }
  if (data.doorTime) {
    data.doorTime = data.doorTime.toISOString();
  }
  if(data.QA === 'yes') {
    data.QA = true;
  } else {
    data.QA = null;
  }
  if(data.isAccessibleForFree === 'yes') {
    data.isAccessibleForFree = true;
  } else {
    data.isAccessibleForFree = null;
  }
  if(!suggest) {
    delete data.comment;
  }

//when saving suggestions watch for add data
    // Create a new listing object based on the form field values
    try {
      //faetch and render many regions onload
      const response = await fetch(`/api/region/${data.regionId}/listing/`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include', // Include cookies
        body: JSON.stringify(data),
      });
      if (response.ok) {
        // Success: Listing updated successfully
        // You can display a success message here if needed
        const data = await response.json()
        onChange();

        setAdditonalData({})
        
        reset();
        setValue('status','live')

        if(regions.length==1)  setValue('regionId',regions[0].id)
        setShowMore(false)
        // Set a success message
        setUpdateMessage(<SuccessMessage data={data} />);

        // You can clear the message after some time or on user interaction
        setTimeout(() => setUpdateMessage(''), 5000); // Clear message after 5 seconds
        // You can close the form here by calling a callback function if needed
    } else {
        // Error: An error occurred while updating the listing
        const errorData = await response.json();
        console.error(errorData.message); // Log the error message for debugging
        // Update the UI to display the error message to the user
        // For example, you can set it in a state variable to render in your component
    }

    } catch (error) {
      // Handle fetch error
      setUpdateMessage(<FailMessage />);
      console.error(error);
    }
  };
const handleSuggestButton = () => {
    setSuggest(!suggest);
}


  return (
    <div className="">
<p><span className="text-2xl font-bold mb-6">{suggest ? (<span>Suggest New Listing:</span>) : 'Add New Listing:'}</span> <span className=" bg-gray-50 hover:bg-black text-black hover:text-white  rounded-full border border-1 border-black p-1" onClick={handleSuggestButton}>{!suggest ? 'Suggest Listing' : 'Add Listing'}</span>  
</p>


      <form onSubmit={handleSubmit(onSubmit)} >
<div>

<div className="mb-6">
    <label htmlFor="large-input" className="block mb-2 text-sm font-medium text-gray-900 ">Event Title</label>
    <input {...register("name", { required: true })} type="text" id="large-input" className="block w-full p-4 text-gray-900 border border-gray-300 rounded-lg bg-gray-50 sm:text-md focus:ring-blue-500 focus:border-blue-500 " />
</div>
{
  regions && regions.length == 1 && <div className="mb-6">
          <label htmlFor="typeSelect" className="block mb-2 text-sm font-medium text-gray-900">Listing Region:</label>
      <select className="block appearance-none w-full bg-gray-50 border text-gray-900 border-gray-300 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline">
         <option key={regions[0].id} value={regions[0].id}>
             {regions[0].name}
          </option>
      </select>
  </div>
}
    {
      regions && regions.length > 1 && <div className="mb-6">
      <label htmlFor="typeSelect" className="block mb-2 text-sm font-medium text-gray-900">Select Listing Region:</label>
      <select {...register("regionId", { required: true })} className="block appearance-none w-full bg-gray-50 border text-gray-900 border-gray-300 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline">
      <option key="" value="">
              Select a listing region
              </option>
              {regions.map(region=>(
                <option key={region.id} value={region.id}>
                  {region.name}
              </option>
              ))}
          </select>
      </div> 
    }  
{regionId &&<div className="mb-6">
            <SetPlaceOrgsNew
          control={control} // Pass control to SetPlaceOrgs
          setValue={setValue} // Pass setValue to SetPlaceOrgs for updating values
          getValues={getValues}
          region={regionId}
          suggest={suggest}
          setSuggest={setSuggest}
        />  
             
</div>}
<div className="mb-6">
<label htmlFor="typeSelect" className="block mb-2 text-sm font-medium text-gray-900">Select Listing Type:</label>
<select {...register("type", { required: true })} className="block appearance-none w-full bg-gray-50 border text-gray-900 border-gray-300 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline">
<option key="" value="">
            Select a listing type
        </option>
        {validTypes.map(type=>(
       <option key={type} value={type}>
       {type}
   </option>
        ))}     
    </select>
</div>
     <div className="w-full mb-6">
     <label className="block mb-2 text-sm font-medium text-gray-900 ">Start Date and Time:</label>
     <Controller
  name="startDate"
  control={control}
  rules={{ required: "Start Date and Time is required" }} // Adding the rules property
  className="block w-full"
  render={({ field, fieldState: { error } }) => (
    <>
      <DatePicker
        selected={field.value}
        onChange={(date) => field.onChange(date)}
        timeInputLabel="Time:"
        dateFormat="MM/dd/yyyy h:mm aa"
        showTimeInput
        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
      />
      {error && <p>{error.message}</p>} 
    </>
  )}
/>
     </div>

     <div className="w-full mb-6">
     <label className="block mb-2 text-sm font-medium text-gray-900 ">End Date and Time:</label>
     <Controller
  name="endDate"
  control={control}
  className="block w-full"
  render={({ field, fieldState: { error } }) => (
    <>
      <DatePicker
        selected={field.value}
        onChange={(date) => field.onChange(date)}
        timeInputLabel="Time:"
        dateFormat="MM/dd/yyyy h:mm aa"
        showTimeInput
        className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5"
      />
    </>
  )}
/>
     </div>

     <div className="mb-6">
     <label className="block mb-2 text-sm font-medium text-gray-900 "> External URL: </label>
        <input className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " {...register("url", { required: true })} />   
     </div>
            
     <div className="mb-6">
     <label className="block mb-2 text-sm font-medium text-gray-900">  Event Subtitle / Note: </label>
        <textarea className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " {...register('note')}  rows="2"  />   
     </div>
     <div className="mb-6">
     <label className="block mb-2 text-sm font-medium text-gray-900 ">  About: </label>
        <textarea className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " {...register('about')}  rows="4"  />   
     </div>
       
          {!showMore && <button className=" bg-gray-50 hover:bg-black text-black font-semibold hover:text-white py-2 px-4 border border-black hover:border-transparent rounded" onClick={handleShowMore}>Show Additional Fields</button>}
          {showMore && (
  <>
  <div className="block mb-5 mt-10 font-medium text-gray-900 ">
    Additional Fields - <button className='text-blue-400 underline' onClick={handleShowMore}>Close</button>
  </div>


         
  <div className="mb-6">
      <label className="block mb-2 text-sm font-medium text-gray-900 ">
        Q&A:
      </label>
      <Controller
  name="QA"
  control={control}
  render={({ field: { onChange, value } }) => (
    <>
      <input
        type="radio"
        value="yes"
        onChange={onChange}
        checked={value === 'yes'}
        className="mr-2"
      />
      Yes
      <input
        type="radio"
        value="no"
        onChange={onChange}
        checked={value === 'no'}
        className="ml-4 mr-2"
      />
      No
    </>
  )}
/>

    </div>
    <div className="mb-6">
      <label className="block mb-2 text-sm font-medium text-gray-900 ">
        Alternate Name:
      </label>
      <input {...register('alternateName')} className="input-style" />
    </div>

    <div className="mb-6">
      <label className="block mb-2 text-sm font-medium text-gray-900 ">
        Building Room:
      </label>
      <input {...register('room')} className="input-style" />
    </div>

    <div className="mb-6">
      <label className="block mb-2 text-sm font-medium text-gray-900 ">
        Language:
      </label>
      <input {...register('inLanguage')} className="input-style" />
    </div>

    <div className="mb-6">
      <label className="block mb-2 text-sm font-medium text-gray-900 ">
        Is Accessible for Free:
      </label>
      <Controller
        name="isAccessibleForFree"
        control={control}
        render={({ field }) => (
          <>
            <input type="radio" value={'yes'} {...field} className="mr-2" />
            Yes
            <input type="radio" value={'no'} {...field} className="ml-4 mr-2" />
            No
          </>
        )}
      />
    </div>

    <div className="mb-6">
      <label className="block mb-2 text-sm font-medium text-gray-900 ">
        Sponsor Note:
      </label>
      <input {...register('sponsor')} className="input-style" />
    </div>

    <div className="mb-6">
      <label className="block mb-2 text-sm font-medium text-gray-900 ">
        External Image URL:
      </label>
      <input {...register('image')} className="input-style" />
    </div>

    <div className="mb-6">
      <label className="block mb-2 text-sm font-medium text-gray-900 ">
        External Image Alternative Text:
      </label>
      <input {...register('alt')} className="input-style" />
    </div>

    <div className="mb-6">
      <label className="block mb-2 text-sm font-medium text-gray-900 ">
        Keywords:
      </label>
      <input {...register('keywords')} className="input-style" />
    </div>

    <div className="mb-6">
      <label className="block mb-2 text-sm font-medium text-gray-900 ">
        Doortime:
      </label>
      <Controller
        name="doorTime"
        control={control}
        render={({ field }) => (
          <DatePicker
            {...field}
            selected={field.value}
            onChange={(date) => field.onChange(date)}
            showTimeSelect
            showTimeSelectOnly
            timeIntervals={15}
            timeCaption="Time"
            dateFormat="h:mm aa"
            className="input-style"
          />
        )}
      />
    </div>
    <div className="mb-6">
      <label className="block mb-2 text-sm font-medium text-gray-900 ">
      Series title:
      </label>
      <input type="text" className="input-style" name="series.title"  {...register('series_title')}  />
    </div>
    <div className="mb-6">
      <label className="block mb-2 text-sm font-medium text-gray-900 ">
      Series Url:
      </label>

      <input type="text" className="input-style" name="series.url" {...register('series_url')}  />
    </div>   
    <div className="mb-6">
      <label className="block mb-2 text-sm font-medium text-gray-900 ">
        Status:
      </label>
      <Controller
  name="status"
  control={control}
  render={({ field: { onChange, value } }) => (
    <>
      <input
        type="radio"
        value="live"
        onChange={onChange}
        checked={value === 'live'}
        className="mr-2"
      />
      Live
      <input
        type="radio"
        value="editing"
        onChange={onChange}
        checked={value === 'editing'}
        className="ml-4 mr-2"
      />
      Editing
      <input
        type="radio"
        value="hiding"
        onChange={onChange}
        checked={value === 'hiding'}
        className="ml-4 mr-2"
      />
      Hiding
    </>
  )}
/>

    </div>

    <button className="button-style" onClick={handleShowMore}>
      Close Additional Fields
    </button>
  </>
)}

          </div> 
        
            <br/>

         {type==='Screening' && <ScreeningForm setMovieData={setAdditonalData} movieData={additionalData}/>}
         <div className="mb-6">
      <label className="block mb-2 text-sm font-medium text-gray-900 ">
        Listing Add Comments:
      </label>
      <textarea {...register('comment')} className="input-style" />
    </div>
         <button type="submit" className=" bg-gray-50 hover:bg-black text-black font-semibold hover:text-white py-2 px-4 border border-black hover:border-transparent rounded" onClick={handleShowMore}>{suggest ? 'Suggest New Listing' : 'Add New Listing'}</button>  
     
<br/>

{updateMessage && <p>{updateMessage}</p>}
<hr />
       
      </form>

    </div>
  );
}

export default AddListingNew;
