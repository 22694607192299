// PasswordReset.js

import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useForm, Controller } from 'react-hook-form';

const PasswordReset = () => {
  const { handleSubmit, watch, control, register, setError, reset, formState: { errors }} = useForm();

  const { token } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
      document.title = `Reset Password - Zeitgeists`;
  }, []);
  function FailMessage ({message})  {
    return (
      <div className="mt-5">
      <div className="border border-red-400 bg-red-100 px-4 py-3 text-red-700">
        <p>{message}</p>
      </div>
    </div>
    )
  }
  function SuccessMessage ({message})  {
    return (
      <div className="mt-5">
      <div className="border border-green-400 bg-green-100 px-4 py-3 text-green-700">
        <p>{message}</p>
      </div>
    </div>
    )
  }
  const [status, setStatus] = useState('');

  const onSubmit = async (formData) => {

    if (formData.password !== formData.confirmPassword) {
      setStatus(<FailMessage message={'Passwords do not match'} />);
      return;
    } 

    try {
      const response = await fetch(`/api/resetpassword/${token}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ newPassword : formData.password}),
      });
      if(response.ok) {
        setStatus(<SuccessMessage message={'Password updated. Redirecting to login now.'} />);
        setTimeout(() => {
          navigate('/login');
        }, 4000); // Redirect after 2 seconds
      }
      if (!response.ok) {
        setStatus(<FailMessage message={'Error with Password update. Try generating a new password reset link.'} />);
        navigate('/forgotpassword');
      }
    } catch (error) {
      console.error(error);
    }
  };


  return (

    <div class="flex min-h-full flex-col justify-center px-6 py-12 lg:px-8 mt-36">
      <div class="sm:mx-auto sm:w-full sm:max-w-sm">
        <h2 class="mt-10 text-center text-2xl font-bold leading-9 tracking-tight text-black">Reset Password</h2>
      </div>
    
      <div class="mt-10 sm:mx-auto sm:w-full sm:max-w-sm">
        <form class="space-y-6" onSubmit={handleSubmit(onSubmit)}>
        <div>
            <div className="flex items-center justify-between">
              <label htmlFor="password" className="block text-sm font-medium leading-6 text-gray-900">
                Password
              </label>
            </div>
            <div className="mt-2">
            <input
              id="password"
              name="password"
              type="password"
              {...register('password', {
                required: 'Password is required',
                pattern: {
                  value: /^(?=.*[@#$%^&*!_-~+=]).{8,}$/,
                  message: 'Password should be at least 8 characters long and contain at least one special character.',
                },
              })}
              autoComplete="new-password"
              className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-xl rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
            />
            </div>
            {errors.password && (
              <div className="mt-5">
                <div className="border border-red-400 bg-red-100 px-4 py-3 text-red-700">
                  <p>{errors.password.message}</p>
                </div>
              </div>
            )}
          </div>
          <div className="mt-2">
        <label htmlFor="confirmPassword" className="block text-sm font-medium leading-6 text-gray-900">
          Confirm Password
        </label>
        <div className="mt-2">
          <Controller
            name="confirmPassword"
            control={control}
            defaultValue=""
            rules={{
              required: 'Confirm Password is required',
              validate: (value) => value === watch('password') || 'Passwords do not match',
            }}
            render={({ field }) => (
              <input
                {...field}
                type="password"
                autoComplete="new-password"
                className="bg-gray-50 border border-gray-300 text-gray-900 sm:text-xl rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 "
              />
            )}
          />
        </div>
        {errors.confirmPassword && (
          <div className="mt-5">
            <div className="border border-red-400 bg-red-100 px-4 py-3 text-red-700">
              <p>{errors.confirmPassword.message}</p>
            </div>
          </div>
        )}
      </div>
      {status}

      <button
                    type="submit"
                    className="w-full px-4 py-2 text-sm font-semibold text-black bg-white border border-black rounded-md shadow-sm hover:bg-black hover:text-white focus:outline-none focus:ring focus:ring-gray-600"
                 >
Submit                  </button>
          
        </form>

      </div>
    </div>
    
  );
};

export default PasswordReset;
