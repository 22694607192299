import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
const moment = require('moment-timezone');

function truncateString(str, num) {
  if (str.length <= num) {
    return str;
  }
  return str.slice(0, num) + '...';
}

function DisplaySuggestedAdd({suggestion, open, listing}) {
  const [openSuggestion,setOpenSuggestion] = useState(open || false);

  useEffect(()=>{
  
    setOpenSuggestion(open || false);
  }, [open]);

  function formatISOInLocal(isoString) {
    // Format: 'MMM DD, h:mm A' e.g., 'Nov 17, 6:00 AM'
    return moment(isoString).format('MMM DD, h:mm A');
}
  return (
    <div key={suggestion.id} className="p-2 mt-2  border border-black border-dashed shadow mb-4">
<p>
{suggestion.status !== 'approved' && <Link className="text-blue-500 hover:underline" to={`/listing/${listing.uuid}?suggestion=${suggestion.uuid}`} > View Suggestion</Link> }Status: <span className={`${suggestion.status === 'unapproved' ? 'bg-red-300' : suggestion.status === 'approved' ? 'bg-green-300' : suggestion.status === 'rejected' ? 'bg-red-500' : ''} capitalize`}>
  {suggestion.status}
</span>

</p>
      
      {suggestion.createdAt && (
        <div>
          Submited At: {formatISOInLocal(suggestion.createdAt)} by{' '}
          {
            suggestion.user ? <Link to={`/user/${suggestion.user.username}`} className="text-blue-600">
            @{suggestion.user.username}
          </Link> : 'Integration'
          }
          
        </div>
      )}
      {
        openSuggestion && <div> opened </div>
      }
      {suggestion.comment && <p>Comment: <span className="bg-green-200">{truncateString(suggestion.comment,500)}</span></p>}


    
  </div>
)}
    


export default DisplaySuggestedAdd;
