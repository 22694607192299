import React, {useState,useEffect} from 'react';
import { useForm, Controller,useFieldArray } from "react-hook-form"
function ManageIntegration({ place, integrations, selectedIntegration,setSelectedIntegration, onChange }) {
    const [errorMessage, setErrorMessage] = useState(false);

    function SuccessMessage({ data }) {
        return (
          <div className="border text-xl border-green-400  bg-green-100 px-4 py-3 text-green-700">
              {data}
          </div>
        );
      }
      function FailMessage({data}) {
        return (
          <div className="border text-xl border-red-400  bg-red-100 px-4 py-3 text-red-700">
          {data ? data : 'Error Editing Integration'}
          </div>
        );
      }
  const {
    watch,
    control,
    register,
    handleSubmit, formState: { errors }, setError, getValues, reset, setValue} = useForm({
        defaultValues: {
          type: selectedIntegration.type,
          name: selectedIntegration.name,
          action: selectedIntegration.action,
          key: selectedIntegration.key || null,
          organizer: selectedIntegration.organizerId|| null,
          url: selectedIntegration.url|| null,
          listingType: selectedIntegration.key|| null,
        }
      });
      useEffect(()=>{
        setValue('type', selectedIntegration.type);
        setValue('name', selectedIntegration.name);
        setValue('action', selectedIntegration.action);
        setValue('key', selectedIntegration.key||null);
        setValue('organizer', selectedIntegration.organizerId||null);
        setValue('url', selectedIntegration.url||null);
        setValue('listingType', selectedIntegration.key||null);

      },[selectedIntegration])
    const { fields, append, remove } = useFieldArray({
        control,
        name: "catches", // Name of the field array in your form data
      });

      const type = watch('type');

  const organizer = watch('organizer');
  const litingType = watch('litingType');


  const onSubmit = async (data) => {
        // Check if type is OrganizerCatch and catches are empty

  if (window.confirm("Confirm Edit Integration")!==true) {
      return;
  }
    try {
      // Send a POST request to your server to save the data
      const response = await fetch(`/api/region/${place.region.urlName}/place/${place.urlName}/edit/integration/${selectedIntegration.uuid}`, {
        method: 'PATCH',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(data),
      });

      if (response.ok) {
        // Reset the form after successful submission
        const data = await response.json();
        console.log(data);
        setErrorMessage(<SuccessMessage data={data.message} />);
        onChange();
        // Handle success or navigate to a different page
      } else {
        setErrorMessage(<FailMessage  />);
        console.error('Error editing integration:', response.statusText);
      }
    } catch (error) {
      setErrorMessage(<FailMessage  />);
      console.error('Error editing integration:', error);
    }
  };


  

  return (

    <div className="m-4">
        <button className='underline text-red-500' onClick={()=>setSelectedIntegration(false)}>Close</button>
            <p className="text-lg pb-2">Manage Integration:</p>
<form onSubmit={(handleSubmit(onSubmit))} className="flex flex-col space-y-4">
Name: {selectedIntegration.name}
<div className='text-lg'>
Type: {selectedIntegration.integrationType.name}
</div>
<div  className="mb-6">
<label htmlFor="actionsSelect" className="block mb-2 text-sm font-medium text-gray-900">Change Access Level:</label>
<select {...register("action", { required: 'Access level is required' })} className="block appearance-none w-full bg-gray-50 border text-gray-900 border-gray-300 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline">
<option key="" value="">
Select Access Level
        </option>
       <option key="add" value="add">
      Full - Manage Live Listings (Add/Edit)
               </option>
        <option key="suggest" value="suggest">
        Suggest - Edit & New Listing Suggestions
                </option>
                <option key="disabled" value="disabled">
        Disabled - Temporarily inactive
                </option>
    </select>
</div>
{errors.action && (
              <div className="mt-5">
                <div className="border border-red-400 bg-red-100 px-4 py-3 text-red-700">
                  <p>{errors.action.message}</p>
                </div>
              </div>
)}
<div>
<label className="block mb-2 text-sm font-medium text-gray-900 "> Integration Label: </label>
        <input className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-lg focus:ring-blue-500 focus:border-blue-500 block w-full p-2.5 " {...register("name",  {required: 'Label is required'})} />   
     </div>
     {errors.name && (
              <div className="mt-5">
                <div className="border border-red-400 bg-red-100 px-4 py-3 text-red-700">
                  <p>{errors.name.message}</p>
                </div>
              </div>
)}  






    {errorMessage}

    <button type="submit" className=" bg-gray-50 hover:bg-black text-black font-semibold hover:text-white py-2 px-4 border border-black hover:border-transparent rounded">Update</button>

    </form>


    </div>

  );
}

export default ManageIntegration;
