import React, { useEffect, useState } from "react";
import { Controller } from "react-hook-form";

function SetPlaceOrgsNew({ control, setValue, getValues, thePlace, preOrgs, suggest,setSuggest,region }) {
    const [places, setPlaces] = useState(thePlace ? [thePlace] : []);
    const [selectedOrganizers, setSelectedOrganizers] = useState(preOrgs || []);

    useEffect(() => {
            listingPlaces();
        if(!places.includes(getValues('placeId'))){
            setValue('placeId','');
            const orgIds = preOrgs && preOrgs.map(org=>org.organizerId)
            setSelectedOrganizers(orgIds || []);
            setValue('organizers', []);
        }
    }, [suggest,region]);

    const listingPlaces = async () => {
        try {
            const fetchUrl = thePlace  ? `/api/region/${region}/listing/place/${thePlace}` : (suggest ? `/api/region/${getValues('regionId')}/listings/places/all` :`/api/region/${getValues('regionId')}/listings/places`)
            const response = await fetch(fetchUrl);
            const resData = await response.json();
            console.log(resData)
            setPlaces(resData);
            if(!suggest&&resData.length===0) {
              setSuggest(true)
            }
        } catch (error) {
            console.error(error);
        }
    }

    const handlePlaceChange = (place) => {
        setValue('placeId', place.id);
        setValue('geo', place.geo);
        setValue('address', place.address);
        setSelectedOrganizers([]); // Reset organizers when the place changes
        setValue('organizers', []); // Update organizers in the form
    };

    const handleOrganizerChange = (e) => {
        const selectedOptions = Array.from(e.target.selectedOptions, option => option.value);
        setSelectedOrganizers(selectedOptions);
        setValue('organizers', selectedOptions); // Update organizers in the form
    };


    return (
        <div className="text-black mb-6" >
            {!thePlace && places && (
                <Controller
                    control={control}
                    name="placeId"
                    rules={{ required: "Selecting a place is required" }}
                    render={({ field, fieldState: { error } }) => (
                        <div className="mb-6">


                            <label htmlFor="placeSelect" className="block mb-2 text-sm font-medium text-gray-900 ">Select a Place:</label>
                            
                            <select id="placeSelect"
                                {...field}
                                onChange={(e) => {

                                    const selectedValue = e.target.value;
                                    if(places) {
                                      const place = places.find(p => p.value == selectedValue);
                                      if (place) {
                                          handlePlaceChange(place);
                                      } 
                                    }
 
                                    field.onChange(e);
                                }} className="block appearance-none w-full bg-gray-50 border text-gray-900 border-gray-300 hover:border-gray-500 px-4 py-2 pr-8 rounded shadow leading-tight focus:outline-none focus:shadow-outline">
                                <option value="">Select a place</option>
                                {places.map(place => (
                                    <option key={place.id} value={place.id}>
                                        {place.name}
                                    </option>
                                ))}
                            </select>
                            {error && <p>{error.message}</p>}
                        </div>
                    )}
                />
            )}

{places&&places.find(place => place.id == (thePlace ? thePlace : getValues('placeId')))?.organizers.length > 0 ? (
      <>
        <Controller
          control={control}
          name="organizers"
          render={({ field }) => (
            <div className="mb-6">
              <label className="block mb-2 text-sm font-medium text-gray-900 ">Select Organizers:</label>
              <select
                id="organizerSelect"
                {...field}
                multiple
                onChange={handleOrganizerChange}
                value={selectedOrganizers}
                className="block appearance-none w-full bg-gray-50 border text-gray-900 border-gray-300 hover:border-gray-500 rounded shadow leading-tight focus:outline-none focus:shadow-outline"
              >
                {places.find(place => place.id == (thePlace ? thePlace : getValues('placeId')))?.organizers.map(organizer => (
                  <option key={organizer.id} value={organizer.id}>
                    {organizer.name}
                  </option>
                ))}
              </select>
            </div>
          )}
        />
        <div className="flex flex-wrap gap-2">
          {selectedOrganizers.map(organizerId => {
            const organizer = places.find(place => place.id == (thePlace ? thePlace : getValues('placeId')))?.organizers.find(o => o.id == organizerId);
            return (
              <div key={organizerId} className="flex items-center gap-2 bg-blue-200 rounded px-4 py-2">
                {organizer?.name}
                <button
                  type="button"
                  onClick={() => {
                    const newSelectedOrganizers = selectedOrganizers.filter(id => id != organizerId);
                    setSelectedOrganizers(newSelectedOrganizers);
                    setValue('organizers', newSelectedOrganizers);
                  }}
                  className="bg-red-500 rounded-full w-4 h-4 flex items-center justify-center text-white"
            >
                </button>
              </div>
            );
          })}
        </div>
      </>
    ) : (
      <div className="mb-6">No Organizers</div>
    )}
        </div>
    );
}

export default SetPlaceOrgsNew;
