import React, { useEffect, useState } from 'react';
import { useNavigate,Link } from 'react-router-dom';
import { useParams } from 'react-router-dom';

import EditBar from '../../components/utilities/EditPlaceBar';
import DisplayLogs from '../../components/place/DisplayLogs';
function EditPlaceLogs({setIsAuthenticated, isAuthenticated, role }) {
  const navigate = useNavigate();
    const [place,setPlace] = useState(false);
    const { regionName,placeName } = useParams(); // Access the "urlName" route parameter

   

    //get place by string
    const [editPlace,setEditPlace] = useState(false);
    const [logs,setLogs] = useState(false);
    const [filtered, setFiltered] = useState(false);

    useEffect(() => {
      // Fetch region details when the component mounts
      fetchPlaceDetails();
      //fetch user list in place detauks
    }, []);
    useEffect(() => {
      // Fetch region details when the component mounts
    }, [filtered]);
    useEffect(() => {
      if(place) {
        document.title = `Edit ${place.name} Integration Logs - Zeitgeists`;
      } else {
        document.title = `Place - Zeitgeists`;
      }
    }, [place]);
    

      const fetchPlaceDetails = async () => {
        try {
            const response = await fetch(`/api/region/${regionName}/place/${placeName}/edit/logs`, {
                headers: {
                  'Content-Type': 'application/json', // Use a function to get the cookie value
                        },
                        credentials: 'include', // Include cookies
              });
               if (response.ok) {
                const data = await response.json();
                console.log(data);
                  setPlace(data.place);
                  if(response.status ===201) {
                    setEditPlace(data.edit);
                    setLogs(data.runs);
                  }
              }
              else if(response.status===401||response.status===403) {
                navigate(`/region/${regionName}/place/${placeName}/edit`)
              }
        } catch (error) {

        }
      }
//display place people

   return (

    
    <div className="p-4">
      { place && <EditBar type="place" place={place} active={'Integration Logs'} region={place.region}/>}   


<div>
{place && <DisplayLogs edit={editPlace} place={place} logs={logs} isAuthenticated={isAuthenticated} />}
</div>

    
  
 
    

       </div>


    
  );
}

export default EditPlaceLogs;
