import React, { useEffect, useState } from 'react';
import { useParams,useNavigate } from 'react-router-dom';
import PlaceInfo from '../../components/place/PlaceInfo';
import GetListings from '../../components/listing/GetListings';

function PlaceDetail({ setIsAuthenticated, isAuthenticated, role }) {
  const navigate = useNavigate();
  const [place, setPlace] = useState(false);
  const [organizers, setOrganizers] = useState(false);
  const { regionName,placeName } = useParams();

  const fetchPlaceDetails = async () => {
    try {
      const response = await fetch(`/api/region/${regionName}/place/${placeName}`, {
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });

      if (response.ok) {
        const data = await response.json();
        setPlace(data.place);
        setOrganizers(data.organizers);
        navigate(`/region/${data.place.region.urlName}/place/${data.place.urlName}`, { replace: true });
        }
    } catch (error) {
      // Handle error
    }
  };
  useEffect(() => {
    fetchPlaceDetails();
  }, []);
  useEffect(() => {
    if(place) {
      document.title = `${place.name} - Zeitgeists`;
    } else {
      document.title = `Place - Zeitgeists`;
    }
  }, [place]);

 

  return (
    <div className="grid grid-cols-3 gap-4 mt-16">
      <div className="col-span-3 md:col-span-1">
      {place && <PlaceInfo place={place} organizers={organizers} />}
      </div>
  <div className="col-span-3 md:col-span-2">
      {place && (
        <div className="m-4">
          <GetListings places={[place.id]} placePage={true}  />
        </div>
      )}
</div>

    </div>
  );
}

export default PlaceDetail;
