import React, { useEffect, useState, } from 'react';
import { useParams, useNavigate } from 'react-router-dom';

const EmailConfirmation = (props) => {
    const navigate = useNavigate();
  const [confirmationMessage, setConfirmationMessage] = useState('');
  const {token} = useParams(); // Access the "urlName" route parameter
  useEffect(() => {
    // Make a GET request to your backend route for email confirmation
    const confirm = async () => {
      try {
        const response = await fetch(`/api/confirm/${token}`);
        if(response.ok) {
          const data = await response.json();
          setConfirmationMessage(<p className='text-green-500'>{data.message}</p>);

        } else {
          setConfirmationMessage(<p className='text-red-500'>Invalid or expired token</p>);
        }
        setTimeout(() => {
          navigate('/login'); // Redirect to the login page
        }, 3000); // Redirect after 2 seconds
   
      }
      catch (error) {
        setConfirmationMessage(<p className='text-red-500'>Invalid or expired token</p> );
        console.error(error);
        setTimeout(() => {
          navigate('/login'); // Redirect to the login page
        }, 3000); // Redirect after 2 seconds
   
      }
      
    } 

    confirm();

  }, [token,navigate]);
  useEffect(() => {
    document.title = `Email Confirmation - Zeitgeists`;
}, []);
  return (
    <div className='p-4 mt-36'>
      <div className='text-center p-4 text-2xl font-bold'>
      Email Confirmation
      </div>
      <div className='text-center text-lg p-2'>
{confirmationMessage}
      </div>
      <div className='text-center font-semibold text-lg p-2'>
      Redirecting to login page...
      </div>
    </div>
  );
};

export default EmailConfirmation;
