import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate, useLocation} from 'react-router-dom';


import HomePage from './pages/HomePage';
import NewHome from './pages/NewHome';


import RegisterPage from './pages/user/RegisterPage';
import LoginPage from './pages/user/LoginPage';
import Logout from './pages/user/Logout';

//to import  profile 
import API from './pages/api';
import About from './pages/about';

import Admin from './pages/admin';
import Profile from './pages/user/Profile';
import UserDashboard from './pages/user/UserDashboard';
import UserProfile from './pages/user/UserProfile';

import Regions from './pages/region/Regions';
import RegionDetail from './pages/region/RegionDetail';
import EditRegionDetail from './pages/region/EditRegionDetail';

import Places from './pages/place/Places';
import PlaceDetail from './pages/place/PlaceDetail';
import EditPlace from './pages/place/EditPlace';
import EditPlaceScrapes from './pages/place/EditPlaceScrapes';
import EditPlaceSuggestions from './pages/place/EditPlaceSuggestions';
import EditPlacePeople from './pages/place/EditPlacePeople';
import EditPlaceOrganizers from './pages/place/EditPlaceOrganizers';
import EditPlaceSettings from './pages/place/EditPlaceSettings';
import EditPlaceLogs from './pages/place/EditPlaceLogs';



import Organizers from './pages/organizer/Organizers';
import OrganizerDetail from './pages/organizer/OrganizerDetail';
import EditOrganizerDetail from './pages/organizer/EditOrganizerDetail';

import Listings from './pages/listing/Listings';
import Listing from './pages/listing/Listing';
import EditListing from './pages/listing/EditListing';


import EmailConfirmation from './pages/user/EmailConfirmation';
import PasswordResetRequest from './pages/user/PasswordResetRequest';
import PasswordReset from './pages/user/PasswordReset';
import Navbar from './components/utilities/navbar';
import EditPlaceIntegrations from './pages/place/EditPlaceIntegrations';

import Down from './pages/Down';
import Footer from './components/utilities/Footer';


function App() {

  const [role, setRole] = useState(localStorage.getItem('role')); // Initialize with null
  const [isAuthenticated, setIsAuthenticated] = useState(localStorage.getItem('isAuthenticated')); // Initialize with false
  useEffect(() => {
    // Check if the authentication cookie exists using js-cookie
    if(isAuthenticated) {
    checkAuthStatus()
    }
  }, []);

  const checkAuthStatus = async () => {
    try {
      const response = await fetch('/api/checkAuthStatus', {
        headers: {
          'Content-Type': 'application/json',
        },
        credentials: 'include',
      });
      if (response.ok) {
        setIsAuthenticated(true);
        const data = await response.json()
        setRole(data.role)
      } else {
        setIsAuthenticated(false);
        setRole(null);
      }
    } catch (error) {
      console.log('Error checking auth status', error);
    }
  };
  return (
    <Router>
      <div className="App">
        <header className="App-header">
<Navbar isAuthenticated={isAuthenticated} role={role} />
        </header>
        <div>
          <Routes>

          <Route path="/" element={<NewHome />} />
          <Route path="/api" element={<API />} />
          <Route path="/about" element={<About />} />

          <Route path="/regions" element={<Regions setIsAuthenticated={setIsAuthenticated} isAuthenticated={isAuthenticated} />}/>

          <Route path="/region/:urlName" element={<RegionDetail role={role} setIsAuthenticated={setIsAuthenticated} isAuthenticated={isAuthenticated} />}/>
          <Route path="/region/:urlName/edit" element={<EditRegionDetail role={role} setIsAuthenticated={setIsAuthenticated} isAuthenticated={isAuthenticated} />}/>

          <Route path="/places" element={<Places setIsAuthenticated={setIsAuthenticated} isAuthenticated={isAuthenticated} />}/>
          <Route path="/region/:regionName/places" element={<Places setIsAuthenticated={setIsAuthenticated} isAuthenticated={isAuthenticated} />}/>

          <Route path="/region/:regionName/place/:placeName" element={<PlaceDetail role={role} setIsAuthenticated={setIsAuthenticated} isAuthenticated={isAuthenticated} />}/>
          <Route path="/place/:urlName" element={<PlaceDetail role={role} setIsAuthenticated={setIsAuthenticated} isAuthenticated={isAuthenticated} />}/>

          <Route path="/region/:regionName/place/:placeName/edit" element={<EditPlace role={role} setIsAuthenticated={setIsAuthenticated} isAuthenticated={isAuthenticated} />}/>

          <Route path="/region/:regionName/place/:placeName/edit/settings" element={<EditPlaceSettings role={role} setIsAuthenticated={setIsAuthenticated} isAuthenticated={isAuthenticated} />}/>
          <Route path="/region/:regionName/place/:placeName/edit/people" element={<EditPlacePeople role={role} setIsAuthenticated={setIsAuthenticated} isAuthenticated={isAuthenticated} />}/>
          <Route path="/region/:regionName/place/:placeName/edit/organizers" element={<EditPlaceOrganizers role={role} setIsAuthenticated={setIsAuthenticated} isAuthenticated={isAuthenticated} />}/>
          <Route path="/region/:regionName/place/:placeName/edit/suggestions" element={<EditPlaceSuggestions role={role} setIsAuthenticated={setIsAuthenticated} isAuthenticated={isAuthenticated} />}/>
          <Route path="/region/:regionName/place/:placeName/edit/integrations" element={<EditPlaceIntegrations role={role} setIsAuthenticated={setIsAuthenticated} isAuthenticated={isAuthenticated} />}/>
          <Route path="/region/:regionName/place/:placeName/edit/logs" element={<EditPlaceLogs role={role} setIsAuthenticated={setIsAuthenticated} isAuthenticated={isAuthenticated} />}/>


          <Route path="/place/:urlName/edit/scrapes" element={<EditPlaceScrapes role={role} setIsAuthenticated={setIsAuthenticated} isAuthenticated={isAuthenticated} />}/>


          <Route path="/organizers" element={<Organizers setIsAuthenticated={setIsAuthenticated} isAuthenticated={isAuthenticated}  role={role}/>}/>
          <Route path="/region/:regionName/organizers" element={<Organizers setIsAuthenticated={setIsAuthenticated} isAuthenticated={isAuthenticated}  role={role}/>}/>




          <Route path="/region/:regionName/organizer/:organizerName" element={<OrganizerDetail role={role} setIsAuthenticated={setIsAuthenticated} isAuthenticated={isAuthenticated} />}/>
          <Route path="/region/:regionName/organizer/:organizerName/edit" element={<EditOrganizerDetail role={role} setIsAuthenticated={setIsAuthenticated} isAuthenticated={isAuthenticated} />}/>



          <Route path="/organizer/:urlName" element={<OrganizerDetail role={role} setIsAuthenticated={setIsAuthenticated} isAuthenticated={isAuthenticated} />}/>
          <Route path="/organizer/:urlName/edit" element={<EditOrganizerDetail role={role} setIsAuthenticated={setIsAuthenticated} isAuthenticated={isAuthenticated} />}/>



          <Route path="/listings" element={<Listings setIsAuthenticated={setIsAuthenticated} isAuthenticated={isAuthenticated} />}/>
          <Route path="/region/:regionName/listings" element={<Listings setIsAuthenticated={setIsAuthenticated} isAuthenticated={isAuthenticated} />}/>



          <Route path="/listing/:listingId" element={<Listing setIsAuthenticated={setIsAuthenticated} isAuthenticated={isAuthenticated} />}/>
          <Route path="/listing/:listingId/edit" element={<EditListing setIsAuthenticated={setIsAuthenticated} isAuthenticated={isAuthenticated} />}/>


          <Route path="/user" element={<Profile setIsAuthenticated={setIsAuthenticated} isAuthenticated={isAuthenticated} />}/>
          <Route path="/dashboard" element={isAuthenticated ? <UserDashboard setIsAuthenticated={setIsAuthenticated} isAuthenticated={isAuthenticated} /> :  <Navigate to="/login/dashboard" />}/>
          <Route path="/user/:username" element={<UserProfile setIsAuthenticated={setIsAuthenticated} isAuthenticated={isAuthenticated} /> } />


          <Route path="/login/*" element={isAuthenticated ? <Navigate to="/dashboard" /> : <LoginPage setRole={setRole} setIsAuthenticated={setIsAuthenticated} />} />
          <Route path="/signup/*" element={isAuthenticated ? <Navigate to="/dashboard" /> : <RegisterPage />} />
          <Route path="/admin" element={isAuthenticated ? <Admin setIsAuthenticated={setIsAuthenticated} isAuthenticated={isAuthenticated} /> : <Navigate to="/" />} />


        
          <Route path="/confirm/:token" element={<EmailConfirmation />} />
          <Route path="/forgotpassword" element={<PasswordResetRequest/>} />
          <Route path="/resetpassword/:token" element={<PasswordReset/>} />


          <Route path="/logout" element={isAuthenticated ? <Logout setRole={setRole} setIsAuthenticated={setIsAuthenticated} /> : <Navigate to="/login" />} />
          {/* Add other routes for different pages */}
        </Routes>
        </div>

      </div>
    </Router>
  );
}

export default App;
