import React, { useEffect, useState } from 'react';
import { useNavigate, Link, redirect } from 'react-router-dom';
import AddRegion from '../components/admin/AddRegion'
import DisplayUsers from '../components/utilities/DisplayUsers';
import RegionInfo from '../components/region/RegionInfo';
import AdminUsers from '../components/admin/AdminUsers';
import AdminPlaceIntegrations from '../components/admin/AdminPlaceIntegrations';
const moment = require('moment-timezone');
function formatISOInLocal(isoString) {
  // Format: 'MMM DD, h:mm A' e.g., 'Nov 17, 6:00 AM'
  return moment(isoString).format('MMM DD, h:mm A');
}
function Admin({setIsAuthenticated,isAuthenticated}) {

  const navigate = useNavigate();
  const [regions, setRegions] = useState([]);
  const [users, setUsers] = useState([]);
  const [showRegions, setShowRegions] = useState(false);
  const [placeIntegrations, setPlaceIntegrations] = useState(false);

  const [places, setPlaces] = useState([]);
  const [integrations, setIntegrations] = useState([]);
  const [selected, setSelected] = useState(false);
  const [directory, setDirectory] = useState('');

  const [errorMessage, setErrorMessage] = useState('');
  //show user places if logged in and notifications about places
  //if not show blurb about places then a list of places
//get all places and display them with links to the places and buttons to edit if they are logged in
//nearby places using coords

 
useEffect(() => {
  if(isAuthenticated) {
    fetchAdmin();
  }
  else {
    navigate('/')
  }
}, [isAuthenticated,setIsAuthenticated]);
useEffect(() => {
  document.title = `Admin - Zeitgeists`;
}, []);
const fetchAdmin = async () => {
  try {
    const response = await fetch('/api/admin/', {
      headers: {
        'Content-Type': 'application/json', // Use a function to get the cookie value
              },
              credentials: 'include', // Include cookies
    });
     if (response.ok&&response.status===200) {
      const data = await response.json();
      setPlaceIntegrations(data.placeIntegrations)
      setRegions(data.regions);
      setUsers(data.users);
      setIntegrations(data.integrations);
      setPlaces(data.places);
    }
    if (response.status===403) {
      navigate('/dashboard');
    }
    if(response.status===401) {
      navigate('/');
    }

  } catch (error) {
    console.error(error);
    navigate('/dashboard');

  }
};
 const handleDeleteRegion = async (regionId) => {
  if (window.confirm("Confirm Delete Region")!==true) {
    return
} else {
  if (window.confirm("Again Confirm Delete Region")!==true) {
    return
}
}
try {


  // Make an API request to update user profile using the fetch API
   const response = await fetch(`/api/region/${regionId}`, {
    method: 'DELETE',
    credentials: 'include', // Include cookies

    headers: {
      'Content-Type': 'application/json',
    },
  });

  if (response.status === 201) {
    // User profile updated successfully
    // You can redirect to another page or display a success message here
   // console.log('User profile updated:', response);
   setErrorMessage(regionId + ' deleted!')
   fetchAdmin();
  } else if (response.status===404) {
    setErrorMessage('Region not found!')
    
  } else if (response.status===401||response.status===403) {
    setErrorMessage('Permision denied!')
    setIsAuthenticated(false);
    localStorage.removeItem('isAuthenticated');
    localStorage.removeItem('role');
    navigate('/')
  }
  else {

  } 

} catch (error) {
  // Handle any errors that may occur during region creation
  setErrorMessage(error);
  console.error('Error deleting region:', error);
}
 }




const updateIntegrationDirectory = async (integration) => {
  if (!selected||!directory) {
    return
}
  try{
    const response = await fetch(`/api/integration/${integration.id}`, {
      method: 'PATCH',
      credentials: 'include', // Include cookies
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify({directory}),
    });
      if(response.ok) {
        console.log('Success!')
      } else {
        console.error('Error!')
      }
   
  } catch (error) {
    console.error(error);

  }
console.log(directory)
setDirectory('')




}

  //your places
  //admin
  //
  return (
              <div className='p-4 mt-36'>
                <h2 className='text-xl font-bold '>Admin:</h2>
                <button onClick={()=>setShowRegions(!showRegions)} className='underline text-blue-600'> {showRegions ? 'Close Regions' : 'Show Regions'}</button>

                {showRegions && <div>
                <h3 className='text-lg font-semibold text-center '>Regions:</h3>
                <div className='grid grid-cols-3'>

                {regions&& regions.map(r => 
                <div  key={r.id}>
                  <RegionInfo region={r} />
                  <div className='text-center'>

                  <button className="underline font-bold  text-red-600" onClick={()=> handleDeleteRegion(r.id)}>Delete Region</button>

                    </div>
                </div>)}
                </div>
                <br/>
                {errorMessage}
                {<AddRegion onChange={fetchAdmin} isAuthenticated />}  
                </div>}
               {placeIntegrations&& <AdminPlaceIntegrations onChange={fetchAdmin} placeIntegrations={placeIntegrations} />} 



               
    
                {users && <AdminUsers regions={regions} users={users} />}

              </div>
 

  );
}

export default Admin;



