import React, { useState,useEffect } from 'react';
import { Link } from 'react-router-dom';
import AdminManageIntegration from './AdminManageIntegration';
//add confirm and fix external links
function AdminPlaceIntegrations({placeIntegrations,onChange}) { 
  useEffect(()=>{
    console.log(placeIntegrations)
  },[placeIntegrations])
    const [showIntegrations, setShowIntegrations] = useState(false);
    const [selectedIntegration, setSelectedIntegration] = useState(false);

      return (
<div>
<button onClick={()=>setShowIntegrations(!showIntegrations)} className='underline text-blue-600'> {showIntegrations ? 'Close Place Integrations' : 'Show  Place Integrations'}</button>
{showIntegrations && placeIntegrations &&  <div className='space-y-4'>
  <div className='font-bold'>Place Integrations:</div>

  {placeIntegrations.map(pi=>(
<div key={pi.uuid}>
<div className={`${selectedIntegration.id===pi.id ?'bg-yellow-200': 'bg-white'} shadow rounded-md px-6 py-4`}>
<div className="items-baseline justify-between">
  <div className="flex flex-wrap sm:flex-wrap space-x-2 sm:space-x-4">
  <span className="font-bold">Region:</span>
  <Link className='underline text-blue-600 hover:text-blue-800 visited:text-purple-600' to={`/region/${pi.place.region.urlName}`}>{pi.place.region.name}</Link>
  <span className="font-bold">Place:</span>
  <Link className='underline text-blue-600 hover:text-blue-800 visited:text-purple-600' to={`/region/${pi.place.region.urlName}/place/${pi.place.urlName}`}>{pi.place.name}</Link>
  <span className="font-bold">Date Added:</span>
    <span>{new Date(pi.createdAt).toLocaleString('en-US')}</span>
    </div>
    <div className="flex flex-wrap space-x-2  sm:space-x-4">

    <span className="font-bold">Type:</span>
    <span>{pi.integrationType.name}</span>
    <span className="font-bold">Name:</span>
    <span>{pi.name}</span>
    <span className="font-bold">Status:</span>
    <span>{pi.status.toUpperCase()}</span>
    <span className="font-bold">Permissions:</span>
    <span>{pi.action.toUpperCase()}</span>
    {pi.integrationType.name === "ICS" && (
      <>
        <span className='font-bold'>ICS URL:</span>
        <Link className='underline text-blue-600 hover:text-blue-800 visited:text-purple-600' to={pi.url}>{pi.url}</Link>
      </>
    )}
    {pi.integrationType.name === "Eventbrite" && (
      <>
        <span className='font-bold'>Eventbrite API:</span>
        <span>{pi.key}</span>
      </>
    )}
    {pi.integrationType.name === "TypeCatch" && (
      <>
        <span className='font-bold'>Listing Type:</span>
        <span>{pi.key}</span>
      </>
    )}
    {pi.integrationType.name === "OrganizerCatch" && (
      <>
        <span className='font-bold'>Organizer:</span>
        <Link className='underline text-blue-600 hover:text-blue-800 visited:text-purple-600' to={`/region/${pi.place.region.urlName}/organizer/${pi.organizer.urlName}`}>{pi.organizer.name}</Link>
      </>
    )}
        </div>

  <div className="flex flex-wrap space-x-2 sm:space-x-4 mt-4 sm:mt-0 justify-end">
  <button className='text-blue-500 hover:text-blue-700 underline' onClick={()=>selectedIntegration.id===pi.id ? setSelectedIntegration(false) : setSelectedIntegration(pi)}>{selectedIntegration.id===pi.id && selectedIntegration ? 'Close Manager' : 'Manage Integration'}</button>
    <Link className='text-orange-500 hover:text-orange-700 underline' to={`/region/${pi.place.region.urlName}/place/${pi.place.urlName}/edit/logs`}>See All Logs({pi.runs.length})</Link>
    <button className='text-red-500 text-xs hover:text-red-700 underline'>Delete</button>
  </div>
</div>
</div>
{selectedIntegration.id===pi.id&&<AdminManageIntegration onChange={onChange} setSelectedIntegration={setSelectedIntegration} selectedIntegration={selectedIntegration}/>}
</div>
))}
  </div>}


</div>
  
)}


export default AdminPlaceIntegrations;
